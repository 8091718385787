import { render, staticRenderFns } from "./saleXq.vue?vue&type=template&id=caf13e08&scoped=true"
import script from "./saleXq.vue?vue&type=script&lang=js"
export * from "./saleXq.vue?vue&type=script&lang=js"
import style0 from "./saleXq.vue?vue&type=style&index=0&id=caf13e08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caf13e08",
  null
  
)

export default component.exports