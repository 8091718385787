import { render, staticRenderFns } from "./grainFarmer.vue?vue&type=template&id=6cb15f54&scoped=true"
import script from "./grainFarmer.vue?vue&type=script&lang=js"
export * from "./grainFarmer.vue?vue&type=script&lang=js"
import style0 from "./grainFarmer.vue?vue&type=style&index=0&id=6cb15f54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb15f54",
  null
  
)

export default component.exports