<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >
            <div class="box">
                <div class="boxTit">基础信息</div>
                <el-form-item>
                    <div class="boxLi">
                        <div class="boxName"></div>
                        <el-radio-group
                            v-model="priceTypeName"
                            fill="#00903d"
                            @input="inputType"
                        >
                            <el-radio-button
                                label="以合同价为准"
                                fill="#00903d"
                            ></el-radio-button>
                            <el-radio-button
                                label="以实时价为准"
                                fill="#00903d"
                            ></el-radio-button>
                        </el-radio-group>
                        <!-- <el-radio v-model="ruleForm.priceType" label="0" size="medium" :border="true" text-color="#00903d" fill="#00903d" >以合同价为准</el-radio> -->
                        <!-- <el-radio v-model="ruleForm.priceType" label="1" size="medium" :border="true" text-color="#00903d" fill="#00903d">以实时价为准</el-radio> -->
                    </div>
                </el-form-item>
                <el-form-item prop="price" v-if="ruleForm.priceType == 0">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>价格(元)</div>
                        <!-- <div class="inpt">
            <input v-model="ruleForm.price" type="number" placeholder="请输入价格" style="width: 7.63rem;"/>

            <div class="el-icon-arrow-right"></div>
          </div> -->
                        <el-input
                            class="input"
                            v-model="ruleForm.price"
                            type="number"
                            size="medium"
                            placeholder="请输入价格"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="num">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>采购数量(吨)</div>
                        <!-- <div class="inpt">
            <input v-model="ruleForm.num" placeholder="请输入采购数量" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                        <el-input
                            class="input"
                            v-model="ruleForm.num"
                            size="medium"
                            placeholder="请输入采购数量"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="productName">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>交易品种</div>
                        <!-- <div class="inpt">
            <input v-model="ruleForm.productName" placeholder="请输入交易品种" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                        <el-input
                            v-model="ruleForm.productName"
                            placeholder="请输入交易品种"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="dealType">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>交易类型</div>
                        <!-- <div class="inpt">
            <input v-model="ruleForm.dealType" placeholder="请输入交易类型" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                        <el-input
                            v-model="ruleForm.dealType"
                            placeholder="请输入交易类型"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="producer">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>产地</div>
                        <!-- <div class="inpt">
            <input v-model="ruleForm.producer" placeholder="请输入产地" style="width: 7.63rem;"/>
            <div class="el-icon-arrow-right"></div>
          </div> -->
                        <el-input
                            v-model="ruleForm.producer"
                            placeholder="请输入产地"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <!-- <el-form-item prop="level">
        <div class="boxLi">
          <div class="boxName"><span>*</span>国标等级</div>
          
          <el-input v-model="ruleForm.level" placeholder="请输入国标等级" suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
        </div>
      </el-form-item> -->
                <el-form-item prop="releaseTime">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>发布时间</div>
                        <el-date-picker
                            v-model="ruleForm.releaseTime"
                            type="date"
                            placeholder="选择发布时间"
                            style="width: 7.63rem"
                        >
                        </el-date-picker>
                        <!-- <el-input v-model="ruleForm.releaseTime" placeholder="请选择发布时间" suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input> -->
                    </div>
                </el-form-item>
                <el-form-item prop="ensureMethod">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>采履约保证方式</div>
                        <el-input
                            v-model="ruleForm.ensureMethod"
                            placeholder="请输入采履约保证方式"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="messageValidity">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>信息有效期</div>
                        <el-date-picker
                            v-model="ruleForm.messageValidity"
                            type="date"
                            placeholder="请选择信息有效期"
                            style="width: 7.63rem"
                        >
                        </el-date-picker>
                        <!-- <el-input v-model="ruleForm.messageValidity" placeholder="请选择信息有效期" suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input> -->
                    </div>
                </el-form-item>
                <el-form-item prop="packing">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>包装方式</div>
                        <el-input
                            v-model="ruleForm.packing"
                            placeholder="请输入包装方式"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
            </div>
            <div class="box">
                <div class="boxTit">交易信息</div>
                <el-form-item prop="deliveryTime">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>交货时间</div>
                        <el-date-picker
                            v-model="ruleForm.deliveryTime"
                            type="date"
                            placeholder="请选择交货时间"
                            style="width: 7.63rem"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item prop="deliveryPlace">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>交货地</div>
                        <el-input
                            v-model="ruleForm.deliveryPlace"
                            placeholder="请输入交货地"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="deliveryMethod">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>交货方式</div>
                        <el-input
                            v-model="ruleForm.deliveryMethod"
                            placeholder="请输入交货方式"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="invoiceType">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>可提供发票类型</div>
                        <el-input
                            v-model="ruleForm.invoiceType"
                            placeholder="请输入可提供发票类型"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="paymentMethod">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>付款方式</div>
                        <el-input
                            v-model="ruleForm.paymentMethod"
                            placeholder="请输入付款方式"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="returnTime">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>回款时间</div>
                        <el-date-picker
                            v-model="ruleForm.returnTime"
                            type="date"
                            placeholder="请选择回款时间"
                            style="width: 7.63rem"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>

                <el-form-item prop="checkMethod">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>验收方式</div>
                        <el-input
                            v-model="ruleForm.checkMethod"
                            placeholder="请输入验收方式"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
            </div>
            <div class="box">
                <div class="boxTit">质量标准</div>
                <el-form-item prop="waterRequire">
                    <div class="boxLi">
                        <div class="boxName">水分要求≦</div>

                        <el-input
                            v-model="ruleForm.waterRequire"
                            placeholder="请输入水分要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="impurityRequire">
                    <div class="boxLi">
                        <div class="boxName">杂质要求≦</div>
                        <el-input
                            v-model="ruleForm.impurityRequire"
                            placeholder="请输入杂质要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        %
                    </div>
                </el-form-item>
                <el-form-item prop="moldyRequire">
                    <div class="boxLi">
                        <div class="boxName">生霉粒要求≦</div>
                        <el-input
                            v-model="ruleForm.moldyRequire"
                            placeholder="请输入生霉粒要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        %
                    </div>
                </el-form-item>
                <el-form-item prop="unsoundKernel">
                    <div class="boxLi">
                        <div class="boxName">不完善率要求≦</div>
                        <el-input
                            v-model="ruleForm.unsoundKernel"
                            placeholder="请输入不完善率要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        %
                    </div>
                </el-form-item>
                <el-form-item prop="proteinRequire">
                    <div class="boxLi">
                        <div class="boxName">蛋白质要求≦</div>
                        <el-input
                            v-model="ruleForm.proteinRequire"
                            placeholder="请输入蛋白质要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="weightRequire">
                    <div class="boxLi">
                        <div class="boxName">容重要求≧</div>
                        <el-input
                            v-model="ruleForm.weightRequire"
                            placeholder="请输入容重要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        g/L
                    </div>
                </el-form-item>
                <el-form-item prop="wornRequire">
                    <div class="boxLi">
                        <div class="boxName">赤霉病率要求≦</div>
                        <el-input
                            v-model="ruleForm.wornRequire"
                            placeholder="请输入赤霉病率要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        %
                    </div>
                </el-form-item>
                <el-form-item prop="mildewRequire">
                    <div class="boxLi">
                        <div class="boxName">霉菌要求＜</div>
                        <el-input
                            v-model="ruleForm.mildewRequire"
                            placeholder="请输入霉菌要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        g
                    </div>
                </el-form-item>
                <el-form-item prop="vomitoxinRequire">
                    <div class="boxLi">
                        <div class="boxName">呕吐毒素要求≦</div>
                        <el-input
                            v-model="ruleForm.vomitoxinRequire"
                            placeholder="请输入呕吐毒素要求"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                        kg
                    </div>
                </el-form-item>
                <el-form-item prop="deductions">
                    <div class="boxLi">
                        <div class="boxName"><span>*</span>抵扣标准</div>
                        <el-input
                            v-model="ruleForm.deductions"
                            placeholder="请输入抵扣标准"
                            suffix-icon="el-icon-arrow-right"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="remark">
                    <div class="boxLi" style="align-items: start">
                        <div class="boxName">备注</div>
                        <el-input
                            type="textarea"
                            autosize
                            v-model="ruleForm.remark"
                            placeholder="请输入备注"
                            style="width: 7.63rem"
                        ></el-input>
                    </div>
                </el-form-item>
                <!-- <el-form-item prop="colorOdorRequire">
        <div class="boxLi">
          <div class="boxName">色泽气味</div>
          <el-input v-model="ruleForm.colorOdorRequire" placeholder="请输入色泽气味" suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
        </div>
      </el-form-item>
      <el-form-item prop="other">
        <div class="boxLi">
          <div class="boxName">其它</div>
          <el-input v-model="ruleForm.other" placeholder="请输入其它" suffix-icon="el-icon-arrow-right" style="width: 7.63rem;"></el-input>
        </div>
      </el-form-item> -->
            </div>
            <el-form-item>
                <div class="btnBoxBig">
                    <button class="btnBox" @click="submitForm('ruleForm')">
                        确定提交
                    </button>
                </div>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { publishOrder } from '@/api/user/index.js'

export default {
    data() {
        var validateUsername = (rule, value, callback) => {
            if (this.ruleForm.priceType == 0 && !value) {
                callback(new Error('用户名不能为空'))
            } else {
                // 验证通过
                callback()
            }
        }

        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            ruleForm: {
                price: '', //价格
                unit: '吨', //单位
                num: '', //采购数量
                productName: '', //交易品种
                dealType: '', //交易类型
                producer: '', //产地
                // level:"",//国标等级
                releaseTime: '', //发布时间
                ensureMethod: '', //采履约保证方式
                messageValidity: '', //信息有效期
                packing: '', //包装方式
                deliveryTime: '', //交货时间
                deliveryPlace: '', //交货地
                deliveryMethod: '', //交货方式
                invoiceType: '', //可提供发票类型
                paymentMethod: '', //付款方式
                returnTime: '', //回款时间
                checkMethod: '', //验收方式
                waterRequire: '', //水分要求
                impurityRequire: '', //杂质要求
                moldyRequire: '', //霉变粒要求
                unsoundKernel: '', //不完善粒
                proteinRequire: '', //蛋白质
                weightRequire: '', //容重要求
                wornRequire: '', //赤毒病率
                mildewRequire: '', //霉菌
                vomitoxinRequire: '', //呕吐毒素
                deductions: '', //抵扣标准
                remark: '', //备注
                // waterRequire:"",//水分要求
                // impurityRequire:"",//杂质要求
                // mildewRequire:"",//霉变粒要求
                // wornRequire:"",//破损粒要求
                // weightRequire:"",//容重要求
                // colorOdorRequire:"",//色泽气味
                // other:""//其它≥≥≦
                priceType: '0'
            },
            priceTypeName: '以合同价为准',
            rules: {
                price: [{ validator: validateUsername, trigger: 'blur' }],
                num: [
                    {
                        required: true,
                        message: '请输入采购数量',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                productName: [
                    {
                        required: true,
                        message: '请输入交易品种',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                dealType: [
                    {
                        required: true,
                        message: '请输入交易类型',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                producer: [
                    {
                        required: true,
                        message: '请输入产地',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                // level: [{
                // 	required: true,
                // 	message: '请输入国标等级',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }],
                releaseTime: [
                    {
                        required: true,
                        message: '请选择发布时间',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                ensureMethod: [
                    {
                        required: true,
                        message: '请输入采履约保证方式',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                messageValidity: [
                    {
                        required: true,
                        message: '请选择信息有效期',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                packing: [
                    {
                        required: true,
                        message: '请输入包装方式',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                deliveryTime: [
                    {
                        required: true,
                        message: '请选择交货时间',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                deliveryPlace: [
                    {
                        required: true,
                        message: '请输入交货地',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                deliveryMethod: [
                    {
                        required: true,
                        message: '请输入交货方式',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                invoiceType: [
                    {
                        required: true,
                        message: '请输入可提供发票类型',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                paymentMethod: [
                    {
                        required: true,
                        message: '请输入付款方式',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                returnTime: [
                    {
                        required: true,
                        message: '请选择回款时间',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                checkMethod: [
                    {
                        required: true,
                        message: '请输入验收方式',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ],
                deductions: [
                    {
                        required: true,
                        message: '请输入抵扣标准',
                        // 可以单个或者同时写两个触发验证方式
                        trigger: ['change', 'blur']
                    }
                ]
                // waterRequire: [{
                // 	required: true,
                // 	message: '请输入水分要求',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }],
                // impurityRequire: [{
                // 	required: true,
                // 	message: '请输入杂质要求',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }],
                // mildewRequire: [{
                // 	required: true,
                // 	message: '请输入霉变粒要求',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }],
                // wornRequire: [{
                // 	required: true,
                // 	message: '请输入破损粒要求',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }],
                // weightRequire: [{
                // 	required: true,
                // 	message: '请输入容重要求',
                // 	// 可以单个或者同时写两个触发验证方式
                // 	trigger: ['change', 'blur'],
                // }]
            }
        }
    },
    created() {
        if (localStorage.getItem('msgDataIssue')) {
            let data = JSON.parse(localStorage.getItem('msgDataIssue'))
            this.ruleForm.price = data.price
            this.ruleForm.num = data.num
            this.ruleForm.productName = data.productName
            this.ruleForm.dealType = data.dealType
            this.ruleForm.producer = data.producer
            this.ruleForm.releaseTime = data.releaseTime
            this.ruleForm.ensureMethod = data.ensureMethod
            this.ruleForm.messageValidity = data.messageValidity
            this.ruleForm.packing = data.packing
            this.ruleForm.deliveryTime = data.deliveryTime
            this.ruleForm.deliveryPlace = data.deliveryPlace
            this.ruleForm.deliveryMethod = data.deliveryMethod
            this.ruleForm.invoiceType = data.invoiceType
            this.ruleForm.paymentMethod = data.paymentMethod
            this.ruleForm.returnTime = data.returnTime
            this.ruleForm.checkMethod = data.checkMethod
            this.ruleForm.waterRequire = data.waterRequire
            this.ruleForm.impurityRequire = data.impurityRequire
            this.ruleForm.moldyRequire = data.moldyRequire
            this.ruleForm.unsoundKernel = data.unsoundKernel
            this.ruleForm.proteinRequire = data.proteinRequire
            this.ruleForm.weightRequire = data.weightRequire
            this.ruleForm.wornRequire = data.wornRequire
            this.ruleForm.mildewRequire = data.mildewRequire
            this.ruleForm.vomitoxinRequire = data.vomitoxinRequire
            this.ruleForm.deductions = data.deductions
            this.ruleForm.remark = data.remark
            this.ruleForm.priceType = data.priceType
            this.priceTypeName =
                data.priceType == 0 ? '以合同价为准' : '以实时价为准'
        }
    },
    destroyed() {
        localStorage.removeItem('msgDataIssue')
    },

    methods: {
        inputType(e) {
            console.log(e)
            this.priceTypeName = e
            this.ruleForm.priceType = e == '以合同价为准' ? '0' : '1'
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    publishOrder(this.ruleForm).then((res) => {
                        console.log(res)
                        if (res.code == 0) {
                            this.$message({
                                message: '提交成功，等待审核!',
                                type: 'success'
                            })
                            this.$router.go(-1)
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning',
                                customClass: 'messageBoxTip'
                            })
                        }
                    })
                } else {
                    console.log('error submit!!')
                    // return false;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.5rem;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.box {
    width: 16.27rem;
    border-radius: 0.43rem;
    opacity: 1;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
    margin: 0.52rem auto 0;
    .boxTit {
        width: 16.12rem;
        height: 0.7rem;
        border-radius: 0.32rem 0.32rem 0 0;
        opacity: 1;
        background: linear-gradient(90deg, #00903d 0%, #00903d00 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        padding-left: 0.26rem;
        margin-bottom: 0.2rem;
    }

    .boxLi {
        display: flex;
        align-items: center;
        margin-top: 0.3rem;
        padding-left: 0.35rem;
        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 2.5rem;
            text-align: right;
            margin-right: 0.3rem;
            span {
                color: #f92800;
            }
        }
        .inpt {
            width: 7.63rem;
            height: 0.64rem;
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.01rem solid #ced9f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.22rem;
            box-sizing: border-box;
        }
        input {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        input::placeholder {
            color: #b8c2d9;
        }
        .el-icon-arrow-right {
            font-size: 0.3rem;
        }
    }
}
.btnBoxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.88rem;
}
.btnBox {
    width: 6.62rem;
    height: 0.98rem;
    border-radius: 1rem;
    background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
    color: #ffffff;
    font-size: 0.32rem;
    font-weight: 500;
    font-family: 'SourceHanSansCN';
    text-align: center;
}
</style>