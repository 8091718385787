<template>
    <div class="content">
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="searchBox">
            <el-dropdown @command="handleCommandType">
                <span class="el-dropdown-link">
                    {{ indexKeyword
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="公司">公司</el-dropdown-item>
                    <el-dropdown-item command="商品">商品</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <input placeholder="请输入关键字" v-model="keyWord" type="text" />
            <div @click="searchBtn">搜索</div>
        </div>

        <div class="paixuBox" v-if="indexKeyword == '商品'">
            <el-dropdown class="dropDown" @command="handleCommand">
                <span
                    class="el-dropdown-link"
                    :style="
                        paixuClickIndex == 0 ? 'color:#0e8f46' : 'color:#121212'
                    "
                >
                    {{ dropdownText
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="0">综合</el-dropdown-item>
                    <el-dropdown-item command="2"
                        >价格从高到低</el-dropdown-item
                    >
                    <el-dropdown-item command="1"
                        >价格从低到高</el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>

            <div
                @click="paixuClick"
                :style="paixuClickIndex == 1 ? 'color:#0e8f46' : '#121212'"
            >
                销量
            </div>
        </div>
        <div class="listBox" v-if="indexKeyword == '公司'">
            <div
                class="listLi"
                v-for="(item, index) in dataMsg"
                :key="index"
                @click="toUrl('/companyXq', item.id)"
            >
                <div class="listImg">
                    <img :src="item.factoryImg" alt="" />
                </div>
                <div class="listCont">
                    <div class="listName">
                        {{ item.title }}
                    </div>
                    <!-- <div class="listtip">
                        <div class="listtipName">
                            {{item.paramOne}}
                        </div>
                    </div> -->
                    <div class="listtip">
                        <div class="listtipName">
                            主营：{{ item.majorBusiness }}
                        </div>
                    </div>
                    <div class="listtipBtn">
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="listBox" v-if="indexKeyword == '商品'">
            <div
                class="listLi"
                v-for="(item, index) in dataMsg"
                :key="index"
                @click="toUrl('/shopXq', item.id, name)"
            >
                <div class="listImg">
                    <img :src="item.pic[0]" alt="" />
                </div>
                <div class="listCont">
                    <div class="listName">
                        {{ item.title }}
                    </div>
                    <div class="listtip">
                        <div class="listtipPrice">
                            ￥<span>{{ item.price }}</span>
                        </div>
                    </div>
                    <div class="listtip">
                        <div class="listtipName">
                            {{ item.source || '' }}
                            <span>销量：{{ item.salesVolume || 0 }}</span>
                        </div>
                    </div>
                    <div class="listtipBtn">
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>
<script>
import { searchGoods, searchInfos } from '@/api/user/index.js'

export default {
    data() {
        return {
            dataNum: 4,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            dataMsg: [],
            keyWord: '',
            listQuery: {
                page: 1,
                limit: 10,
                total: 0
            },
            type: 0,
            dropdownText: '综合',
            paixuClickIndex: 0,
            indexKeyword: '公司'
        }
    },
    created() {
        this.keyWord = this.$route.query.name
        this.init()
    },
    methods: {
        handleCommandType(command) {
            // this.$message('click on item ' + command)
            this.indexKeyword = command
            this.$forceUpdate()
            if (this.keyWord) {
                this.listQuery.page = 1
                this.listQuery.limit = 10
                this.listQuery.total = 0
                this.init()
            }
        },
        handleCommand(e) {
            console.log(e)
            this.type = e
            if (e == 0) {
                this.dropdownText = '综合'
            } else if (e == 2) {
                this.dropdownText = '价格从高到低'
            } else if (e == 1) {
                this.dropdownText = '价格从低到高'
            }
            this.paixuClickIndex = 0
            this.init()
        },

        //销量点击
        paixuClick() {
            this.type = 3
            this.paixuClickIndex = 1
            this.init()
        },

        toUrl(urlSrc, id, name) {
            this.$router.push({
                path: urlSrc,
                query: { id: id, name: name }
            })
        },

        searchBtn() {
            if (!this.keyWord) {
                this.$message({
                    message: '请输入搜索内容',
                    type: 'warning'
                })
                return
            }
            this.init()
        },

        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.init()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.init()
        },

        init() {
            if (this.indexKeyword == '公司') {
                searchInfos({
                    page: this.listQuery.page,
                    limit: this.listQuery.limit,
                    title: this.keyWord
                }).then((res) => {
                    if (res.code == 0) {
                        this.listQuery.total = Number(res.data.total)

                        // for (var a = 0; a < res.data.records.length; a++) {
                        //     res.data.records[a].pic =
                        //         res.data.records[a].pic.split(',')
                        // }
                        this.dataMsg = res.data.records
                    }
                })
            } else {
                searchGoods({
                    page: this.listQuery.page,
                    limit: this.listQuery.limit,
                    title: this.keyWord,
                    type: this.type
                }).then((res) => {
                    if (res.code == 0) {
                        this.listQuery.total = Number(res.data.total)

                        for (var a = 0; a < res.data.records.length; a++) {
                            res.data.records[a].pic =
                                res.data.records[a].pic.split(',')
                        }
                        this.dataMsg = res.data.records
                    }
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.searchBox {
    width: 15.5531rem;
    height: 1rem;
    border-radius: 0.31rem;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    padding: 0 0.49rem;
    margin: 0.9rem 0 0 1.2rem;
    display: flex;
    align-items: center;

    input {
        flex: 1;
        color: #121212;
        font-size: 0.4rem;
        font-weight: 400;
        margin-left: 0.2rem;
    }

    div {
        font-size: 0.4rem;
        font-weight: 400;
        color: #00943f;
    }
}

.paixuBox {
    width: 15.5531rem;
    height: 1rem;
    background: #ffffff;
    margin: 0.3rem 0 0 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropDown {
    color: #121212;
    font-size: 0.4rem;
    font-weight: 400;
    margin: 0 1rem;
}

.paixuBox div {
    color: #121212;
    font-size: 0.4rem;
    font-weight: 400;
    margin: 0 1rem;
}

.listBox {
    padding: 0.5rem 1.84rem;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .listLi {
        width: 7.48rem;
        // height: 2.68rem;
        height: 3.68rem;

        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        padding: 0.4rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .listImg {
            width: 3rem;
            // height: 1.86rem;
            height: 2.86rem;

            border-radius: 0.16rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }

        .listCont {
            margin-left: 0.37rem;
            flex: 1;

            .listName {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                margin-bottom: 0.36rem;
                display: -webkit-box;
                /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /*显示行数*/
                overflow: hidden;
                /*隐藏多出部分文字*/
                text-overflow: ellipsis;
                /*用省略号代替多出部分文字*/
                height: 1rem;
            }

            .listtip {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .listtipName {
                    color: #54545480;
                    font-size: 0.18rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }

                .listtipPrice {
                    color: #0e8f46;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    font-size: 0.28rem;

                    span {
                        color: #0e8f46;
                        font-weight: 700;
                        font-family: 'SourceHanSansCN';
                        font-size: 0.36rem;
                    }
                }
            }

            .listtipBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                line-height: 0.5rem;
                margin-top: 0.5rem;
            }
        }
    }
}
</style>