<template>
    <div class="hello" @scroll="handleScroll">
        <div class="bgHeader"></div>
        <div class="bgHeaderOne"></div>
        <!-- <div class="bgHeaderTwo"></div> -->
        <!-- <div class="bgHeaderOne"></div> -->
        <!-- <div class="bgHeaderThree"></div> -->
        <!-- <div class="bgHeaderFour"></div> -->

        <div class="boxCont">
            <HeaderTopNew
                :parentData="dataNum"
                :backgroundImage="backgroundBg"
                :flagView="flagView"
            ></HeaderTopNew>
            <div class="banner">
                <div class="bannerLeft" style="margin-top: 1.2rem">
                    <div class="bannerLeftTit">主推企业</div>
                    <div class="bannerLeftTitLiBox" style="height: 3.25rem">
                        <div
                            class="bannerLeftTitLi"
                            v-for="(item, index) in initData.mainCompanyList
                                .records"
                            :key="index"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <img :src="item.factoryImg" alt="" />
                            <div class="bannerLeftTitLiName">
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <el-carousel
                    :interval="5000"
                    arrow="always"
                    height="4.92rem"
                    indicator-position="outside"
                    style="position: relative; width: 8.44rem"
                >
                    <el-carousel-item
                        v-for="(item, index) in initData.carouselList.records"
                        :key="index"
                    >
                        <img
                            :src="item.pic"
                            alt=""
                            style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 0.1rem;
                            "
                            @click="swiperIndex(index)"
                        />
                    </el-carousel-item>
                </el-carousel>
                <div class="bannerLeft">
                    <div class="bannerLeftTit">主推产品</div>
                    <div
                        class="bannerLeftTitLiBox"
                        style="padding: 0.22rem 0.15rem"
                    >
                        <div
                            class="bannerRightTitLi"
                            v-for="(item, index) in initData.mainGoodsList
                                .records"
                            :key="index"
                            @click.stop="toUrl('/shopXq', item.id)"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            class="bannerRightTitLi"
                            v-if="initData.mainGoodsList.records.length >= 6"
                        >
                            ...
                        </div>
                    </div>
                </div>
            </div>
            <div class="titBox">
                <div
                    class="titBoxTop"
                    style="
                        position: relative;
                        padding: 0.12rem 0.72rem;
                        box-sizing: border-box;
                    "
                >
                    <div class="titDown">
                        <img src="../assets/downYellow.png" alt="" />
                    </div>
                    <div class="titBoxCont">
                        <div class="titBoxName">今日特卖</div>
                        <div class="titBoxTig">TODAY'S SPECIAL SALE</div>
                    </div>
                    <div class="titDown"></div>
                    <div
                        style="
                            font-size: 0.18rem;
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0;
                            bottom: 0.12rem;
                            color: #ffffff;
                        "
                        @click="toUrl('/temaiList', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div style="position: relative; width: 11.9rem; height: 7.6rem">
                    <div class="shopBox">
                        <div
                            class="shopBoxLi"
                            v-for="(obj, objIndex) in initData.saleList.records"
                            :key="objIndex"
                            @click.stop="toUrl('/shopXq', obj.id)"
                        >
                            <div
                                class="shopBoxImg"
                                :style="getBackgroundStyle(obj.pic[0])"
                            ></div>
                            <div class="shopBoxCont">
                                <div class="shopBoxBottom">
                                    <div class="shopPrice">
                                        ￥<span class="shopNum">{{
                                            obj.price
                                        }}</span>
                                    </div>
                                </div>
                                <div class="shopBoxTit">
                                    {{ obj.title }}
                                </div>

                                <div
                                    class="shopBoxBottomBtn"
                                    @click.stop="gomai(obj)"
                                >
                                    <div class="shopBoxBottomBtnLi">
                                        加入购物车
                                    </div>
                                    <div class="shopBoxBottomBtnLiA">
                                        立即购买
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <el-carousel
                    :interval="5000"
                    :autoplay="false"
                    indicator-position="none"
                    arrow="always"
                    height="7.6rem"
                    style="position: relative; width: 11.9rem"
                >
                    <el-carousel-item
                        v-for="(item, index) in initData.saleList.records"
                        :key="index"
                    >
                        <div class="shopBox">
                            <div
                                class="shopBoxLi"
                                v-for="(obj, objIndex) in item"
                                :key="objIndex"
                                @click.stop="toUrl('/shopXq', obj.id)"
                            >
                                <div
                                    class="shopBoxImg"
                                    :style="getBackgroundStyle(obj.pic[0])"
                                >
                                </div>
                                <div class="shopBoxCont">
                                    <div class="shopBoxBottom">
                                        <div class="shopPrice">
                                            ￥<span class="shopNum">{{
                                                obj.price
                                            }}</span>
                                        
                                        </div>
                                    </div>
                                    <div class="shopBoxTit">
                                        {{ obj.title }}
                                    </div>

                                    <div
                                        class="shopBoxBottomBtn"
                                        @click.stop="gomai(obj)"
                                    >
                                        <div class="shopBoxBottomBtnLi">
                                            加入购物车
                                        </div>
                                        <div class="shopBoxBottomBtnLiA">
                                            立即购买
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel> -->

                <!-- <div
                    class="titBoxTop"
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0.12rem 0.72rem;
                        box-sizing: border-box;
                    "
                >
                    <div class="titDown">
                        <img src="../assets/downYellow.png" alt="" />
                    </div>
                    <div class="titdianBox">
                        <div
                            class="titdians"
                            v-for="(item, index) in initData.saleList.records"
                            :key="index"
                        >
                            <div class="titdian"></div>
                        </div>
                    </div>
                    <div class="titDown"></div>
                </div> -->
                <div class="navBox">
                    <div class="navBoxLi">
                        <img src="../assets/lsmyIcon.png" alt="" />
                        粮食贸易
                    </div>
                    <div class="navBoxLine"></div>
                    <div class="navBoxLi">
                        <img src="../assets/zscyIcon.png" alt="" />
                        真实产业
                    </div>
                    <div class="navBoxLine"></div>
                    <div class="navBoxLi">
                        <img src="../assets/gnstIcon.png" alt="" />
                        国内首推
                    </div>
                    <div class="navBoxLine"></div>
                    <div class="navBoxLi">
                        <img src="../assets/aqwdIcon.png" alt="" />
                        安全稳定
                    </div>
                </div>
                <!-- <div class="titLsBig" style="position: relative">
                    <div class="titLsBox">
                        <div class="titLs">粮市</div>
                        <div class="titLsTip">OUR GRAIN MILL</div>
                    </div>
                    <img src="../assets/yellowDown.png" alt="" />
                    <div class="titLsBox"></div>
                    <div
                        style="
                            font-size: 0.18rem;
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0;
                            bottom: 0;

                            color: #ffffff;
                        "
                        @click="toUrl('/grainMarket', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div> -->
                <div class="lfBox" style="position: relative">
                    <div class="lfTop">
                        <div class="lfTit">粮市</div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">OUR GRAIN MILL</div>
                    <div
                        style="
                            /* font-size: 0.18rem; */
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0.12rem;
                            bottom: 0.12rem;
                            color: #ffffff;
                            font-size: 0.18rem;
                        "
                        @click="toUrl('/grainMarket', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>

                <div class="lsBox">
                    <div
                        class="lsLi"
                        v-for="(item, index) in initData.companyZeroList
                            .records"
                        :key="index"
                        @click="toUrl('/companyXq', item.id)"
                    >
                        <div class="lsImg">
                            <img :src="item.factoryImg" alt="" />
                        </div>
                        <div class="lsCont">
                            <div class="lsName">
                                {{ item.title }}
                            </div>

                            <div class="lsBtnBox">
                                <div class="lsBtn">
                                    了解更多信息
                                    <img src="../assets/rightI.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lfBox" style="position: relative">
                    <div class="lfTop">
                        <div class="lfTit">粮坊</div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">GRAIN MARKET</div>
                    <div
                        style="
                            /* font-size: 0.18rem; */
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0.12rem;
                            bottom: 0.12rem;
                            color: #ffffff;
                            font-size: 0.18rem;
                        "
                        @click="toUrl('/grainMill', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="lfCont">
                    <div
                        class="lfContLiBox"
                        v-for="(item, index) in initData.companyOneList.records"
                        :key="index"
                    >
                        <div
                            class="lfContLi"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <div class="lfContLiText">
                                <div class="pTit">主营:</div>
                                <div class="pTitTip">
                                    {{ item.majorBusiness }}
                                </div>
                            </div>
                            <div class="lfContImg">
                                <img :src="item.factoryImg" alt="" />
                            </div>
                            <div class="lfContName">
                                {{ item.title }}
                            </div>
                            <div class="lfContMore">
                                了解更多信息<img
                                    src="../assets/rightIc.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="lfBox" style="background: none; position: relative">
                    <div class="lfTop">
                        <div class="lfTit" style="background: #6f8c52">
                            粮仓
                        </div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">GRANARY</div>
                    <div
                        style="
                            font-size: 0.18rem;
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0.12rem;
                            bottom: 0.12rem;
                            color: #6f8c52;
                        "
                        @click="toUrl('/grainElevator', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div> -->
                <div class="lfBox" style="position: relative">
                    <div class="lfTop">
                        <div class="lfTit">粮仓</div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">GRANARY</div>
                    <div
                        style="
                            /* font-size: 0.18rem; */
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0.12rem;
                            bottom: 0.12rem;
                            color: #ffffff;
                            font-size: 0.18rem;
                        "
                        @click="toUrl('/grainElevator', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="granaryBox">
                    <div
                        v-for="(item, index) in initData.companyTwoList.records"
                        :key="index"
                    >
                        <div
                            class="granaryLi"
                            v-if="index <= 5"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <img :src="item.factoryImg" alt="" />
                            <div class="granaryBottom">
                                <div class="granaryCont">
                                    <div class="granaryName">
                                        {{ item.title }}
                                    </div>
                                    <div class="granaryZy">
                                        主营：{{ item.majorBusiness }}
                                    </div>
                                    <div class="granaryMore">
                                        <div class="granaryMoreLi">
                                            了解更多信息<img
                                                src="../assets/rightI.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lfBox" style="position: relative">
                    <div class="lfTop">
                        <div class="lfTit">粮耕</div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">GRAIN CULTIVATION</div>
                    <div
                        style="
                            font-size: 0.18rem;
                            display: flex;
                            align-items: flex-end;
                            font-weight: 300;
                            font-family: 'SourceHanSansCN';
                            position: absolute;
                            right: 0.12rem;
                            bottom: 0.12rem;
                            color: #ffffff;
                        "
                        @click="toUrl('/grainFarmer', 0)"
                    >
                        <div style="display: flex; align-items: center">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="droitPrise">
                    <div
                        class="droitPriseLi"
                        @click="toUrl('/seedList', item.id, item.title)"
                        v-for="(item, index) in lglist"
                        :key="index"
                    >
                        <img :src="item.img" alt="" />
                        <div class="droitPriseLiText">
                            <div class="droitPriseTop">
                                <div class="droitPriseName">
                                    {{ item.title }}
                                </div>
                                <!-- <div class="droitPriseTip">SEED</div> -->
                            </div>

                            <div class="droitPriseMoreBox">
                                <div class="droitPriseMore">
                                    了解更多信息<img
                                        src="../assets/rightIco.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lx">
                <div class="lxLeft">
                    <div
                        class="lxLeftLi"
                        v-for="(item, index) in initData.twoList.records"
                        :key="index"
                        @click="toUrl('/newXq', item.id)"
                    >
                        <img :src="item.pic" alt="" />
                        <div class="lxLeftLiNum">0{{ index + 1 }}</div>
                    </div>
                </div>
                <div class="lxRight">
                    <div class="lxTit">粮讯</div>
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 0.08rem;
                        "
                    >
                        <div class="lxTip">GRAIN NEWS</div>
                        <div class="lxMore" @click="toUrl('/newPage', 0)">
                            查看更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <img src="../assets/yellowDown.png" alt="" />
                    <div class="lxLiBox">
                        <div
                            class="lxLi"
                            v-for="(item, index) in initData.twoList.records"
                            :key="index"
                            @click="toUrl('/newXq', item.id)"
                        >
                            0{{ index + 1 }}、 {{ item.title }}{{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="ldBox">
                <div class="ld">
                    <div class="lfBox" style="background: none">
                        <div class="lfTop">
                            <div class="lfTit" style="background: #6f8c52">
                                粮道
                            </div>
                            <img src="../assets/yellowDown.png" alt="" />
                            <div style="width: 1.77rem"></div>
                        </div>
                        <div class="lfTip">GRAIN ROAD</div>
                    </div>
                    <div class="titB">
                        <div class="tit">请选择您的身份，加入粮之道大家!</div>
                        <div class="tip">——用实力服务每一位客户</div>
                    </div>
                    <div class="joinBox">
                        <div class="joinLi">
                            <div class="joinLiImg">
                                <img src="../assets/cwls.png" alt="" />
                            </div>
                            <div class="joinName">成为粮市</div>
                            <div class="joinName joinNameA">(成品制造商)</div>
                            <div
                                class="joinBtn"
                                @click="toUrlsa('/manuFacturer')"
                            >
                                欢迎加入
                            </div>
                        </div>
                        <div class="joinLi">
                            <div class="joinLiImg">
                                <img
                                    src="../assets/cwlf.png"
                                    alt=""
                                    style="width: 0.9rem; height: 0.89rem"
                                />
                            </div>

                            <div class="joinName">成为粮坊</div>
                            <div class="joinName joinNameA">(采购商)</div>
                            <div
                                class="joinBtn"
                                @click="toUrlsa('/purchasingAgent')"
                            >
                                欢迎加入
                            </div>
                        </div>
                        <div class="joinLi">
                            <div class="joinLiImg">
                                <img
                                    src="../assets/cwlc.png"
                                    alt=""
                                    style="width: 0.93rem; height: 0.77rem"
                                />
                            </div>

                            <div class="joinName">成为粮仓</div>
                            <div class="joinName joinNameA">(供应商)</div>
                            <div
                                class="joinBtn"
                                @click="toUrlsa('/supplierRz')"
                            >
                                欢迎加入
                            </div>
                        </div>
                        <div class="joinLi">
                            <div class="joinLiImg">
                                <img
                                    src="../assets/cwly.png"
                                    alt=""
                                    style="width: 0.79rem; height: 0.89rem"
                                />
                            </div>

                            <div class="joinName">成为粮运</div>
                            <div class="joinName joinNameA">(司机)</div>
                            <div class="joinBtn" @click="toUrlsa('/driverRz')">
                                欢迎加入
                            </div>
                        </div>
                        <div class="joinLi">
                            <div class="joinLiImg">
                                <img
                                    src="../assets/cwlg.png"
                                    alt=""
                                    style="width: 0.93rem; height: 0.91rem"
                                />
                            </div>
                            <div class="joinName">成为粮耕</div>
                            <div class="joinName joinNameA">(粮农)</div>
                            <div class="joinBtn" @click="toUrlsa('/farmingRz')">
                                欢迎加入
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: 1.78rem"></div>
            <div class="ewm">
                <div class="ewmLi" style="margin-left: 0">
                    <img :src="msgData.picture.appUrl" alt="" />
                    <div class="ewmName">android下载</div>
                </div>
                <div class="ewmLi">
                    <img :src="msgData.picture.iosUrl" alt="" />
                    <div class="ewmName">ios下载</div>
                </div>
                <div class="ewmLi">
                    <img :src="msgData.company.code3" alt="" />
                    <div class="ewmName">微信小程序</div>
                </div>
                <div class="ewmLi">
                    <img :src="msgData.company.code2" alt="" />
                    <div class="ewmName">微信公众号</div>
                </div>
                <div class="ewmLi">
                    <img :src="msgData.company.code1" alt="" />
                    <div class="ewmName">抖音号</div>
                </div>
            </div>
            <div class="footer">
                <div class="footerLeft">
                    <div class="footerDisplay">
                        <img :src="msgData.company.logo" alt="" />
                        <!-- <div class="footerName">{{msgData.company.name}}</div> -->
                    </div>
                    <div class="footerContBox">
                        <div class="footerCont">
                            <div class="footerContLi">
                                <div class="footerContName">咨询电话</div>
                                <div class="footerContText">
                                    {{ msgData.company.telephone }}
                                </div>
                            </div>

                            <div class="footerContLi">
                                <div class="footerContName">客服电话</div>
                                <div class="footerContText">
                                    {{ msgData.company.mobile }}
                                </div>
                            </div>
                            <div class="footerContLi">
                                <div class="footerContName">商务合作</div>
                                <div class="footerContText">
                                    {{ msgData.company.email }}
                                </div>
                            </div>
                            <div class="footerContLi">
                                <div class="footerContName">联系地址</div>
                                <div class="footerContText">
                                    {{ msgData.company.address }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="lx">
            <div class="lxLeft">
                <div
                    class="lxLeftLi"
                    v-for="(item, index) in initData.twoList.records"
                    :key="index"
                    @click="toUrl('/newXq', item.id)"
                >
                    <img :src="item.pic" alt="" />
                    <div class="lxLeftLiNum">0{{ index + 1 }}</div>
                </div>
            </div>
            <div class="lxRight">
                <div class="lxTit">粮讯</div>
                <div class="lxTip">OUR GRAIN NEWS</div>
                <img src="../assets/yellowDown.png" alt="" />
                <div class="lxLiBox">
                    <div
                        class="lxLi"
                        v-for="(item, index) in initData.twoList.records"
                        :key="index"
                        @click="toUrl('/newXq', item.id)"
                    >
                        0{{ index + 1 }}、 {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ldBox">
            <div class="ld">
                <div class="lfBox" style="background: none">
                    <div class="lfTop">
                        <div class="lfTit" style="background: #6f8c52">
                            粮仓
                        </div>
                        <img src="../assets/yellowDown.png" alt="" />
                        <div style="width: 1.77rem"></div>
                    </div>
                    <div class="lfTip">GRAIN ROAD</div>
                </div>
                <div class="titB">
                    <div class="tit">请选择您的身份，加入粮之道大家！</div>
                    <div class="tip">—— ——用实力服务每一位客户</div>
                </div>
                <div class="joinBox">
                    <div class="joinLi">
                        <img src="../assets/cwls.png" alt="" />
                        <div class="joinName">成为粮市</div>
                        <div class="joinName joinNameA">(成品制造商)</div>
                        <div class="joinBtn" @click="toUrl('/manuFacturer')">
                            欢迎加入
                        </div>
                    </div>
                    <div class="joinLi">
                        <img src="../assets/cwls.png" alt="" />
                        <div class="joinName">成为粮坊</div>
                        <div class="joinName joinNameA">(采购商)</div>
                        <div class="joinBtn" @click="toUrl('/purchasingAgent')">
                            欢迎加入
                        </div>
                    </div>
                    <div class="joinLi">
                        <img src="../assets/cwls.png" alt="" />
                        <div class="joinName">成为粮仓</div>
                        <div class="joinName joinNameA">(供应商)</div>
                        <div class="joinBtn" @click="toUrl('/supplierRz')">
                            欢迎加入
                        </div>
                    </div>
                    <div class="joinLi">
                        <img src="../assets/cwls.png" alt="" />
                        <div class="joinName">成为粮运</div>
                        <div class="joinName joinNameA">(司机)</div>
                        <div class="joinBtn" @click="toUrl('/driverRz')">
                            欢迎加入
                        </div>
                    </div>
                    <div class="joinLi">
                        <img src="../assets/cwls.png" alt="" />
                        <div class="joinName">成为粮耕</div>
                        <div class="joinName joinNameA">(粮农)</div>
                        <div class="joinBtn" @click="toUrl('/farmingRz')">
                            欢迎加入
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 1.78rem"></div>
        <div class="ewm">
            <div class="ewmLi" style="margin-left: 0">
                <img :src="msgData.picture.appUrl" alt="" />
                <div class="ewmName">android下载</div>
            </div>
            <div class="ewmLi">
                <img :src="msgData.picture.iosUrl" alt="" />
                <div class="ewmName">ios下载</div>
            </div>
            <div class="ewmLi">
                <img :src="msgData.company.code3" alt="" />
                <div class="ewmName">微信小程序</div>
            </div>
            <div class="ewmLi">
                <img :src="msgData.company.code2" alt="" />
                <div class="ewmName">微信公众号</div>
            </div>
            <div class="ewmLi">
                <img :src="msgData.company.code1" alt="" />
                <div class="ewmName">抖音号</div>
            </div>
        </div> -->

        <div class="xfBox">
            <!-- <div class="xfLi">
                <el-image
                    style="width: 0.5rem; height: 0.5rem; display: block"
                    :src="msgData.picture.appUrl"
                    :preview-src-list="srcList"
                >
                </el-image>
                <div class="xfLiName">android下载</div>
            </div>
            <div class="xfLi">
                <el-image
                    style="width: 0.5rem; height: 0.5rem; display: block"
                    :src="msgData.picture.iosUrl"
                    :preview-src-list="srcList"
                >
                </el-image>
                <div class="xfLiName">ios下载</div>
            </div> -->

            <div class="kefu" @click="kefuGo">
                <img src="@/assets/kf.png" alt="" />
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="70%">
            <div style="width: 100%; height: 8rem">
                <iframe
                    :src="urlSrcKf"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </div>
        </el-dialog>
        <SpeciFication
            v-if="specificationFlag"
            :msgData="dataMsg"
            @closeBtn="closeBtn"
            @guigeMsgGet="guigeMsgGet"
        ></SpeciFication>
    </div>
</template>

<script>
// import FooterBottom from './FooterBottom.vue'
import {
    getWeb,
    index,
    userInfo,
    categoryList,
    addShoppingCart,
    getGoods
} from '@/api/user/index.js'
export default {
    // components: { FooterBottom },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            specificationFlag: false,
            dataMsg: {},
            backgroundBg: require('@/assets/dhBg.png'),
            lglist: [],
            urlSrcKf: '',
            dialogVisible: false,

            srcList: [],
            dataNum: 0,
            msgData: {
                picture: {},
                choose: {},
                company: {},
                navbar: {},
                mainCompany: [],
                mainProduct: []
            },
            initData: {
                carouselList: {},
                companyOneList: {},
                companyTwoList: {},
                companyZeroList: {},
                noticeList: {},
                productOneList: {},
                productZeroList: {},
                twoList: {},
                saleList: {},
                mainCompanyList: {},
                mainGoodsList: {}
            },
            // ulList: [
            //     { msg: '这是第一条信息' },
            //     { msg: '这是第二条信息' },
            //     { msg: '这是第三条信息' },
            //     { msg: '这是第四条信息' },
            //     { msg: '这是第五条信息' },
            //     { msg: '这是第六条信息' },
            //     { msg: '这是第七条信息' },
            //     { msg: '这是第八条信息' },
            //     { msg: '这是第九条信息' },
            //     { msg: '这是第十条信息' }
            // ],
            play: true,
            scrollPosition: 0,
            userInfo: {},
            flagView: true
        }
    },
    created() {
        this.wznr()
        this.getInit()
        this.lgData()
        if (localStorage.getItem('token')) {
            this.getUser()
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        setInterval(this.startPlay, 2000)
    },
    beforeDestroy() {
        // 移除事件监听
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        gomai(item) {
            getGoods({ id: item.id }).then((res) => {
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    // res.data.content = res.data.content.replace(
                    //     /<img/g,
                    //     "<img style='max-width:100%;height:auto;'"
                    // )
                    res.data.pic = res.data.pic.split(',')
                    res.data.details = res.data.details.split(',')
                    if (res.data.commentList.length > 0) {
                        for (var a = 0; a < res.data.commentList.length; a++) {
                            if (res.data.commentList[a].img) {
                                res.data.commentList[a].img =
                                    res.data.commentList[a].img.split(',')
                            }
                            if (res.data.commentList[a].videos) {
                                res.data.commentList[a].videos =
                                    res.data.commentList[a].videos.split(',')
                            }
                        }
                    }
                    this.dataMsg = res.data
                    this.specificationFlag = true
                }
            })
            // console.log(item)
            // this.dataMsg = item
        },
        guigeMsgGet(e, num, number) {
            console.log(e, num, number)
            // num==1//加入购物车2市立即购买
            let data = JSON.parse(e)
            if (num == 1) {
                // 加入购物车
                addShoppingCart({ goodsSpecId: data.id, num: number }).then(
                    (res) => {
                        this.specificationFlag = false

                        if (res.code == 0) {
                            this.$message({
                                message: '加入购物车成功',
                                type: 'success',
                                customClass: 'messageBoxTip'
                            })
                        }
                        // else {
                        //     this.$message({
                        //         message: res.msg,
                        //         type: 'warning',
                        //         customClass: 'messageBoxTip'
                        //     })
                        // }
                    }
                )
            } else {
                // 立即购买

                this.showSpecifica = false
                // 立即购买
                this.$router.push({
                    path: '/sureOrder',
                    query: { ids: data.id, num: number, type: 2 }
                })
            }
        },
        closeBtn() {
            this.specificationFlag = false
        },
        handleScroll() {
            this.scrollPosition = window.scrollY || window.pageYOffset // 对于旧版IE使用window.pageYOffset, 对于现代浏览器使用window.scrollY
            console.log(this.scrollPosition, '页面滚动事件被触发')
            // 在这里处理你的逻辑
            if (this.scrollPosition <= 0) {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = true
            } else {
                this.backgroundBg = require('@/assets/dhBg.png')

                this.flagView = false
            }
            console.log(this.backgroundBg, '页面滚动事件被触发')
        },
        getBackgroundStyle(imageUrl) {
            return {
                'background-image': `url(${imageUrl})`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
            }
        },

        getUser() {
            userInfo().then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.userInfo = res.data
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }
            })
        },
        // 粮耕
        lgData() {
            categoryList().then((res) => {
                if (res.code == 0) {
                    this.lglist = res.data
                }
            })
        },
        //搜索
        searchClick() {
            this.$router.push({ path: '/allSearch' })
        },
        startPlay() {
            let that = this
            that.play = true //开始播放
            setTimeout(() => {
                that.initData.noticeList.records.push(
                    that.initData.noticeList.records[0]
                ) //将第一条数据塞到最后一个
                that.initData.noticeList.records.shift() //删除第一条数据
                that.play = false //暂停播放,此处修改，保证每一次都会有动画显示。 一定要修改属性，只有修改属性这样才能触发控件刷新冲毁!!!!
            }, 1000)
        },

        //只要对第一行进行滚动，下面的自动会跟着往上移动。
        isScroll(index) {
            if (index == 0) {
                return true
            } else {
                return false
            }
        },

        swiperIndex(index) {
            console.log(index)
            // uni.navigateTo({
            // 	url:this.listBannerList[index].url
            // })
            // 1产品详情2公司详情0富文本
            if (this.initData.carouselList.records[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.initData.carouselList.records[index].url }
                })
            } else if (this.initData.carouselList.records[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.initData.carouselList.records[index].url }
                })
            } else if (this.initData.carouselList.records[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.initData.carouselList.records[index].id }
                })
            }
            // if(this.initData.carouselList.records[index].url){
            // 	// uni.navigateTo({
            // 	// 	url:this.listBannerList[index].url
            // 	// })
            // }else{
            //   this.$router.push({path:'/lbXq',query:{id:this.initData.carouselList.records[index].id}})
            // 	// uni.navigateTo({
            // 	// 	url:"/pagesC/pages/lcxq?id=" + this.listBannerList[index].id
            // 	// })
            // }
        },
        kefuGo() {
            if (localStorage.getItem('token')) {
                userInfo().then((res) => {
                    console.log(res)
                    if (res.code == 0) {
                        let url =
                            'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                            '&visiter_id=' +
                            res.data.id +
                            '&visiter_name=' +
                            res.data.username +
                            '&avatar=' +
                            res.data.headUrl +
                            '&business_id=1&groupid=0'
                        // window.open(url, '_blank')
                        this.urlSrcKf = url
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(res.data)
                        )
                    }
                })
            } else {
                let url =
                    'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                    '&visiter_id=' +
                    '' +
                    '&visiter_name=' +
                    '' +
                    '&avatar=' +
                    '' +
                    '&business_id=1&groupid=0'
                // window.open(url, '_blank')
                this.urlSrcKf = url
            }
            this.dialogVisible = true
        },
        wenben(data, index) {
            localStorage.setItem('textCont', JSON.stringify(data))
            this.$router.push({ path: '/adoutXq', query: { type: index } })
        },
        wznr() {
            let array = []
            getWeb().then((res) => {
                array.push(res.data.picture.appUrl)
                array.push(res.data.picture.iosUrl)
                this.srcList = array
                // res.data.mainCompany = this.chunkArray(res.data.mainCompany, 5)
                // res.data.mainProduct = this.chunkArray(res.data.mainProduct, 6)
                this.msgData = res.data
                localStorage.setItem('webMsg', JSON.stringify(res.data))
            })
        },
        chunkArrayOld(arr, chunkSize) {
            let chunks = []
            for (let i = 0; i < arr.length; i += chunkSize) {
                arr.index = i
                chunks.push(arr.slice(i, i + chunkSize))
            }
            return chunks
        },
        chunkArray(arr, chunkSize) {
            let chunks = []
            for (let i = 0; i < arr.length; i++) {
                if (i < chunkSize) {
                    chunks.push(arr[i])
                }
            }
            return chunks
        },
        getInit() {
            index({ type: 0, page: 1, keyword: '' }).then((res) => {
                // res.data.twoList.records = this.chunkArray(
                //     res.data.twoList.records,
                //     4
                // )
                res.data.twoList.records = this.chunkArray(
                    res.data.twoList.records,
                    9
                )
                res.data.companyOneList.records = this.chunkArray(
                    res.data.companyOneList.records,
                    8
                )
                res.data.companyZeroList.records = this.chunkArray(
                    res.data.companyZeroList.records,
                    10
                )
                res.data.companyTwoList.records = this.chunkArray(
                    res.data.companyTwoList.records,
                    6
                )

                // if (res.data.productZeroList.records.length > 0) {
                //     for (
                //         var a = 0;
                //         a < res.data.productZeroList.records.length;
                //         a++
                //     ) {
                //         res.data.productZeroList.records[a].pic =
                //             res.data.productZeroList.records[a].pic.split(',')
                //     }
                // }

                // if (res.data.productOneList.records.length > 0) {
                //     for (
                //         var b = 0;
                //         b < res.data.productOneList.records.length;
                //         b++
                //     ) {
                //         res.data.productOneList.records[b].pic =
                //             res.data.productOneList.records[b].pic.split(',')
                //     }
                // }
                if (res.data.saleList.records.length > 0) {
                    for (var c = 0; c < res.data.saleList.records.length; c++) {
                        res.data.saleList.records[c].pic =
                            res.data.saleList.records[c].pic.split(',')
                    }
                }
                // res.data.saleList.records = this.chunkArrayOld(
                //     res.data.saleList.records,
                //     10
                // )
                res.data.saleList.records = this.chunkArray(
                    res.data.saleList.records,
                    10
                )
                res.data.mainCompanyList.records = this.chunkArray(
                    res.data.mainCompanyList.records,
                    4
                )
                res.data.mainGoodsList.records = this.chunkArray(
                    res.data.mainGoodsList.records,
                    6
                )

                this.initData = res.data
                console.log(this.initData)
            })
        },
        toUrlsa(urlSrc) {
            if (!localStorage.getItem('token')) {
                this.$message({
                    message: '请登录后操作！',
                    type: 'warning'
                })
                return
            }
            // if(urlSrc=='/HelloWorld'){
            //     this.$router.push(urlSrc)
            // }else{
            if (this.userInfo.userType == 1) {
                this.$message({
                    message: '您已是粮市(成品制造商)！',
                    type: 'warning'
                })
                return
            }
            if (this.userInfo.userType == 2) {
                this.$message({
                    message: '您已是粮坊(采购商)！',
                    type: 'warning'
                })

                return
            }
            if (this.userInfo.userType == 3) {
                this.$message({
                    message: '您已是粮仓(供货商)！',
                    type: 'warning'
                })

                return
            }

            if (this.userInfo.userType == 4) {
                this.$message({
                    message: '您已是粮运(司机)！',
                    type: 'warning'
                })

                return
            }
            if (this.userInfo.userType == 5) {
                this.$message({
                    message: '您已是粮耕(粮农)！',
                    type: 'warning'
                })

                return
            }
            this.$router.push(urlSrc)
        },

        toUrl(urlSrc, id, name) {
            if (urlSrc == '/shopXq' || urlSrc == '/companyXq') {
                this.$router.push({ path: urlSrc, query: { id: id } })
                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: urlSrc, query: { id: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }
            if (urlSrc == '/newXq') {
                this.$router.push({ path: urlSrc, query: { id: id } })
                return
            }
            if (urlSrc == '/shopList' || urlSrc == '/temaiList') {
                this.$router.push({ path: urlSrc, query: { type: id } })
                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: urlSrc, query: { type: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }

            if (urlSrc == '/seedList') {
                this.$router.push({
                    path: urlSrc,
                    query: { id: id, name: name }
                })
                return
            }
            this.$router.push(urlSrc)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
:deep(.el-carousel__indicators) {
    position: absolute;
    // bottom: unset; /* 取消默认底部定位 */
    bottom: 0; /* 上移距离（按需调整） */
    left: 50%;
    transform: translateX(-50%);
}
/* 可选：调整单个指示点样式（如间距） */
:deep(.el-carousel__indicators--outside button) {
    background: #ffffff;
}
.bgHeader {
    width: 100%;
    height: 8.3rem;
    background: url('../assets/headerOne.png') no-repeat center;
    background-size: 100% 100%;
}
.bgHeaderOne {
    width: 100%;
    height: 6.46rem;
    // height: 5.76rem;
    background: url('../assets/headerTwo.png') no-repeat center;
    background-size: 100% 100%;
}
.bgHeaderTwo {
    width: 100%;
    height: 5.89rem;
    background: url('../assets/headerThree.png') no-repeat center;
    background-size: 100% 100%;
}
.bgHeaderThree {
    width: 100%;
    height: 6.3rem;
    background: url('../assets/headerFour.png') no-repeat center;
    background-size: 100% 100%;
}

.bgHeaderFour {
    width: 100%;
    height: 22.26rem;
    // height: 22.56rem;
}

.boxCont {
    width: 100%;
    position: absolute;
    top: 0.88rem;
    left: 0;
}

.banner {
    // width: 12rem;
    width: 11.9rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .bannerLeft {
        width: 1.68rem;
        height: 3.7rem;
        // height: 4.92rem;
        .bannerLeftTit {
            width: 100%;
            height: 0.47rem;
            font-size: 0.25rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            color: #ffffff;
            // background: rgba($color: #000000, $alpha: 0.1);
            background: #6f8c52;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.1rem 0.1rem 0 0;
        }
        .bannerLeftTitLiBox {
            // background: rgba(220, 230, 202, 0.9);
            background: #ffffff;
            border-radius: 0 0 0.1rem 0.1rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            height: 4.45rem;

            .bannerLeftTitLi {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.1rem 0;
                border-bottom: 1px solid #7f9964;
                img {
                    // width: 0.64rem;
                    // height: 0.64rem;
                    // width: 0.55rem;
                    // height: 0.55rem;
                    // width: 0.54rem;
                    // height: 0.54rem;
                    // width: 0.44rem;
                    // height: 0.44rem;
                    width: 0.58rem;
                    height: 0.58rem;
                    border-radius: 0.1rem;
                }
                .bannerLeftTitLiName {
                    // font-size: 0.16rem;
                    font-size: 0.14rem;
                    color: #2b2b2b;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.09rem;
                }
            }
            .bannerLeftTitLi:last-child {
                border: none;
            }
            .bannerRightTitLi {
                color: #000000;
                font-size: 0.18rem;
                padding: 0.16rem 0;
                border-bottom: 1px solid #7f9964;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 300;
                font-family: 'SourceHanSansCN';
            }
            .bannerRightTitLi:last-child {
                border: none;
            }
        }
    }
}

.titBox {
    // width: 12rem;
    width: 11.9rem;
    margin: 0 auto;
    .titBoxTop {
        display: flex;
        justify-content: space-between;
        padding: 0.12rem 0 0.12rem 0.72rem;
        box-sizing: border-box;
        .titDown {
            width: 0.32rem;
            height: 0.73rem;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        .titdianBox {
            display: flex;
            align-items: center;
            .titdians {
                padding: 0.08rem;
                box-sizing: border-box;
            }
            .titdian {
                width: 0.12rem;
                height: 0.12rem;
                background: #ffffff;
                border-radius: 50%;
            }
        }
        .titBoxCont {
            padding-top: 0.19rem;
            .titBoxName {
                width: 1.78rem;
                height: 0.55rem;
                border: 1px solid #ffffff;
                font-size: 0.36rem;
                color: #ffffff;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                // background: rgba($color: #000000, $alpha: 0.2);
                background: #6f8c52;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
            }
            .titBoxTig {
                font-size: 0.16rem;
                color: #ffffff;
                font-family: 'SourceHanSansCN';
                margin-top: 0.14rem;
                letter-spacing: 0.2rem;
                font-weight: 200;
            }
        }
    }
    .shopBox {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        .shopBoxLi {
            border-radius: 0.1rem;
            // margin-bottom: 0.27rem;
            margin-bottom: 0.17rem;
            margin-left: 0.21rem;
            // margin-left: 0.37rem;
            width: 2.21rem;
            // width: 2.62rem;
            background: #ffffff;
            box-shadow: 0px 5px 5px 0px rgba(78, 112, 88, 0.3);
            .shopBoxImg {
                width: 100%;
                height: 2.52rem;
                background: url('../assets/cgsBanner.png') no-repeat center;
                background-size: cover;
                border-radius: 0.1rem 0.1rem 0 0;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0.1rem 0.1rem 0 0;
                }
            }
            .shopBoxCont {
                padding: 0.24rem 0.17rem 0.1rem;
                box-sizing: border-box;
                background: #ffffff;
                z-index: 9999;
                margin-top: -0.3rem;
                background: url('../assets/contBg.png') no-repeat center;
                background-size: 100% 100%;
                .shopBoxTit {
                    font-size: 0.2rem;
                    color: #000000;
                    text-align: center;
                    font-family: 'SourceHanSansCN';
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-top: 0.045rem;
                }
                .shopBoxBottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .shopPrice {
                        font-size: 0.2rem;
                        color: #6f8c52;
                        font-weight: 500;

                        font-family: 'SourceHanSansCN';

                        .shopNum {
                            font-size: 0.28rem;
                            font-weight: 500;
                            font-family: 'SourceHanSansCN';
                        }
                        .shopTag {
                            font-size: 0.18rem;
                        }
                    }
                    img {
                        width: 0.29rem;
                        height: 0.29rem;
                    }
                }
                .shopBoxBottomBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.76rem;
                    height: 0.24rem;
                    border-radius: 0.6rem;
                    background: linear-gradient(0deg, #cccccc 0%, #f2f1f3 100%);
                    margin: 0.12rem auto 0;
                    .shopBoxBottomBtnLi {
                        color: #ffffff;
                        font-size: 0.14rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        text-align: center;
                        width: 0.92rem;
                        height: 0.24rem;
                        border-radius: 0.6rem;
                        background: linear-gradient(
                            0deg,
                            #708d53 0%,
                            #97af7d 100%
                        );
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .shopBoxBottomBtnLiA {
                        color: #708d53;
                        font-size: 0.14rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
    .shopBox :nth-child(5n + 1) {
        margin-left: 0;
    }
    .navBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 11.9rem;
        // width: 12rem;
        height: 0.99rem;
        background: #6f8c52;
        margin-top: 0.25rem;
        margin-bottom: 0.2rem;
        .navBoxLi {
            font-size: 0.2rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;
            img {
                // width: 0.42rem;
                // height: 0.42rem;
                width: 0.66rem;
                height: 0.66rem;
                margin-right: 0.16rem;
            }
        }
        .navBoxLine {
            width: 1px;
            height: 0.76rem;
            background: #ffffff;
            // margin: 0 0.5rem;
        }
    }
    .titLsBig {
        margin-top: 0.41rem;
        margin-bottom: 13px;
        display: flex;
        justify-content: space-between;
        img {
            width: 29.64px;
            height: 14.82px;
        }
    }
    .titLsBox {
        width: 50%;
        .titLs {
            width: 1.77rem;
            height: 0.55rem;
            border: 1px solid #ffffff;
            font-size: 0.36rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 2.15px;
            font-family: 'SourceHanSansCN';
            padding-left: 2.15px;
            box-sizing: border-box;
            font-weight: 700;
            background: #6f8c52;
        }
        .titLsTip {
            font-size: 0.16rem;
            color: #ffffff;
            font-family: 'SourceHanSansCN';
            margin-top: 0.16rem;
            letter-spacing: 0.2rem;
            font-weight: 200;
        }
    }
    .lsBox {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        .lsLi {
            // margin-left: 0.48rem;
            margin-left: 0.1421rem;
            // width: 2.2679rem;
            width: 2.265rem;
            // width: 2.64rem;
            background: #ffffff;
            box-shadow: 0px 5px 5px 0px rgba(78, 112, 88, 0.3);
            border-radius: 0.1rem;
            margin-bottom: 0.24rem;
            .lsImg {
                width: 100%;
                height: 2.93rem;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0.1rem 0.1rem 0 0;
                    object-fit: cover;
                }
            }
            .lsCont {
                padding: 0.09rem 0.11rem 0.12rem;
                box-sizing: border-box;

                .lsName {
                    font-size: 0.2rem;
                    color: #000000;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    border-top: 1px solid #849d6c;
                    padding-top: 0.17rem;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .lsBtnBox {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    margin-top: 0.11rem;
                }
                .lsBtn {
                    font-size: 0.12rem;
                    width: 1rem;
                    height: 0.18rem;
                    background: #6f8c52;
                    border-radius: 50px;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 0.11rem;
                        height: 0.09rem;
                        margin-left: 0.04rem;
                    }
                }
            }
        }
    }
    .lsBox :nth-child(5n + 1) {
        margin-left: 0;
    }

    .lfBox {
        width: 100%;
        // height: 1.11rem;
        // background: rgba($color: #6f8c52, $alpha: 0.8);
        background: #6f8c52;
        padding: 0.12rem;
        box-sizing: border-box;
        margin-bottom: 0.17rem;
        .lfTop {
            display: flex;
            justify-content: space-between;
            .lfTit {
                width: 1.77rem;
                height: 0.55rem;
                border: 1px solid #ffffff;
                font-size: 0.36rem;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.1rem;
                font-family: 'SourceHanSansCN';
                padding-left: 0.1rem;
                box-sizing: border-box;
                font-weight: 700;
                background: #6f8c52;
            }
            img {
                width: 0.3rem;
                height: 0.15rem;
            }
        }
        .lfTip {
            padding: 0 0.12rem;
            box-sizing: border-box;
            font-size: 0.16rem;
            color: #000000;
            font-family: 'SourceHanSansCN';
            margin-top: 0.16rem;
            letter-spacing: 0.2rem;
            font-weight: 200;
            width: 6.3rem;
            height: 0.21rem;
            background: linear-gradient(90deg, #efce63 0%, #efce6300 100%);
        }
    }
    .lfCont {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        .lfContLiBox {
            width: 2.8rem;
            // margin-left: 1.7rem;
            margin-left: 0.2rem;
            margin-bottom: 0.22rem;
        }
        .lfContLi {
            position: relative;
            width: 2.46rem;
            height: 3.77rem;
            background: #efce63;
            box-shadow: 2.5px 4.33px 5px 0 #0000004d;
            .lfContLiText {
                padding: 0.22rem 0.1rem 0 0.19rem;
                box-sizing: border-box;
                height: 1rem;

                .pTit {
                    color: #000000;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                    font-size: 0.2rem;
                }
                .pTitTip {
                    color: #000000;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    font-size: 0.15rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .lfContImg {
                width: 2.52rem;
                height: 2.52rem;
                position: absolute;
                bottom: 0;
                left: 0.33rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .lfContName {
                position: absolute;
                bottom: 1.84rem;
                left: 0.1rem;
                color: #ffffff;
                // font-size: 0.25rem;
                font-size: 0.2rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                // height: 0.55rem;
                background: rgba($color: #6f8c52, $alpha: 0.9);
                padding: 0.1rem 0.2rem;
                box-sizing: border-box;
                max-width: 2.75rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .lfContMore {
                width: 1.01rem;
                height: 0.19rem;
                border-radius: 0.095rem;
                background: #efce63;
                position: absolute;
                bottom: 0.14rem;
                right: -0.22rem;
                color: #000000;
                font-size: 0.12rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                img {
                    width: 0.11rem;
                    height: 0.09rem;
                    margin-left: 0.04rem;
                }
            }
        }
    }
    .lfCont :nth-child(4n + 1) {
        /* CSS 样式 */
        margin-left: 0;
    }
    .granaryBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .granaryLi {
            width: 3.91rem;
            height: 3.2rem;
            position: relative;
            margin-bottom: 0.28rem;
            border-radius: 10px;
            box-shadow: 1px 2px 4px 0 #0000004d;
            img {
                width: 100%;
                border-radius: 10px;
                height: 100%;
            }
            .granaryBottom {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
            .granaryCont {
                width: 3.4rem;
                // height: 1.11rem;
                background: rgba(255, 255, 255, 0.85);
                margin: 0 auto;
                border-radius: 0.1rem 0.1rem 0 0;
                padding: 0 0.11rem 0.12rem;
                box-sizing: border-box;
                .granaryName {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    padding: 0.12rem;
                    box-sizing: border-box;
                    font-size: 0.1821rem;
                    font-weight: 0;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    border-bottom: 1px solid #708f5f;
                }
                .granaryZy {
                    color: #6f8c52;
                    font-size: 0.1639rem;
                    font-weight: 0;
                    text-align: center;
                    font-family: 'SourceHanSansCN';
                    padding: 0.12rem;
                    box-sizing: border-box;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 2;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    // height: 0.65rem;
                    height: 0.4rem;
                }
                .granaryMore {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .granaryMoreLi {
                        font-size: 0.12rem;
                        width: 1rem;
                        height: 0.18rem;
                        background: #6f8c52;
                        border-radius: 50px;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 0.11rem;
                            height: 0.09rem;
                            margin-left: 0.04rem;
                        }
                    }
                }
            }
        }
    }
    .droitPrise {
        display: flex;

        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .droitPriseLi {
            margin-bottom: 0.26rem;

            display: flex;
            width: 5.91rem;
            box-shadow: 2.5px 4.33px 6px 0 #6f8c534d;
            img {
                width: 3.7rem;
                height: 2.64rem;
                object-fit: cover;
            }
            .droitPriseLiText {
                background: #6f8c52;
                flex: 1;
                height: 2.64rem;
                padding: 0.1rem 0.2rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .droitPriseTop {
                    .droitPriseName {
                        letter-spacing: 1px;
                        color: #ffffff;
                        font-size: 0.25rem;
                        font-weight: 0;
                        font-family: 'SourceHanSansCN';
                        border-bottom: 1px solid #ffffff;
                    }
                    .droitPriseTip {
                        color: #ffffff;
                        font-size: 0.14rem;
                        font-weight: 0;
                        font-family: 'SourceHanSansCN';
                        margin-top: 0.05rem;
                        letter-spacing: 12.32px;
                    }
                }
                .droitPriseMoreBox {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .droitPriseMore {
                        font-size: 0.12rem;
                        width: 1rem;
                        height: 0.18rem;
                        background: #ffffff;
                        border-radius: 50px;
                        color: #6f8c52;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 0.11rem;
                            height: 0.09rem;
                            margin-left: 0.04rem;
                        }
                    }
                }
            }
        }
    }
}
.lx {
    width: 11.9rem;
    // width: 12rem;
    display: flex;
    margin: 0 auto;
    // margin-top: 0.22rem;
    // margin-top: 0.52rem;
    .lxLeft {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // flex: 1;
        justify-content: space-between;
        // width: 9.6rem;
        width: 7.07rem;

        .lxLeftLi {
            // width: 33.33%;
            // width: 3.15rem;
            width: 2.31rem;
            height: 2.1rem;
            color: #ffffff;
            // font-size: 1.36rem;
            font-size: 1rem;
            font-weight: 700;
            font-family: 'yshst';
            background: #6f8c52;
            position: relative;
            margin-bottom: 0.07rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .lxLeftLiNum {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .lxLeft::after {
        content: '';
        width: 33.33%;
    }
    .lxRight {
        // width: 9.6rem;
        flex: 1;
        height: 6.44rem;
        // height: 6.51rem;
        background: #6f8c52;
        padding: 0.5rem 0.15rem;
        box-sizing: border-box;

        .lxTit {
            width: 1.77rem;
            height: 0.55rem;
            border: 1px solid #ffffff;
            font-size: 0.36rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.1rem;
            font-family: 'SourceHanSansCN';
            padding-left: 0.1rem;
            box-sizing: border-box;
            font-weight: 700;
        }
        .lxTip {
            font-size: 0.15rem;
            color: #ffffff;
            font-family: 'SourceHanSansCN';
            letter-spacing: 0.2rem;
            font-weight: 200;
        }
        .lxMore {
            font-size: 0.17rem;
            color: #ffffff;
            font-family: 'SourceHanSansCN';
            font-weight: 200;
        }
        img {
            width: 0.3rem;
            height: 0.15rem;
            display: block;
            margin-top: 0.1rem;
        }
        .lxLiBox {
            margin-top: 0.43rem;
            .lxLi {
                width: 3.83rem;
                color: #ffffff;
                font-size: 0.17rem;
                font-weight: 0;
                font-family: 'SourceHanSansCN';
                margin-bottom: 0.2rem;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
.ldBox {
    width: 100%;
    height: 4.78rem;
    background: url('../assets/headersix.png') no-repeat center;
    background-size: cover;
    padding-top: 0.3rem;
    .ld {
        // width: 12rem;
        width: 11.9rem;
        margin: 0 auto;
        .lfBox {
            width: 100%;
            height: 1.11rem;
            background: rgba(111, 140, 82, 0.8);
            padding: 0.12rem;
            box-sizing: border-box;
            margin-bottom: 0.17rem;
            .lfTop {
                display: flex;
                justify-content: space-between;
                .lfTit {
                    width: 1.77rem;
                    height: 0.55rem;
                    border: 1px solid #ffffff;
                    font-size: 0.36rem;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    letter-spacing: 0.1rem;
                    font-family: 'SourceHanSansCN';
                    padding-left: 0.1rem;
                    box-sizing: border-box;
                    font-weight: 700;
                }
                img {
                    width: 0.3rem;
                    height: 0.15rem;
                }
            }
            .lfTip {
                padding: 0 0.12rem;
                box-sizing: border-box;
                font-size: 0.16rem;
                color: #000000;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
                letter-spacing: 0.2rem;
                font-weight: normal;
                width: 6.3rem;
                height: 0.21rem;
                font-weight: 200;
                background: linear-gradient(90deg, #efce63 0%, #efce6300 100%);
            }
        }
        .titB {
            padding: 0.3rem 0 0.47rem;
            width: 9.9rem;
            margin: 0 auto;
            .tit {
                color: #f4fcf6;
                font-weight: 0;
                font-size: 0.5959rem;
                font-family: 'almmszht';
                letter-spacing: 3.58px;
                text-align: center;
            }
            .tip {
                color: #f4fcf6;
                font-weight: 0;
                text-align: right;
                font-size: 0.298rem;
                font-family: 'almmszht';
                letter-spacing: 1.79px;
                margin-top: 0.2rem;
                text-align: right;
            }
        }
        .joinBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px dashed #ffffff;
            padding-top: 0.3rem;
            .joinLi {
                background: #ffffff;
                border-radius: 0.1rem;
                width: 1.99rem;
                height: 2.81rem;
                text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // flex-direction: column;
                box-shadow: 2.5px 4.33px 5px 0 #6f8c524d;
                padding-top: 0.36rem;
                box-sizing: border-box;
                .joinLiImg {
                    height: 0.89rem;
                    display: flex;
                    align-items: flex-end;
                }
                img {
                    width: 0.93rem;
                    height: 0.89rem;
                    display: block;
                    margin: 0 auto;
                }
                .joinName {
                    color: #000000;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    margin-top: 0.2rem;
                }
                .joinNameA {
                    margin-top: 0;
                    font-weight: 300;

                    margin-top: -0.08rem;
                }

                .joinBtn {
                    width: 0.99rem;
                    height: 0.3rem;

                    background: #6f8c52;

                    color: #ffffff;
                    font-size: 0.2rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    border-radius: 0.5rem;
                    margin: 0.18rem auto 0;
                }
            }
        }
    }
}
.ewm {
    width: 100%;
    height: 3.04rem;
    background: #efce63;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.2rem;
    .ewmLi {
        width: 1.7rem;
        margin-left: 0.3rem;
        img {
            width: 100%;
            height: 1.7rem;
            display: block;
            border-radius: 10px;
            border: 1px solid #6f8c52;
        }
        .ewmName {
            color: #000000;
            // font-size: 0.2315rem;
            font-size: 0.21rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
            margin-top: 0.1rem;
        }
    }
}
.xfBox {
    position: fixed;
    right: 0.1rem;
    bottom: 2.5rem;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .kefu {
        width: 0.5rem;
        height: 0.5rem;
        background: #126324;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 0.3rem;
            height: 0.3rem;
        }
    }
    .xfLi {
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            width: 0.5rem;
            height: 0.5rem;
            display: block;
        }

        .xfLiName {
            color: #000000;
            font-size: 0.13rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
        }
    }
}
.footer {
    width: 100%;
    height: 3.43rem;
    background: #6f8c52;
    display: flex;
    .footerLeft {
        width: 11.9rem;
        margin: 0 auto;
        display: flex;
        .footerDisplay {
            // width: 1.13rem;
            // height: 1.39rem;
            width: 0.9rem;
            height: 1.16rem;
            background: url('../assets/lobg.png') no-repeat center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.24rem;
            border-radius: 0 0 0.5rem 0;
            img {
                width: 0.76rem;
                height: 0.91rem;
            }
        }
        .footerContBox {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.43rem;
            flex: 1;
        }
        .footerCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .footerContLi {
                margin-right: 0.76rem;

                margin-bottom: 0.2rem;
                .footerContName {
                    width: 1.26rem;
                    height: 0.36rem;
                    color: #000000;
                    font-weight: 0;
                    font-size: 0.22rem;
                    font-family: 'SourceHanSansCN';
                    background: #efce63;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0 0.5rem 0.5rem 0;
                }
                .footerContText {
                    color: #ffffff;
                    font-size: 0.18rem;
                    font-family: 'SourceHanSansCN';
                    margin: 0.1rem 0.2rem;
                }
            }
        }
    }
}
</style>
