import Vuex from 'vuex'
import Vue from 'vue'
import category from './modules/category'
import newsList from './modules/newsList'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        loginFlag: false,
    },
    mutations:{

        setMessage(state, newMessage) {
            state.loginFlag  = newMessage;
          }
    },
    actions: {
        updateMessageAsync({ commit }, newMessage) {
            commit('setMessage', newMessage);
          }
    },
    getters:{
        categoryList:(state)=>{
            return state.category.categoryList
        },
        currentID:(state)=>{
            return state.category.currentID
        }
    },
    modules:{
        category,
        newsList
    }
})
export default store

