<template>
    <div class="content" @scroll="handleScroll">
        <div class="bgColor"></div>
        <HeaderTopNew
            :parentData="dataNum"
            :backgroundImage="backgroundBg"
            :flagView="flagView"
            @childMessage="handleChildMessage"
        ></HeaderTopNew>
        <div class="banner">
            <div class="bannerTit">
                <div class="bannerTitName">请选择您的身份</div>
                <div class="bannerTitTip">PLEASE CHOOSE YOUR IDENTITY</div>
                <div class="bannerTitTipDiv"></div>
            </div>
            <div class="navTabBox">
                <div class="navTabBoxLi" @click="toUrl('/manuFacturer')">
                    <img src="../assets/cwlsIcon.png" alt="" />
                    <div class="navTabBoxName">成为粮市</div>
                    <div class="navTabBoxTig">(成品制造商)</div>
                    <div class="navTabBoxBtn">欢迎加入</div>
                </div>
                <div class="navTabBoxLi" @click="toUrl('/purchasingAgent')">
                    <img src="../assets/cwlfIcon.png" alt="" />
                    <div class="navTabBoxName">成为粮坊</div>
                    <div class="navTabBoxTig">(采购商)</div>
                    <div class="navTabBoxBtn">欢迎加入</div>
                </div>
                <div class="navTabBoxLi" @click="toUrl('/supplierRz')">
                    <img src="../assets/cwlcIcon.png" alt="" />
                    <div class="navTabBoxName">成为粮仓</div>
                    <div class="navTabBoxTig">(供应商)</div>
                    <div class="navTabBoxBtn">欢迎加入</div>
                </div>
                <div class="navTabBoxLi" @click="toUrl('/driverRz')">
                    <img src="../assets/cwlyIcon.png" alt="" />
                    <div class="navTabBoxName">成为粮运</div>
                    <div class="navTabBoxTig">(司机)</div>
                    <div class="navTabBoxBtn">欢迎加入</div>
                </div>
                <div class="navTabBoxLi" @click="toUrl('/farmingRz')">
                    <img src="../assets/cwlgIcon.png" alt="" />
                    <div class="navTabBoxName">成为粮耕</div>
                    <div class="navTabBoxTig">(粮农)</div>
                    <div class="navTabBoxBtn">欢迎加入</div>
                </div>
            </div>
        </div>
        <div class="titleBox">
            <div class="titleBoxTop">
                <div class="titleBoxName">为什么选择我们？</div>
                <div class="titleBoxtip">WHY CHOOSE US?</div>
                <div class="titleBoxDiv"></div>
            </div>
            <div class="enterpriseCulture">
                <div class="enterpriseCultureImg">
                    <img :src="dataMsg.why.image1" alt="" />
                </div>
                <div class="enterpriseCultureName">
                    <div
                        v-for="(item, index) in dataMsg.why.imgName1"
                        :key="index"
                    >
                        {{ item }}
                    </div>
                </div>
                <div class="enterpriseCultureRight">
                    <div class="enterpriseCultureRightLi">
                        <div class="enterpriseCultureRightDiv">
                            <div class="enterpriseCultureRightNum">01</div>
                            <div class="enterpriseCultureRighttext">愿景</div>
                        </div>
                        <div class="enterpriseCultureRightCont">
                            <div class="enterpriseCultureRightDivName">
                                {{ dataMsg.why.title1 }}
                            </div>
                            <div class="enterpriseCultureRightDivNameHtml">
                                {{ dataMsg.why.describe1 }}
                            </div>
                        </div>
                    </div>
                    <div class="enterpriseCultureRightLi">
                        <div class="enterpriseCultureRightDiv">
                            <div class="enterpriseCultureRightNum">02</div>
                            <div class="enterpriseCultureRighttext">使命</div>
                        </div>
                        <div class="enterpriseCultureRightCont">
                            <div class="enterpriseCultureRightDivName">
                                {{ dataMsg.why.title2 }}
                            </div>
                            <div class="enterpriseCultureRightDivNameHtml">
                                {{ dataMsg.why.describe2 }}
                            </div>
                        </div>
                    </div>
                    <div class="enterpriseCultureRightLi">
                        <div
                            class="enterpriseCultureRightDiv"
                            style="line-height: 0.5rem"
                        >
                            <div class="enterpriseCultureRightNum">03</div>
                            <div
                                class="enterpriseCultureRighttext"
                                style="font-size: 0.23rem; line-height: 0.3rem"
                            >
                                <div>核心</div>
                                价值观
                            </div>
                        </div>
                        <div class="enterpriseCultureRightCont">
                            <div class="enterpriseCultureRightDivName">
                                {{ dataMsg.why.title3 }}
                            </div>
                            <div class="enterpriseCultureRightDivNameHtml">
                                {{ dataMsg.why.describe3 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="enterpriseCulture">
                <div class="enterpriseCultureImg" style="width: 2.68rem">
                    <img :src="dataMsg.why.image2" alt="" />
                </div>
                <div
                    class="enterpriseCultureName"
                    style="font-size: 0.3rem; line-height: 0.42rem"
                >
                    <div>完</div>
                    <div>善</div>
                    <div>的</div>
                    <div style="color: #000000; margin-left: 0.29rem">供、</div>
                    <div style="color: #000000; margin-left: 0.29rem">销、</div>
                    <div style="color: #000000; margin-left: 0.29rem">运、</div>
                    <div style="color: #000000; margin-left: 0.29rem">存、</div>
                    <div>体</div>
                    <div>系</div>
                </div>
                <div
                    class="enterpriseCultureRight"
                    style="margin-left: 0.07rem"
                >
                    <div class="enterpriseCultureRightLi">
                        <div
                            class="enterpriseCultureRightDiv"
                            style="height: 1.15rem"
                        >
                            <div class="enterpriseCultureRightNum">01</div>
                        </div>
                        <div
                            class="enterpriseCultureRightCont"
                            style="
                                flex: 1;
                                height: 1.15rem;
                                border-bottom: 1px solid #6f8c53;
                                margin-left: 0;

                                padding-left: 0.23rem;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <div
                                class="enterpriseCultureRightDivNameHtml"
                                style="
                                    font-size: 0.17rem;
                                    color: #38383a;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 5;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    height: 1.15rem;
                                    margin-top: 0;
                                "
                                v-html="dataMsg.why.content1"
                            ></div>
                        </div>
                    </div>
                    <div class="enterpriseCultureRightLi">
                        <div
                            class="enterpriseCultureRightDiv"
                            style="height: 1.15rem"
                        >
                            <div class="enterpriseCultureRightNum">02</div>
                        </div>
                        <div
                            class="enterpriseCultureRightCont"
                            style="
                                flex: 1;
                                height: 1.15rem;
                                border-bottom: 1px solid #6f8c53;
                                margin-left: 0;

                                padding-left: 0.23rem;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <div
                                class="enterpriseCultureRightDivNameHtml"
                                style="
                                    font-size: 0.17rem;
                                    color: #38383a;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 5;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    height: 1.15rem;
                                    margin-top: 0;
                                "
                                v-html="dataMsg.why.content2"
                            ></div>
                        </div>
                    </div>
                    <div class="enterpriseCultureRightLi">
                        <div
                            class="enterpriseCultureRightDiv"
                            style="height: 1.15rem"
                        >
                            <div class="enterpriseCultureRightNum">03</div>
                        </div>
                        <div
                            class="enterpriseCultureRightCont"
                            style="
                                flex: 1;
                                height: 1.15rem;
                                border-bottom: 1px solid #6f8c53;
                                margin-left: 0;

                                padding-left: 0.23rem;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <div
                                class="enterpriseCultureRightDivNameHtml"
                                style="
                                    font-size: 0.17rem;
                                    color: #38383a;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 5;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    height: 1.15rem;
                                    margin-top: 0;
                                "
                                v-html="dataMsg.why.content3"
                            ></div>
                        </div>
                    </div>
                    <div class="enterpriseCultureRightLi">
                        <div
                            class="enterpriseCultureRightDiv"
                            style="height: 1.15rem"
                        >
                            <div class="enterpriseCultureRightNum">04</div>
                        </div>
                        <div
                            class="enterpriseCultureRightCont"
                            style="
                                flex: 1;
                                height: 1.15rem;
                                border-bottom: 1px solid #6f8c53;
                                margin-left: 0;

                                padding-left: 0.23rem;
                                display: flex;
                                align-items: center;
                            "
                        >
                            <div
                                class="enterpriseCultureRightDivNameHtml"
                                style="
                                    font-size: 0.17rem;
                                    color: #38383a;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 5;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    height: 1.15rem;
                                    margin-top: 0;
                                "
                                v-html="dataMsg.why.content4"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footerBottom">
            <div class="footerBottomCont">
                <div class="footerBottomTit">
                    请选择您的身份，加入粮之道大家庭!
                </div>
                <div class="footerBottomTip">——用实力服务每一位客户</div>
            </div>
            <img src="../assets/footerIoc.png" alt="" />
        </div>
        <!-- <div class="contBox">
            <div class="contMsg">
                <div class="contImg">
                    <img :src="dataMsg.company.pic" alt="" />
                </div>
                <div class="contText">
                    <div class="contTit">{{ dataMsg.company.title }}</div>
                    <div class="contfu">
                        {{ dataMsg.company.introduce }}
                    </div>
                    <div class="contBtn" @click="toUrl('/aboutUs')">
                        集团详情<img src="../assets/righticon.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="contDisplay">
                <div class="contDisplayLi">
                    <div class="contDisplayNum">
                        {{ dataMsg.navbar.num1
                        }}<span>{{ dataMsg.navbar.dw1 }}</span>
                    </div>
                    <div class="contDisplayName">
                        {{ dataMsg.navbar.text1 }}
                    </div>
                </div>
                <div class="contDisplayLine"></div>
                <div class="contDisplayLi">
                    <div class="contDisplayNum">
                        {{ dataMsg.navbar.num2
                        }}<span>{{ dataMsg.navbar.dw2 }}</span>
                    </div>
                    <div class="contDisplayName">
                        {{ dataMsg.navbar.text2 }}
                    </div>
                </div>
                <div class="contDisplayLine"></div>

                <div class="contDisplayLi">
                    <div class="contDisplayNum">
                        {{ dataMsg.navbar.num3
                        }}<span>{{ dataMsg.navbar.dw3 }}</span>
                    </div>
                    <div class="contDisplayName">
                        {{ dataMsg.navbar.text3 }}
                    </div>
                </div>
                <div class="contDisplayLine"></div>

                <div class="contDisplayLi">
                    <div class="contDisplayNum">
                        {{ dataMsg.navbar.num4
                        }}<span>{{ dataMsg.navbar.dw4 }}</span>
                    </div>
                    <div class="contDisplayName">
                        {{ dataMsg.navbar.text4 }}
                    </div>
                </div>
            </div>
        </div>
        <div class="chooseBox">
            <div class="chooseTit">为什么选择我们？</div>
            <div class="chooseFu">
                独特地利用最佳对齐实现直观的带宽。快速协调电子商务<br />
                通过革命性的催化剂应用。
            </div>
            <div class="chooseCont">
                <div class="chooseContLi">
                    <img :src="dataMsg.choose.pic1" alt="" />
                    <div class="chooseContName">
                        {{ dataMsg.choose.title1 }}
                    </div>
                    <div class="chooseContTip">
                        {{ dataMsg.choose.describe1 }}
                    </div>
                    <div
                        class="chooseContBtn"
                        @click="wenben(dataMsg.choose, 1)"
                    >
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
                <div class="chooseContLi">
                    <img :src="dataMsg.choose.pic2" alt="" />
                    <div class="chooseContName">
                        {{ dataMsg.choose.title2 }}
                    </div>
                    <div class="chooseContTip">
                        {{ dataMsg.choose.describe2 }}
                    </div>
                    <div
                        class="chooseContBtn"
                        @click="wenben(dataMsg.choose, 2)"
                    >
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
                <div class="chooseContLi">
                    <img :src="dataMsg.choose.pic3" alt="" />
                    <div class="chooseContName">
                        {{ dataMsg.choose.title3 }}
                    </div>
                    <div class="chooseContTip">
                        {{ dataMsg.choose.describe3 }}
                    </div>
                    <div
                        class="chooseContBtn"
                        @click="wenben(dataMsg.choose, 3)"
                    >
                        了解更多信息<span class="el-icon-arrow-right"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabBox">
            <div class="tabLi" @click="toUrl('/manuFacturer')">
                <img src="../assets/cpzzs.png" alt="" />
                <div class="tabName">成为粮市(成品制造商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/purchasingAgent')">
                <img src="../assets/cgs.png" alt="" />
                <div class="tabName">成为粮坊(采购商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/supplierRz')">
                <img src="../assets/gys.png" alt="" />
                <div class="tabName">成为粮仓(供应商)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/driverRz')">
                <img
                    src="../assets/sj.png"
                    alt=""
                    style="width: 1.44rem; height: 1.61rem"
                />
                <div class="tabName">成为粮运(司机)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
            <div class="tabLi" @click="toUrl('/farmingRz')">
                <img src="../assets/yk.png" alt="" />
                <div class="tabName">成为粮耕(粮农)</div>
                <div class="tabBtn">欢迎加入</div>
            </div>
           
        </div> -->
    </div>
</template>

<script>
import { userInfo } from '@/api/user/index.js'
export default {
    data() {
        return {
            flagView: true,
            dataNum: 6,
            backgroundBg: require('@/assets/dhBg.png'),
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            userInfo: {},
            dataMsg: {
                company: {},
                choose: {},
                navbar: {},
                why: {}
            }
        }
    },
    created() {
        if (localStorage.getItem('token')) {
            this.getUser()
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        // 移除事件监听
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            this.scrollPosition = window.scrollY || window.pageYOffset // 对于旧版IE使用window.pageYOffset, 对于现代浏览器使用window.scrollY
            console.log(this.scrollPosition, '页面滚动事件被触发')
            // 在这里处理你的逻辑
            if (this.scrollPosition <= 0) {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = true
            } else {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = false
            }
            console.log(this.backgroundBg, '页面滚动事件被触发')
        },
        wenben(data, index) {
            localStorage.setItem('textCont', JSON.stringify(data))
            this.$router.push({ path: '/adoutXq', query: { type: index } })
        },
        handleChildMessage(msg) {
            console.log(msg, 651231)
            let arrayNameOne = []
            let arrayNameTwo = []
            for (var a = 0; a < msg.why.imgName1.length; a++) {
                arrayNameOne.push(msg.why.imgName1[a])
            }
            for (var b = 0; b < msg.why.imgName2.length; b++) {
                arrayNameTwo.push(msg.why.imgName2[b])
            }
            msg.why.imgName1 = arrayNameOne
            msg.why.imgName2 = arrayNameTwo
            this.dataMsg = msg
        },
        getUser() {
            userInfo().then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.userInfo = res.data
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }
            })
        },
        toUrl(urlSrc) {
            if (urlSrc == '/aboutUs') {
                this.$router.push(urlSrc)
                return
            }
            if (!localStorage.getItem('token')) {
                this.$message({
                    message: '请登录后操作！',
                    type: 'warning'
                })
                return
            }
            // if(urlSrc=='/HelloWorld'){
            //     this.$router.push(urlSrc)
            // }else{
            if (this.userInfo.userType == 1) {
                this.$message({
                    message: '您已是粮市(成品制造商)！',
                    type: 'warning'
                })
                return
            }
            if (this.userInfo.userType == 2) {
                this.$message({
                    message: '您已是粮坊(采购商)！',
                    type: 'warning'
                })

                return
            }
            if (this.userInfo.userType == 3) {
                this.$message({
                    message: '您已是粮仓(供货商)！',
                    type: 'warning'
                })

                return
            }

            if (this.userInfo.userType == 4) {
                this.$message({
                    message: '您已是粮运(司机)！',
                    type: 'warning'
                })

                return
            }
            if (this.userInfo.userType == 5) {
                this.$message({
                    message: '您已是粮耕(粮农)！',
                    type: 'warning'
                })

                return
            }
            this.$router.push(urlSrc)
        }
    }
    // }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.4rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.banner {
    width: 100%;
    height: 6.72rem;
    background: url('../assets/ldBg.png') no-repeat center;
    background-size: 100% 100%;
    .bannerTit {
        width: 11.9rem;
        height: 2.02rem;
        margin: 0 auto;
        background: rgba(111, 140, 83, 0.7);
        text-align: center;
        padding-top: 0.6rem;

        box-sizing: border-box;

        .bannerTitName {
            color: #ffffff;
            font-size: 0.5rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .bannerTitTip {
            color: #ffffff;
            font-size: 0.16rem;
            font-weight: 300;
            font-family: 'SourceHanSansCN';
        }
        .bannerTitTipDiv {
            width: 1.71rem;
            height: 0.11rem;
            background: #efce63;
            margin: 0.16rem auto 0;
        }
    }
    .navTabBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 11.9rem;
        margin: 0.9rem auto 0;
        .navTabBoxLi {
            width: 2.04rem;
            height: 2.82rem;
            background: #6f8c53;
            border-radius: 0.1rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
                width: 1.11rem;
                height: 1.11rem;
            }
            .navTabBoxName {
                color: #ffffff;
                font-size: 0.2rem;
                font-weight: 500;
                text-align: center;
                font-family: 'SourceHanSansCN';
            }
            .navTabBoxTig {
                color: #ffffff;
                font-size: 0.2rem;

                font-weight: 400;
                text-align: center;
                font-family: 'SourceHanSansCN';
            }
            .navTabBoxBtn {
                color: #38383a;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                width: 0.89rem;
                height: 0.26rem;
                border-radius: 0.95rem;

                margin: 0.2rem auto 0;
                background: linear-gradient(0deg, #efce63 0%, #fae9b1 100%);
            }
        }
    }
}
.titleBox {
    width: 11.9rem;
    margin: 0.22rem auto 0;
    .titleBoxTop {
        text-align: center;
        border-bottom: 1px solid #9da19f;
        padding-bottom: 0.24rem;

        position: relative;
        .titleBoxName {
            color: #38383a;
            font-size: 0.49rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .titleBoxtip {
            color: #38383a;
            font-size: 0.18rem;
            font-weight: 300;
            font-family: 'SourceHanSansCN';
        }
        .titleBoxDiv {
            width: 1.69rem;
            height: 0.1rem;
            background: #6f8c53;
            position: absolute;
            bottom: -0.05rem;
            left: 43%;
        }
    }
    .enterpriseCulture {
        margin-top: 0.3rem;
        display: flex;
        justify-content: space-between;
        .enterpriseCultureImg {
            width: 4.04rem;
            height: 5.21rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.2rem 0 0 0.2rem;
                object-fit: cover;
            }
        }
        .enterpriseCultureName {
            width: 1.07rem;
            height: 5.21rem;
            border-radius: 0 0.2rem 0.2rem 0;
            background: #e5e5e5;
            color: #6f8c53;
            font-size: 0.48rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-left: 0.06rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 0.52rem;
        }
        .enterpriseCultureRight {
            flex: 1;
            margin-left: 0.12rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .enterpriseCultureRightLi {
                display: flex;
                align-items: center;
                .enterpriseCultureRightDiv {
                    width: 1.15rem;
                    height: 1.5rem;
                    background: #6f8c53;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    line-height: 0.4rem;

                    .enterpriseCultureRightNum {
                        color: #ffffff;
                        font-size: 0.5rem;
                        font-weight: 500;
                        font-family: 'yshst';
                        text-align: center;
                    }
                    .enterpriseCultureRighttext {
                        color: #ffffff;
                        font-size: 0.3rem;
                        font-weight: 400;
                        text-align: center;
                        line-height: 0.4rem;
                        font-family: 'SourceHanSansCN';
                    }
                }
                .enterpriseCultureRightCont {
                    margin-left: 0.24rem;
                    width: 5.2rem;
                    .enterpriseCultureRightDivName {
                        color: #38383a;
                        font-size: 0.23rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                    }
                    .enterpriseCultureRightDivNameHtml {
                        color: #38383a;
                        font-size: 0.18rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        margin-top: 0.09rem;
                    }
                }
            }
        }
    }
}
.footerBottom {
    width: 100%;
    height: 4.9rem;
    background: url('../assets/mt.png') no-repeat center;
    background-size: 100% 100%;
    margin-top: 0.29rem;
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;
    .footerBottomCont {
        .footerBottomTit {
            color: #f4fcf6;
            font-size: 0.6rem;
            font-weight: 400;
            font-family: 'almmszht';
            text-shadow: 2px 2px 4px #562b09;
        }
        .footerBottomTip {
            color: #f4fcf6;
            font-size: 36px;
            font-weight: 400;
            font-family: 'almmszht';
            text-align: right;
            margin-top: 0.05rem;
            text-shadow: 2px 2px 4px #562b09;
        }
    }
    img {
        width: 7.04rem;
        height: 1.01rem;
        margin-top: 0.4rem;
    }
}
.tabBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5rem;
    box-sizing: border-box;

    .tabLi {
        width: 4.73rem;
        height: 4.92rem;
        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        margin-bottom: 1.1rem;
        text-align: center;
        padding-top: 0.7rem;

        img {
            width: 1.62rem;
            height: 1.58rem;
        }

        .tabName {
            color: #333333;
            font-size: 0.48rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            margin-top: 0.646rem;
            text-align: center;
        }

        .tabBtn {
            width: 1.58rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            line-height: 0.5rem;
            margin: 0.36rem auto;
        }
    }
}

.tabBox::after {
    content: '';
    width: 4.73rem;
}
.contBox {
    width: 100%;
    height: 8.97rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url('@/assets/jsBg.png') no-repeat center;
    background-size: 100% 100%;

    .contMsg {
        display: flex;
        align-items: center;

        .contImg {
            img {
                width: 5.63rem;
                height: 3.91rem;
                border-radius: 0 0.4rem 0 0.4rem;
                object-fit: cover;
            }
        }

        .contText {
            width: 5.55rem;
            margin-left: 0.56rem;

            .contTit {
                color: #000000;
                font-size: 0.45rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }

            .contfu {
                color: #666666;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
            }

            .contBtn {
                width: 1.8rem;
                height: 0.5799rem;
                border-radius: 1.0356rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.5rem;

                img {
                    width: 0.2568rem;
                    height: 0.0603rem;
                    margin-left: 0.08rem;
                    object-fit: cover;
                }
            }
        }
    }

    .contDisplay {
        width: 12.09rem;
        height: 1.48rem;
        background: #ffffff;
        margin-top: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contDisplayLi {
            padding: 0 0.35rem;
            box-sizing: border-box;

            .contDisplayNum {
                color: #00943f;
                font-size: 0.3rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';

                span {
                    font-size: 0.16rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.04rem;
                }
            }

            .contDisplayName {
                color: #282828;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
        }

        .contDisplayLine {
            width: 0.01rem;
            height: 0.39rem;
            background: #f6f7f7;
        }
    }
}

.chooseBox {
    padding: 1.29rem 0 1rem;
    background: #fafafa;

    .chooseTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .chooseFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
        margin-top: 0.12rem;
    }

    .chooseCont {
        display: flex;
        align-items: center;
        width: 11.7rem;
        justify-content: space-between;
        margin: 0.9rem auto 0;

        .chooseContLi {
            width: 3.7rem;
            height: 6.16rem;
            opacity: 1;
            background: #ffffff;

            img {
                width: 3.7rem;
                height: 3.3rem;
                object-fit: cover;
            }

            .chooseContName {
                color: #000000;
                font-size: 0.24rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
                text-align: center;
                margin-top: 0.36rem;
            }

            .chooseContTip {
                color: #aaaaaa;
                font-size: 0.15rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                width: 2.24rem;
                margin: 0.18rem auto 0;
                display: -webkit-box;
                /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                /*显示行数*/
                overflow: hidden;
                /*隐藏多出部分文字*/
                text-overflow: ellipsis;
                /*用省略号代替多出部分文字*/
            }

            .chooseContBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                margin: 0.57rem auto 0;
            }
        }

        .chooseContLi:hover {
            box-shadow: 0.15rem 0.25rem 0.6rem -0.05rem #0000001a;
        }
    }
}
</style>