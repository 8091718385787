<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="header">
            <div class="headerNext">
                <div class="headerAddress">
                    <div class="headerAddressIcon">
                        <div class="el-icon-location"></div>
                    </div>
                    <div class="headerAddName">
                        {{ dataMsg.addressEntity.userName
                        }}<span>{{ dataMsg.addressEntity.userPhone }}</span>
                    </div>
                </div>
                <div class="headerAddressMsg">
                    {{ dataMsg.addressEntity.provinceName
                    }}{{ dataMsg.addressEntity.cityName
                    }}{{ dataMsg.addressEntity.countyName
                    }}{{ dataMsg.addressEntity.address }}
                    <!-- <div class="el-icon-arrow-right"></div> -->
                </div>
            </div>
        </div>
        <div
            class="shopBox"
            v-for="(item, index) in dataMsg.sumCartPriceVO.goodsList"
            :key="index"
        >
            <div class="shopTop" @click="qyGo(item.companyId)">
                <img src="../../assets/shopIcon.png" alt="" />
                <div class="shopName">
                    {{ item.company }}
                    <div class="el-icon-arrow-right"></div>
                </div>
            </div>
            <div v-for="(obj, objIndex) in item.cartList" :key="objIndex">
                <div class="shopList" @click="spGo(obj.goodsId)">
                    <div class="shopImage">
                        <img :src="obj.goodsImg" alt="" />
                    </div>
                    <div class="shopCont">
                        <div class="shopContTop">
                            <div class="shopContName">
                                {{ obj.goodsName || '' }}
                            </div>
                            <div class="shopContPrice">
                                ￥{{ obj.goodsPrice || '' }}
                            </div>
                        </div>
                        <div class="shopContTip">
                            规格：{{ obj.specName || '' }}
                        </div>
                        <div class="shopContTip">
                            数量：{{ obj.goodsNum || '' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="boxTit">商品总价</div>
            <div class="boxList">
                <div class="boxLeft">运费</div>
                <div
                    class="boxRight"
                    v-if="dataMsg.sumCartPriceVO.freightTotal > 0"
                >
                    ￥{{ dataMsg.sumCartPriceVO.freightTotal }}
                </div>
                <div
                    class="boxRight"
                    v-if="dataMsg.sumCartPriceVO.freightTotal <= 0"
                >
                    --
                </div>
            </div>
        </div>
        <div class="box" v-for="(item, index) in ghMsg" :key="index">
            <div class="boxTit">公户信息{{ index + 1 }}</div>
            <div class="boxList">
                <div class="boxLeft">公司名称</div>
                <div class="boxRight">
                    {{ item.accountName }}
                </div>
            </div>
            <div class="boxList">
                <div class="boxLeft">开户银行</div>
                <div class="boxRight">
                    {{ item.bank }}
                </div>
            </div>
            <div class="boxList">
                <div class="boxLeft">银行卡号</div>
                <div class="boxRight">
                    {{ item.accountNumber }}
                </div>
            </div>
        </div>
        <div class="box">
            <div class="boxTit">付款凭证</div>
            <div class="boxStatus">
                <div class="boxStatusLi" @click="chooseType(1)">
                    <div
                        class="boxStatusQuan"
                        :class="paymentType == 1 ? 'boxStatusQuanA' : ''"
                    >
                        <div
                            class="boxStatusQuanNei"
                            v-if="paymentType == 1"
                        ></div>
                    </div>
                    线下打款
                </div>
                <div class="boxStatusLi" @click="chooseType(2)">
                    <div
                        class="boxStatusQuan"
                        :class="paymentType == 2 ? 'boxStatusQuanA' : ''"
                    >
                        <div
                            class="boxStatusQuanNei"
                            v-if="paymentType == 2"
                        ></div>
                    </div>
                    使用粮票
                </div>
            </div>
            <div class="boxImage" v-if="paymentType == 1">
                <div class="imagePer" @click="triggerFileInput">
                    <input
                        type="file"
                        @change="handleFileChange"
                        ref="fileInput"
                        name="avatar"
                        id="avatar"
                        accept="image/*"
                        style="display: none"
                    />
                    <img :src="urlSrc" alt="" v-if="urlSrc" />
                    <div class="notu" v-if="!urlSrc">
                        <img src="../../assets/photo.png" alt="" />
                        <div>图片</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footerBottom">
            <div>
                <CountDown :initialTime="dataMsg.djsTime"></CountDown>
            </div>
            <div class="footerBottomLi" @click="show = true">取消订单</div>
            <div class="footerBottomLi footerBottomLiA" @click="payMent">
                立即支付 ¥{{ dataMsg.totalPrice }}
            </div>
        </div>

        <div class="popupBig" v-if="wuliuFlag">
            <div class="popupBox">
                <div class="popupTopBig">
                    <div></div>
                    <div class="popupTit">查看详情</div>
                    <div class="el-icon-close"></div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">已下单</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <div class="line"></div>
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">06-28 16:59</div>
                            <div class="popupTextLi">
                                您的订单当前商家正在处理中
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">06-28 16:59</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <div class="line"></div>
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">
                                您的订单已完成初步审核，待商家进行下一步处理，请保持手机畅通，耐心等候。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popupCont">
                    <div class="popupTop">
                        <div class="popupImg">
                            <img src="../../assets/wlxq.png" alt="" />
                        </div>
                        <div class="popupName">06-28 16:59</div>
                    </div>
                    <div class="popupText">
                        <div class="popupTextLine">
                            <!-- <view class="line">
								</view> -->
                        </div>
                        <div class="popupTextCont">
                            <div class="popupTextLi">
                                订单正在审核中，审核结果会及时通知，请保持手机畅通，耐心等候。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CancelOrder v-if="show" @suBmit="suBmit" @close="close"></CancelOrder>
    </div>
</template>

<script>
import {
    lzdAccount,
    orderDetail,
    upload,
    uploadVoucher,
    orderCancel
} from '@/api/user/index.js'
export default {
    data() {
        return {
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',

            wuliuFlag: false,
            paymentType: 1,
            urlSrc: '',
            show: false,
            ghMsg: [],
            orderId: '',
            dataMsg: {
                sumCartPriceVO: {
                    freightTotal: '',
                    goodsList: [],
                    goodsTotal: ''
                }
            },
            btnFlag: false
        }
    },
    created() {
        console.log(this.$route.query.orderId, 'adsf')
        this.orderId = this.$route.query.orderId
        this.getOrder()
        this.getGhMsg()
    },
    methods: {
        close(e) {
            this.show = e
        },
        // 取消订单
        suBmit(num, flag) {
            orderCancel({
                groupCode: this.orderId,
                type: num,
                flag: flag
            }).then((res) => {
                this.show = false
                if (res.code == 0) {
                    this.$message({
                        message: '取消成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1500)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 立即支付
        payMent() {
            if (this.paymentType == 1) {
                if (!this.urlSrc) {
                    this.$message({
                        message: '请上传打款凭证',
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                    return
                }
            }
            if (this.btnFlag) {
                return
            }

            this.btnFlag = true
            uploadVoucher({
                groupCode: this.orderId,
                url: this.paymentType == 1 ? this.urlSrc : '',
                type: this.paymentType
            }).then((res) => {
                this.btnFlag = false

                if (res.code == 0) {
                    this.$message({
                        message: '支付成功，待审核！',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    setTimeout(() => {
                        this.$router.replace('/merchandiseOrderList')
                    }, 1500)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },

        // 上传图片
        triggerFileInput() {
            this.$refs['fileInput'].click()
        },
        handleFileChange(event) {
            console.log(event)
            this.selectedFile = event.target.files[0]
            if (!this.selectedFile) {
                return
            }
            const formData = new FormData()
            formData.append('file', this.selectedFile)
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            upload(formData)
                .then((res) => {
                    loading.close()
                    console.log(this.chooseNum)
                    this.urlSrc = res.data.src
                })
                .catch((error) => {
                    console.error('Error uploading image:', error)
                    // 处理上传失败后的逻辑
                })
        },
        // 跳转企业详情
        qyGo(id) {
            if (id) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: id }
                })
            }
        },
        // 跳转商品详情
        spGo(id) {
            this.$router.push({
                path: '/shopXq',
                query: { id: id }
            })
        },

        convertToTimestamp(timeString) {
            let currentTime = new Date().getTime()
            // 预处理字符串，将"-"替换为"/"（如果需要）
            let formattedTimeString = timeString.replace(/-/g, '/')

            // 创建Date对象（注意：这里假设时间字符串的格式是"YYYY/MM/DD hh:mm:ss"或"YYYY/MM/DD"）
            let date = new Date(formattedTimeString)

            // 检查Date对象是否有效
            if (isNaN(date.getTime())) {
                // alert('无效的时间字符串')
                return
            }

            console.log(
                Math.round(date.getTime()) / 1000 +
                    30 * 60 -
                    currentTime / 1000,
                '时间'
            )
            return Math.round(
                Math.round(date.getTime()) / 1000 + 30 * 60 - currentTime / 1000
            )
            // 获取时间戳
            // this.timestamp = Math.round(date.getTime() / 1000); // 转换为秒级时间戳（可选）
        },
        // 获取订单详情
        getOrder() {
            orderDetail({ groupCode: this.orderId }).then((res) => {
                res.data.djsTime = this.convertToTimestamp(res.data.createDate)
                this.dataMsg = res.data
            })
        },
        // 获取公户信息
        getGhMsg() {
            lzdAccount().then((res) => {
                this.ghMsg = res.data
            })
        },
        chooseType(num) {
            this.paymentType = num
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 15.12rem;
    border-radius: 0.32rem;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
    margin: 2rem auto 0;
    padding: 0.38rem 0.28rem;
    box-sizing: border-box;
    .headerTop {
        color: #12924a;
        font-size: 0.34rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        img {
            width: 0.42rem;
            height: 0.42rem;
            margin-right: 0.12rem;
        }
    }

    .headerTip {
        color: #555555e6;
        font-size: 0.24rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        padding-left: 0.5443rem;
        box-sizing: border-box;
        margin-top: 0.16rem;
    }
    .headerNext {
        margin-top: 0.26rem;
        .headerNextOne {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .headerNextOneLeft {
                display: flex;
                align-items: center;
                .headerNextOneLeftd {
                    width: 0.29rem;
                    height: 0.29rem;
                    background: #f9f9f9;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .headerNextOneLeftdN {
                        width: 0.13rem;
                        height: 0.13rem;
                        background: #cccccc;
                        border-radius: 50%;
                    }
                }

                .headerNextOneLeftName {
                    color: #1b1b1b;
                    font-size: 0.28rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.18rem;
                }
            }
            .headerNextOneRight {
                color: #1b1b1b;
                font-size: 0.28rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
        }
        .headerNextTwo {
            display: flex;

            .headerNextTwoLeft {
                width: 0.29rem;
                min-height: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .line {
                    width: 0.01rem;
                    height: 100%;
                    background: #cccccc;
                }
            }

            .headerNextTwoCont {
                padding: 0.08rem 0.18rem;
                box-sizing: border-box;
                color: #6b6b6bcc;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                line-height: 0.4rem;
            }
        }

        .headerAddress {
            display: flex;
            align-items: center;
            .headerAddressIcon {
                width: 0.29rem;
                height: 0.29rem;
                background: #12924a;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-icon-location {
                    font-size: 0.2rem;
                    color: #ffffff;
                }
            }
            .headerAddName {
                margin-left: 0.18rem;
                color: #1b1b1b;
                font-size: 0.28rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                span {
                    color: #6b6b6bcc;
                    font-size: 0.28rem;
                    margin-left: 0.1rem;
                }
            }
        }
        .headerAddressMsg {
            color: #6b6b6bcc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.27rem;
            line-height: 0.4rem;
            padding-left: 0.47rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.shopBox {
    width: 15.12rem;
    border-radius: 0.32rem;
    background: #ffffff;
    padding: 0.28rem;
    box-sizing: border-box;
    margin: 0.26rem auto 0;
    .shopTop {
        display: flex;
        align-items: center;

        img {
            width: 0.33rem;
            height: 0.35rem;
        }
        .shopName {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-left: 0.11rem;
        }
    }
    .shopList {
        display: flex;
        // align-items: center;
        margin-top: 0.22rem;
        .shopImage {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }
        .shopCont {
            margin-left: 0.26rem;

            flex: 1;
            .shopContTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .shopContName {
                    color: #1b1b1b;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .shopContPrice {
                    color: #1b1b1b;
                    font-size: 0.3rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                }
            }
            .shopContTip {
                color: #6b6b6bcc;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-top: 0.16rem;
            }
        }
    }
    .shopOrder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.26rem;
        .shopOrderLeft {
            color: #6b6b6bcc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        .shopOrderRight {
            color: #555555;
            font-size: 0.26rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }

    .shopShow {
        color: #8b8b8b;
        font-size: 0.26rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
        text-align: center;
        padding: 0.45rem;
        box-sizing: border-box;
    }
}
.box {
    width: 15.12rem;
    border-radius: 0.32rem;
    background: #ffffff;
    padding: 0.28rem;
    box-sizing: border-box;
    margin: 0.26rem auto 0;
    .boxTit {
        color: #1b1b1b;
        font-size: 0.28rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
    }
    .boxList {
        margin-top: 0.26rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .boxLeft {
            color: #6b6b6bcc;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        .boxRight {
            color: #555555;
            font-size: 0.26rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }
    .boxStatus {
        display: flex;
        align-items: center;
        margin-top: 0.27rem;
        .boxStatusLi {
            margin-right: 0.39rem;
            color: #798cb5;
            font-size: 0.38rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            .boxStatusQuan {
                width: 0.4rem;
                height: 0.4rem;
                border: 0.02rem solid #ced9f2;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.1rem;
                .boxStatusQuanNei {
                    width: 0.25rem;
                    height: 0.25rem;
                    background: #00913e;
                    border-radius: 50%;
                }
            }
            .boxStatusQuanA {
                border: 0.02rem solid #00913e;
            }
        }
    }
    .boxImage {
        display: flex;
        margin-top: 0.3rem;

        .imagePer {
            width: 3rem;
            height: 3rem;
            border-radius: 0.1rem;
            border: 0.01rem solid #dedede;
            display: flex;
            align-items: center;
            justify-content: center;

            .notu {
                font-size: 0.5rem;
                color: #b8c2d9;
                img {
                    width: 1rem;

                    height: 1rem;
                }
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem;
            }
        }

        .boxName {
            color: #253a70;
            font-size: 0.3rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 4rem;
            text-align: right;
            margin-right: 0.3rem;

            span {
                color: #f92800;
            }
        }
    }
}
.footerBottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    // background: #ffffff;
    // box-shadow: 0 -0.01rem 0.12rem 0 #0000001a;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 15.12rem;
    margin: 0.45rem auto;

    .footerBottomLi {
        min-width: 1.5738rem;
        height: 0.64rem;
        border-radius: 0.72rem;
        border: 0.02rem solid #383838;
        color: #555555;
        font-size: 0.28rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.16rem;
        //  margin-bottom: constant(safe-area-inset-bottom);
        //  margin-bottom: env(safe-area-inset-bottom);
    }
    .footerBottomLiA {
        padding: 0 0.5rem;
        background: #0e8f46;
        color: #ffffff;
        border: none;
    }
}
.popupBig {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;
    .popupBox {
        width: 7.5rem;
        border-radius: 0.32rem;
        background: #ffffff;
        margin: 4rem auto 0;

        padding: 0.3rem;

        box-sizing: border-box;
        .popupTopBig {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .popupTit {
                color: #1b1b1b;
                font-size: 0.3rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
            .el-icon-close {
                font-size: 0.3rem;

                color: #a6a6a6;
            }
        }
        .popupCont {
            .popupTop {
                display: flex;
                align-items: center;
                .popupImg {
                    width: 0.29rem;
                    height: 0.29rem;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                .popupName {
                    color: #1b1b1b;
                    font-size: 0.28rem;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.18rem;
                }
            }

            .popupText {
                display: flex;
                .popupTextLine {
                    width: 0.29rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .line {
                        width: 0.01rem;
                        min-height: 1.5rem;
                        background: #e5e5e5;
                    }
                }

                .popupTextCont {
                    margin-left: 0.18rem;
                    flex: 1;
                    .popupTextLi {
                        color: #6b6b6bcc;
                        font-size: 0.26rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        margin-top: 0.22rem;
                        line-height: 0.4rem;
                    }
                }
            }
        }
    }
}
</style>