<template>
    <div class="content" @scroll="handleScroll">
        <div class="bgHeader"></div>

        <div class="header">
            <HeaderTopNew
                :parentData="dataNum"
                :backgroundImage="backgroundBg"
                :flagView="flagView"
            ></HeaderTopNew>
            <div class="banner">
                <div class="bannerLeft" style="margin-top: 1.2rem">
                    <div class="bannerLeftTit">主推企业</div>
                    <div class="bannerLeftTitLiBox" style="height: 3.25rem">
                        <div
                            class="bannerLeftTitLi"
                            v-for="(item, index) in mainCompanyList"
                            :key="index"
                            @click="toUrl('/companyXq', item.id)"
                        >
                            <img :src="item.factoryImg" alt="" />
                            <div class="bannerLeftTitLiName">
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <el-carousel
                    :interval="5000"
                    arrow="always"
                    height="4.92rem"
                    indicator-position="outside"
                    style="position: relative; width: 3.33rem"
                >
                    <el-carousel-item
                        v-for="(item, index) in lunboList"
                        :key="index"
                    >
                        <img
                            :src="item.pic"
                            alt=""
                            style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 0.1rem;
                            "
                            @click="swiperIndex(index)"
                        />
                    </el-carousel-item>
                </el-carousel>
                <div class="shopTui">
                    <div class="shopTuiTop">
                        <div
                            class="shopTuiTopLi"
                            @click.stop="toUrl('/shopXq', mainGoodsListOne.id)"
                        >
                            <img :src="mainGoodsListOne.img" alt="" />
                        </div>
                        <div class="shopTuiLine"></div>
                        <div
                            class="shopTuiTopLi"
                            @click.stop="toUrl('/shopXq', mainGoodsListTwo.id)"
                        >
                            <img :src="mainGoodsListTwo.img" alt="" />
                        </div>
                    </div>
                    <div class="shopTuiBottom">
                        <div
                            class="shopTuiBottomLi"
                            @click.stop="
                                toUrl('/shopXq', mainGoodsListThree.id)
                            "
                        >
                            <img :src="mainGoodsListThree.img" alt="" />
                        </div>
                        <div
                            class="shopTuiBottomLi"
                            @click.stop="toUrl('/shopXq', mainGoodsListFour.id)"
                        >
                            <img :src="mainGoodsListFour.img" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div
                class="marketBox"
                style="background: #fafafa"
                v-if="saleList.length > 0"
            >
                <div
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 0.2rem;
                        box-sizing: border-box;
                    "
                >
                    <div style="width: 1rem"></div>

                    <div>
                        <div class="marketTit">今日特卖</div>
                    </div>
                    <div
                        style="
                            width: 1rem;
                            font-size: 0.2rem;
                            color: rgba(17, 17, 17, 0.5019607843);
                        "
                        @click="toUrl('/temaiList', 1)"
                    >
                        更多产品
                    </div>
                </div>
                <div class="jrtmBox">
                    <div
                        class="jrtmLi"
                        v-for="(item, index) in saleList"
                        :key="index"
                        @click="toUrl('/shopXq', item.id)"
                    >
                        <img :src="item.pic[0]" alt="" />
                        <div class="jrtmName">{{ item.title }}</div>
                    </div>
                </div>
            </div> -->
            <div class="titleIconBox">
                <div class="titleIcon">
                    <img src="../assets/jinrtm.png" alt="" />
                </div>
                <div class="titleCont">
                    <div class="titleName">今日特卖</div>
                    <div class="titleTip">精选好物推荐</div>
                </div>
                <div class="titleMore" @click="toUrl('/temaiList', 2)">
                    <div>查看更多<i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
            <div style="position: relative; width: 11.9rem; margin: 0 auto">
                <div class="shopBox">
                    <div
                        class="shopBoxLi"
                        v-for="(obj, objIndex) in saleList"
                        :key="objIndex"
                        @click.stop="toUrl('/shopXq', obj.id)"
                    >
                        <div
                            class="shopBoxImg"
                            :style="getBackgroundStyle(obj.pic[0])"
                        ></div>
                        <div class="shopBoxCont">
                            <div class="shopBoxBottom">
                                <div class="shopPrice">
                                    ￥<span class="shopNum">{{
                                        obj.price
                                    }}</span>
                                </div>
                            </div>
                            <div class="shopBoxTit">
                                {{ obj.title }}
                            </div>

                            <div
                                class="shopBoxBottomBtn"
                                @click.stop="gomai(obj)"
                            >
                                <div class="shopBoxBottomBtnLi">加入购物车</div>
                                <div class="shopBoxBottomBtnLiA">立即购买</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="titleIconBox">
                <div class="titleIcon">
                    <img src="../assets/jinrtm.png" alt="" />
                </div>
                <div class="titleCont">
                    <div class="titleName">粮市</div>
                    <div class="titleTip">品质铸就信赖</div>
                </div>
            </div>

            <div class="lsBox">
                <div
                    class="lsLi"
                    v-for="(item, index) in companyZeroList"
                    :key="index"
                    @click="toUrl('/companyXq', item.id)"
                >
                    <div class="lsImg">
                        <img :src="item.factoryImg" alt="" />
                    </div>
                    <div class="lsCont">
                        <div class="lsName">
                            {{ item.title }}
                        </div>

                        <div class="lsBtnBox">
                            <div class="lsBtn">
                                了解更多信息
                                <img src="../assets/rightI.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    box-sizing: border-box;
                    width: 11.9rem;
                    margin: 0.2rem auto;
                "
            >
                <el-pagination
                    :current-page="listQuery.page"
                    :page-sizes="[10]"
                    :page-size="listQuery.limit"
                    :total="listQuery.total"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="pageSizeChangeHandle"
                    @current-change="pageCurrentChangeHandle"
                >
                </el-pagination>
                <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
            </div>
        </div>
        <!-- <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
        </el-carousel> -->

        <!-- <FooterBottom></FooterBottom> -->
        <SpeciFication
            v-if="specificationFlag"
            :msgData="dataMsg"
            @closeBtn="closeBtn"
            @guigeMsgGet="guigeMsgGet"
        ></SpeciFication>
    </div>
</template>
  
  <script>
// import pageIndex from '@/components/pageIndex' // Secondary package based on el-pagination

import { index, addShoppingCart, getGoods } from '@/api/user/index.js'

export default {
    // components:{
    //   pageIndex
    // },
    data() {
        return {
            backgroundBg: require('@/assets/dhBg.png'),
            dataNum: 1,
            banner: JSON.parse(localStorage.getItem('webMsg')).picture.ls,
            companyZeroList: [],
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            // lunboList:JSON.parse(localStorage.getItem('lunboList'))
            lunboList: [],
            saleList: [],
            flagView: true,
            // msgData: {
            //     picture: {},
            //     choose: {},
            //     company: {},
            //     navbar: {},
            //     mainCompany: [],
            //     mainProduct: []
            // },
            dataMsg: {},
            specificationFlag: false,
            mainCompanyList: [],
            mainGoodsListOne: {},
            mainGoodsListTwo: {},
            mainGoodsListThree: {},
            mainGoodsListFour: {}
        }
    },
    created() {
        // this.wznr()
        this.listQuery.page = 1
        this.companyZeroList = []
        this.getInit()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        // 移除事件监听
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        guigeMsgGet(e, num, number) {
            console.log(e, num, number)
            // num==1//加入购物车2市立即购买
            let data = JSON.parse(e)
            if (num == 1) {
                // 加入购物车
                addShoppingCart({ goodsSpecId: data.id, num: number }).then(
                    (res) => {
                        this.specificationFlag = false

                        if (res.code == 0) {
                            this.$message({
                                message: '加入购物车成功',
                                type: 'success',
                                customClass: 'messageBoxTip'
                            })
                        }
                    }
                )
            } else {
                // 立即购买

                this.showSpecifica = false
                // 立即购买
                this.$router.push({
                    path: '/sureOrder',
                    query: { ids: data.id, num: number, type: 2 }
                })
            }
        },
        closeBtn() {
            this.specificationFlag = false
        },
        gomai(item) {
            getGoods({ id: item.id }).then((res) => {
                if (res.code == 0) {
                    res.data.pic = res.data.pic.split(',')
                    res.data.details = res.data.details.split(',')
                    if (res.data.commentList.length > 0) {
                        for (var a = 0; a < res.data.commentList.length; a++) {
                            if (res.data.commentList[a].img) {
                                res.data.commentList[a].img =
                                    res.data.commentList[a].img.split(',')
                            }
                            if (res.data.commentList[a].videos) {
                                res.data.commentList[a].videos =
                                    res.data.commentList[a].videos.split(',')
                            }
                        }
                    }
                    this.dataMsg = res.data
                    this.specificationFlag = true
                }
            })
        },
        getBackgroundStyle(imageUrl) {
            return {
                'background-image': `url(${imageUrl})`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
            }
        },
        chunkArray(arr, chunkSize) {
            let chunks = []
            for (let i = 0; i < arr.length; i++) {
                if (i < chunkSize) {
                    chunks.push(arr[i])
                }
            }
            return chunks
        },
        // wznr() {
        //     let array = []
        //     getWeb().then((res) => {
        //         array.push(res.data.picture.appUrl)
        //         array.push(res.data.picture.iosUrl)
        //         this.srcList = array
        //         // res.data.mainCompany = this.chunkArray(res.data.mainCompany, 5)
        //         // res.data.mainProduct = this.chunkArray(res.data.mainProduct, 6)
        //         this.msgData = res.data
        //         localStorage.setItem('webMsg', JSON.stringify(res.data))
        //     })
        // },
        handleScroll() {
            this.scrollPosition = window.scrollY || window.pageYOffset // 对于旧版IE使用window.pageYOffset, 对于现代浏览器使用window.scrollY
            console.log(this.scrollPosition, '页面滚动事件被触发')
            // 在这里处理你的逻辑
            if (this.scrollPosition <= 0) {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = true
            } else {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = false
            }
            console.log(this.backgroundBg, '页面滚动事件被触发')
        },
        swiperIndex(index) {
            console.log(index)

            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        toUrl(name, id) {
            if (name == '/temaiList') {
                this.$router.push({ path: name, query: { type: id } })

                // if (localStorage.getItem('token')) {
                //     this.$router.push({ path: name, query: { type: id } })
                // } else {
                //     this.$message({
                //         message: '请登录后操作',
                //         type: 'warning'
                //     })
                // }
                return
            }
            this.$router.push({ path: name, query: { id: id } })

            // if (localStorage.getItem('token')) {
            //     this.$router.push({ path: name, query: { id: id } })
            // } else {
            //     this.$message({
            //         message: '请登录后操作',
            //         type: 'warning'
            //     })
            // }
        },
        getInit() {
            index({
                type: 1,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(
                        res.data.companyZeroList.total
                    )
                    this.companyZeroList = res.data.companyZeroList.records

                    if (res.data.saleList.records.length > 0) {
                        for (
                            var c = 0;
                            c < res.data.saleList.records.length;
                            c++
                        ) {
                            res.data.saleList.records[c].pic =
                                res.data.saleList.records[c].pic.split(',')
                        }
                    }
                    res.data.mainCompanyList.records = this.chunkArray(
                        res.data.mainCompanyList.records,
                        4
                    )
                    // res.data.mainGoodsList.records = this.chunkArray(
                    //     res.data.mainGoodsList.records,
                    //     6
                    // )
                    this.mainGoodsListOne = res.data.mainGoodsList.records[0]
                    this.mainGoodsListTwo = res.data.mainGoodsList.records[1]
                    this.mainGoodsListThree = res.data.mainGoodsList.records[2]
                    this.mainGoodsListFour = res.data.mainGoodsList.records[3]

                    this.mainCompanyList = res.data.mainCompanyList.records
                    // this.mainGoodsList = res.data.mainGoodsList.records

                    this.saleList = res.data.saleList.records
                    this.lunboList = res.data.carouselList.records
                }
            })
        }
    }
}
</script>
  
  <style lang="scss" scoped>
:deep(.el-carousel__indicators) {
    position: absolute;
    // bottom: unset; /* 取消默认底部定位 */
    bottom: 0; /* 上移距离（按需调整） */
    left: 50%;
    transform: translateX(-50%);
}
/* 可选：调整单个指示点样式（如间距） */
:deep(.el-carousel__indicators--outside button) {
    background: #ffffff;
}
.titleIconBox {
    width: 11.9rem;
    height: 0.86rem;
    background: #efce63;
    border-radius: 0.1rem;
    margin: 0.23rem auto 0.18rem;
    padding: 0.12rem 0.14rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .titleIcon {
        width: 0.62rem;
        height: 0.62rem;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .titleCont {
        margin-left: 0.1rem;
        .titleName {
            color: #3a3a3a;
            font-size: 0.26rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .titleTip {
            color: #3a3a3a;
            font-size: 0.16rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
    .titleMore {
        color: #3a3a3a;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex: 1;
        height: 0.62rem;
    }
}
.lsBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 11.9rem;
    .lsLi {
        // margin-left: 0.48rem;
        margin-left: 0.1421rem;
        // width: 2.2679rem;
        width: 2.265rem;
        // width: 2.64rem;
        background: #ffffff;
        box-shadow: 2.5px 4.33px 5px 0 #4e70584d;
        border-radius: 0.1rem;
        margin-bottom: 0.24rem;
        .lsImg {
            width: 100%;
            height: 2.93rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem 0.1rem 0 0;
                object-fit: cover;
            }
        }
        .lsCont {
            padding: 0.09rem 0.11rem 0.12rem;
            box-sizing: border-box;

            .lsName {
                font-size: 0.2rem;
                color: #000000;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                border-top: 1px solid #849d6c;
                padding-top: 0.17rem;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .lsBtnBox {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                margin-top: 0.11rem;
            }
            .lsBtn {
                font-size: 0.12rem;
                width: 1rem;
                height: 0.18rem;
                background: #6f8c52;
                border-radius: 50px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 0.11rem;
                    height: 0.09rem;
                    margin-left: 0.04rem;
                }
            }
        }
    }
}
.lsBox :nth-child(5n + 1) {
    margin-left: 0;
}
.shopBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    .shopBoxLi {
        border-radius: 0.1rem;
        // margin-bottom: 0.27rem;
        margin-bottom: 0.11rem;
        margin-left: 0.21rem;
        // margin-left: 0.37rem;
        width: 2.21rem;
        // width: 2.62rem;
        background: #ffffff;
        box-shadow: 0px 5px 5px 0px rgba(78, 112, 88, 0.3);
        .shopBoxImg {
            width: 100%;
            height: 2.52rem;
            background: url('../assets/cgsBanner.png') no-repeat center;
            background-size: cover;
            border-radius: 0.1rem 0.1rem 0 0;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem 0.1rem 0 0;
            }
        }
        .shopBoxCont {
            padding: 0.24rem 0.17rem 0.1rem;
            box-sizing: border-box;
            background: #ffffff;
            z-index: 9999;
            margin-top: -0.3rem;
            background: url('../assets/contBg.png') no-repeat center;
            background-size: 100% 100%;
            .shopBoxTit {
                font-size: 0.2rem;
                color: #000000;
                text-align: center;
                font-family: 'SourceHanSansCN';
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 0.045rem;
            }
            .shopBoxBottom {
                display: flex;
                align-items: center;
                justify-content: center;

                .shopPrice {
                    font-size: 0.2rem;
                    color: #6f8c52;
                    font-weight: 500;

                    font-family: 'SourceHanSansCN';

                    .shopNum {
                        font-size: 0.28rem;
                        font-weight: 500;
                        font-family: 'SourceHanSansCN';
                    }
                    .shopTag {
                        font-size: 0.18rem;
                    }
                }
                img {
                    width: 0.29rem;
                    height: 0.29rem;
                }
            }
            .shopBoxBottomBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.76rem;
                height: 0.24rem;
                border-radius: 0.6rem;
                background: linear-gradient(0deg, #cccccc 0%, #f2f1f3 100%);
                margin: 0.12rem auto 0;
                .shopBoxBottomBtnLi {
                    color: #ffffff;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    width: 0.92rem;
                    height: 0.24rem;
                    border-radius: 0.6rem;
                    background: linear-gradient(0deg, #708d53 0%, #97af7d 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .shopBoxBottomBtnLiA {
                    color: #708d53;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
.shopBox :nth-child(5n + 1) {
    margin-left: 0;
}
// .marketBox {
//     padding: 0.54rem 0 0 0;

//     .marketTit {
//         color: #000000;
//         font-size: 0.45rem;
//         font-weight: 700;
//         font-family: 'SourceHanSansCN';
//         text-align: center;
//     }

//     .marketFu {
//         color: #666666;
//         font-size: 0.16rem;
//         font-weight: 400;
//         font-family: 'SourceHanSansCN';
//         text-align: center;
//     }

//     .jrtmBox {
//         width: 15.5531rem;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         flex-wrap: wrap;
//         margin: 0.29rem auto 0;
//         .jrtmLi {
//             width: 3.67rem;
//             margin-bottom: 0.54rem;
//             img {
//                 width: 3.67rem;
//                 height: 3.3rem;
//                 border-radius: 0.2921rem 0.2921rem 0 0;
//                 display: block;
//                 object-fit: cover;
//             }
//             .jrtmName {
//                 color: #121212;
//                 font-size: 0.26rem;
//                 font-weight: 400;
//                 font-family: 'SourceHanSansCN';
//                 text-align: center;
//                 padding: 0 0.32rem;
//                 box-sizing: border-box;
//                 background: #ffffff;
//                 display: -webkit-box;
//                 -webkit-box-orient: vertical;
//                 -webkit-line-clamp: 2;
//                 text-overflow: ellipsis;
//                 overflow: hidden;
//                 height: 0.8rem;
//                 margin-top: 0.3rem;
//             }
//         }
//         .jrtmLi:last-child:nth-child(4n-1) {
//             // margin-right: calc(3.67rem+0.02rem/3);
//             margin-right: calc(24% + 4% / 3);
//         }
//         .jrtmLi:last-child:nth-child(4n-2) {
//             // margin-right: calc(7.34rem+0.04rem/3);
//             margin-right: calc(48% + 8% / 3);
//         }
//     }
// }

.listBox {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.32rem 1.81rem 0.01rem;
    box-sizing: border-box;
    .listLi {
        width: 7.48rem;
        // height: 2.68rem;
        height: 3.68rem;

        border-radius: 0.29rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
        padding: 0.4rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .listImg {
            width: 3rem;
            // height: 1.86rem;
            height: 2.86rem;

            border-radius: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.16rem;
                object-fit: cover;
            }
        }
        .listCont {
            margin-left: 0.37rem;
            flex: 1;
            .listName {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                margin-bottom: 0.36rem;
                display: -webkit-box; /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /*显示行数*/
                overflow: hidden; /*隐藏多出部分文字*/
                text-overflow: ellipsis; /*用省略号代替多出部分文字*/
                height: 1rem;
            }
            .listtip {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .listtipName {
                    color: #54545480;
                    font-size: 0.18rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
            .listtipBtn {
                width: 1.5rem;
                height: 0.5rem;
                border-radius: 1rem;
                opacity: 1;
                background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
                box-shadow: 0 0 0.16rem 0 #00b34a66;
                color: #ffffff;
                font-size: 0.13rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                line-height: 0.5rem;
                margin-top: 0.5rem;
            }
        }
    }
}
.bgHeader {
    width: 100%;
    height: 14rem;
    background: url('../assets/bgChilder.png') no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.header {
    width: 100%;
    // position: absolute;
    // top: 0.88rem;
    // left: 0;
    padding-top: 0.88rem;
    .banner {
        // width: 12rem;
        width: 11.9rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .bannerLeft {
            width: 1.68rem;
            height: 3.7rem;
            // height: 4.92rem;
            .bannerLeftTit {
                width: 100%;
                height: 0.47rem;
                font-size: 0.25rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                color: #ffffff;
                // background: rgba($color: #000000, $alpha: 0.1);
                background: #6f8c52;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.1rem 0.1rem 0 0;
            }
            .bannerLeftTitLiBox {
                // background: rgba(220, 230, 202, 0.9);
                background: #ffffff;
                border-radius: 0 0 0.1rem 0.1rem;
                padding: 0 0.2rem;
                box-sizing: border-box;
                height: 4.45rem;

                .bannerLeftTitLi {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.1rem 0;
                    border-bottom: 1px solid #7f9964;
                    img {
                        // width: 0.64rem;
                        // height: 0.64rem;
                        // width: 0.55rem;
                        // height: 0.55rem;
                        // width: 0.54rem;
                        // height: 0.54rem;
                        // width: 0.44rem;
                        // height: 0.44rem;
                        width: 0.58rem;
                        height: 0.58rem;
                    }
                    .bannerLeftTitLiName {
                        // font-size: 0.16rem;
                        font-size: 0.14rem;
                        color: #2b2b2b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        margin-left: 0.09rem;
                    }
                }
                .bannerLeftTitLi:last-child {
                    border: none;
                }
                .bannerRightTitLi {
                    color: #000000;
                    font-size: 0.18rem;
                    padding: 0.16rem 0;
                    border-bottom: 1px solid #7f9964;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 300;
                    font-family: 'SourceHanSansCN';
                }
                .bannerRightTitLi:last-child {
                    border: none;
                }
            }
        }
        .shopTui {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            flex-direction: column;
            .shopTuiTop {
                width: 6.77rem;
                height: 2.44rem;
                background: #efce63;
                border-radius: 0.1rem;
                display: flex;
                align-items: center;
                padding: 0.15rem 0;
                box-sizing: border-box;
                .shopTuiTopLi {
                    width: 50%;
                    height: 100%;
                    padding: 0 0.15rem;
                    box-sizing: border-box;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .shopTuiLine {
                    width: 1px;
                    height: 100%;
                    border: 1px dashed #999999;
                }
            }
            .shopTuiBottom {
                display: flex;

                align-items: center;
                justify-content: space-between;
                .shopTuiBottomLi {
                    width: 3.35rem;
                    height: 2.43rem;
                    border-radius: 0.1rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 0.1rem;
                    }
                }
            }
        }
    }
}
.companyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.32rem 1.81rem 0.01rem;
    box-sizing: border-box;
    .companyLi {
        width: 7.4rem;
        height: 4.92rem;
        border-radius: 0.31rem;
        opacity: 1;
        border: 0.01rem solid #eaeaea;
        background: #ffffff;
        //   padding: 0.31rem;
        //   box-sizing: border-box;
        margin-bottom: 1.02rem;
        .companyTop {
            display: flex;
            align-items: center;
            background: linear-gradient(180deg, #fffad9 0%, #ffbe0000 100%);
            border-radius: 0.31rem 0.31rem 0 0;

            padding: 0.27rem 0.31rem;
            box-sizing: border-box;
            .companyTag {
                width: 0.39rem;
                height: 0.41rem;
                border-radius: 0.1rem;
                opacity: 1;
                //   border: 0.01rem solid #ffbe00;
                background: #ffbe00;
                color: #ffffff;
                font-size: 0.14rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: left;
                line-height: 0.16rem;
                text-align: center;
                display: flex;
                align-items: center;
            }
            .companyName {
                color: #333333;
                font-size: 0.3rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
                margin-left: 0.14rem;
            }
        }
        .companyMsg {
            display: flex;
            align-items: center;
            margin-top: 0.19rem;
            padding: 0 0.31rem;
            box-sizing: border-box;
            .companyMsgLi {
                color: #555555;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
        }
        .companyCont {
            padding: 0 0.31rem;
            box-sizing: border-box;
            color: #33333380;
            font-size: 0.28rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: -webkit-box; /* 显示多行文本容器 */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*显示行数*/
            overflow: hidden; /*隐藏多出部分文字*/
            text-overflow: ellipsis; /*用省略号代替多出部分文字*/
            margin-top: 0.41rem;
        }
        .companyZy {
            color: #333333cc;
            font-size: 0.24rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.24rem;
            padding: 0 0.31rem;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .companyBtn {
            margin-left: 0.31rem;
            box-sizing: border-box;
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
            margin-top: 0.79rem;
        }
    }
    .companyLi:nth-child(4n + 1) {
        .companyTop {
            background: linear-gradient(180deg, #fffad9 0%, #ffbe0000 100%);
            .companyTag {
                background: #ffbe00;
                color: #ffffff;
            }
        }
    }
    .companyLi:nth-child(4n + 2) {
        .companyTop {
            background: linear-gradient(180deg, #e2e8ff 0%, #ffbe0000 100%);
            .companyTag {
                background: #5e7efe;
            }
        }
    }
    .companyLi:nth-child(4n + 3) {
        .companyTop {
            background: linear-gradient(180deg, #ffe6d3 0%, #ffbe0000 100%);
            .companyTag {
                background: #ff8025;
            }
        }
    }
    .companyLi:nth-child(4n + 4) {
        .companyTop {
            background: linear-gradient(180deg, #ddfbfb 0%, #ffbe0000 100%);
            .companyTag {
                background: #5bebe9;
            }
        }
    }
}
</style>