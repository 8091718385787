<template>
    <div class="content" @scroll="handleScroll">
        <div class="bgColor"></div>
        <HeaderTopNew
            :parentData="dataNum"
            :backgroundImage="backgroundBg"
            :flagView="flagView"
        ></HeaderTopNew>
        <div v-for="(item, index) in lunboList" :key="index">
            <div class="headerImg" v-if="index <= 0" @click="swiperIndex(0)">
                <img :src="item.pic" alt="" />
            </div>
        </div>

        <!-- <div class="header" :style="{background:'url('+banner+')',backgroundSize:cover}">
      <HeaderTop :parentData="dataNum"></HeaderTop>
      <div class="headerContText">
        <div class="headerContTextTit">
          新闻中心
        </div>
        <div class="headerContTextTip">
          与未来同行，让我们的生活更美好
        </div>
      </div>
    </div> -->
        <!-- <el-carousel
            :interval="5000"
            arrow="always"
            height="9.17rem"
            indicator-position="none"
            style="position: relative"
        >
            <el-carousel-item v-for="(item, index) in lunboList" :key="index">
                <img
                    :src="item.pic"
                    alt=""
                    style="width: 100%; height: 100%; object-fit: cover"
                    @click="swiperIndex(index)"
                />
            </el-carousel-item>
        </el-carousel> -->

        <div class="oneMsg">
            <div class="oneMsgLeft">
                <div class="oneMsgTit">
                    <div class="oneMsgDiv"></div>
                    <div class="oneMsgCont">
                        <div class="oneMsgTime">
                            {{ newsListOne.pubDate }}
                        </div>
                        <div class="oneMsgLine"></div>
                        <div class="oneMsgTitName">
                            {{ newsListOne.title }}
                        </div>
                    </div>
                </div>
                <!-- <div class="oneMsgNr" v-html="newsListOne.content"></div> -->
                <div class="oneMsgNr">{{ newsListOne.intro }}</div>

                <!-- <div class="oneMsgNr">{{ newsListOne.content }}</div> -->
                <div
                    class="oneMsgMore"
                    @click="toUrl('/newXq', newsListOne.id)"
                >
                    <div class="oneMsgMoreText">了解更多信息 ></div>
                </div>
            </div>
            <div class="oneMsgRight">
                <img :src="newsListOne.pic" alt="" />
            </div>
            <div class="ciyu">
                <div
                    class="ciyuLi"
                    v-for="(item, index) in newsListOne.labelList"
                    :key="index"
                >
                    {{ item }}
                </div>
            </div>
        </div>

        <div class="newMsg">
            <div class="newMsgBox">
                <div class="oneMsgTit">
                    <div class="oneMsgDiv"></div>
                    <div class="oneMsgCont">
                        <div class="oneMsgTime">Grain information</div>
                        <div class="oneMsgLine"></div>
                        <div class="oneMsgTitName">最新粮食信息</div>
                    </div>
                </div>
                <div class="kuai">
                    <div
                        class="kuaiLi"
                        v-for="(item, index) in newsListTwo"
                        :key="index"
                        @click="toUrl('/newXq', item.id)"
                    >
                        <div class="kuaiLiTop">
                            <img
                                src="../assets/oneIconNew.png"
                                alt=""
                                v-if="index == 0"
                            />
                            <img
                                src="../assets/twoIconNew.png"
                                alt=""
                                v-if="index == 1"
                                style="width: 0.71rem"
                            />
                            <img
                                src="../assets/threeIconNew.png"
                                alt=""
                                v-if="index == 2"
                                style="width: 0.71rem"
                            />
                            <img
                                src="../assets/fourIconNew.png"
                                alt=""
                                v-if="index == 3"
                                style="width: 0.68rem"
                            />
                            <div class="kuaiLiNum">0{{ index + 1 }}</div>
                        </div>
                        <div class="kuaiLiName">
                            {{ item.title }}
                        </div>
                        <div class="kuaiLiRight">></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="endBox">
            <div class="oneMsgTit">
                <div class="oneMsgDiv"></div>
                <div class="oneMsgCont">
                    <div class="oneMsgTime">Grain information</div>
                    <div class="oneMsgLine"></div>
                    <div class="oneMsgTitName">最新粮食信息</div>
                </div>
            </div>
            <div class="endBoxBig">
                <div class="endBoxBigLeft">
                    <div v-for="(item, index) in newsList" :key="index">
                        <div
                            class="endBoxBigLi"
                            v-if="index < 3"
                            @click="toUrl('/newXq', item.id)"
                        >
                            <div class="endBoxBigLiNum">0{{ index + 5 }}.</div>
                            <div class="endBoxBigLiCont">
                                <div class="endBoxBigLiContTop">
                                    <div class="endBoxBigLiContName">
                                        {{ item.title }}
                                    </div>
                                    <div class="endBoxBigLiContRight">></div>
                                </div>
                                <!-- <div
                                    class="endBoxBigLiContTopTetx"
                                    v-html="item.content"
                                ></div> -->
                                <div class="endBoxBigLiContTopTetx">
                                    {{ item.intro }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="endBoxImg" @click="toUrl('/newXq', newsListTu.id)">
                    <div class="endBoxImgSmoll">
                        <div class="endBoxImgSmollName">
                            {{ newsListTu.title }}
                        </div>
                        <div class="endBoxImgSmollTime">
                            {{ newsListTu.pubDate }}
                        </div>
                    </div>
                    <div class="endBoxImgSmollImg">
                        <img :src="newsListTu.pic" alt="" />
                    </div>
                    <div class="endBoxImgSmollImgTag">
                        {{ newsListTu.title }}
                    </div>
                </div>
                <div class="endBoxBigLeft">
                    <div v-for="(item, index) in newsList" :key="index">
                        <div
                            class="endBoxBigLi"
                            v-if="index >= 3"
                            @click="toUrl('/newXq', item.id)"
                        >
                            <div class="endBoxBigLiNum">0{{ index + 5 }}.</div>
                            <div class="endBoxBigLiCont">
                                <div class="endBoxBigLiContTop">
                                    <div class="endBoxBigLiContName">
                                        {{ item.title }}
                                    </div>
                                    <div class="endBoxBigLiContRight">></div>
                                </div>
                                <!-- <div
                                    class="endBoxBigLiContTopTetx"
                                    v-html="item.content"
                                ></div> -->
                                <div class="endBoxBigLiContTopTetx">
                                    {{ item.intro }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                box-sizing: border-box;
                width: 11.9rem;
                margin: 0.2rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
        <!-- <FooterBottom></FooterBottom> -->
    </div>
</template>

<script>
import { index } from '@/api/user/index.js'
export default {
    data() {
        return {
            flagView: true,
            dataNum: 5,
            backgroundBg: require('@/assets/dhBg.png'),
            // banner:JSON.parse(localStorage.getItem('webMsg')).picture.lx,
            newsList: [],

            newsListTu: {},
            newsListOne: {},
            newsListTwo: [],

            listQuery: {
                page: 1,
                limit: 11,
                total: ''
            },
            lunboList: [],

            saleList: []
        }
    },
    created() {
        this.listQuery.page = 1
        this.companyOneList = []
        this.getInit()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        // 移除事件监听
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            this.scrollPosition = window.scrollY || window.pageYOffset // 对于旧版IE使用window.pageYOffset, 对于现代浏览器使用window.scrollY
            console.log(this.scrollPosition, '页面滚动事件被触发')
            // 在这里处理你的逻辑
            if (this.scrollPosition <= 0) {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = true
            } else {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = false
            }
            console.log(this.backgroundBg, '页面滚动事件被触发')
        },
        swiperIndex(index) {
            console.log(index)
            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },

        toUrl(urlSrc, id) {
            if (urlSrc == '/temaiList') {
                if (localStorage.getItem('token')) {
                    this.$router.push({ path: urlSrc, query: { type: id } })
                } else {
                    this.$message({
                        message: '请登录后操作',
                        type: 'warning'
                    })
                }
                return
            }
            this.$router.push({ path: urlSrc, query: { id: id } })
        },
        pageSizeChangeHandle(val) {
            console.log(val, 'pageSizeChangeHandle')
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            console.log(val, 'pageCurrentChangeHandle')

            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            index({
                type: 5,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.newsList.total)
                    let newB = []
                    let newC = []
                    let newA = res.data.newsList.records[0]
                    for (var a = 0; a < res.data.newsList.records.length; a++) {
                        if (a > 0 && a <= 4) {
                            newB.push(res.data.newsList.records[a])
                        }
                        if (a > 4) {
                            newC.push(res.data.newsList.records[a])
                        }
                    }

                    // if (res.data.saleList.records.length > 0) {
                    //     for (
                    //         var c = 0;
                    //         c < res.data.saleList.records.length;
                    //         c++
                    //     ) {
                    //         res.data.saleList.records[c].pic =
                    //             res.data.saleList.records[c].pic.split(',')
                    //     }
                    // }
                    // this.saleList = res.data.saleList.records
                    this.newsListOne = newA
                    this.newsListTwo = newB
                    this.newsList = newC
                    this.newsListTu = this.newsList[0]
                    this.lunboList = res.data.carouselList.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.headerImg {
    margin-top: 0.8rem;
    width: 100%;
    height: 4.9rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.oneMsg {
    width: 11.9rem;
    margin: 0.32rem auto 0;
    display: flex;
    align-items: center;

    position: relative;
    justify-content: space-between;
    .oneMsgLeft {
        .oneMsgTit {
            display: flex;
            align-items: center;
            .oneMsgDiv {
                width: 0.49rem;
                height: 0.66rem;
                background: #6f8c52;
            }

            .oneMsgCont {
                margin-left: 0.15rem;
                .oneMsgTime {
                    color: #6f8c52;
                    font-size: 0.16rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                }
                .oneMsgLine {
                    width: 0.52rem;
                    height: 0.03rem;
                    background: #6f8c52;

                    margin: 0.07rem 0 0.04rem;
                }
                .oneMsgTitName {
                    color: #6f8c52;
                    font-size: 0.26rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
        }

        .oneMsgNr {
            margin-top: 0.42rem;
            margin-left: 0.64rem;
            color: #383838;
            font-size: 0.16rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            width: 7.25rem;
            // height: 1.87rem;
        }
        .oneMsgMore {
            width: 1.42rem;
            height: 0.41rem;
            background: #cecece;
            position: relative;
            margin-top: 0.28rem;
            margin-left: 0.54rem;

            .oneMsgMoreText {
                color: #383838;
                font-size: 0.2rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                width: 1.59rem;
                height: 0.41rem;
                background: #efce63;
                position: absolute;
                bottom: 0.06rem;
                left: 0.11rem;
                display: flex;

                align-items: center;

                justify-content: center;
            }
        }
    }
    .oneMsgRight {
        width: 2.68rem;
        height: 3.91rem;
        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
    .ciyu {
        height: 0.41rem;
        background: #6f8c52;
        position: absolute;
        bottom: 0.41rem;
        left: 4.69rem;
        display: flex;
        align-items: center;
        .ciyuLi {
            color: #ffffff;
            font-size: 0.16rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 1.41rem;
            text-align: center;
            border-right: 1px solid #ffffff;
        }
        .ciyuLi:last-child {
            border: none;
        }
    }
}
.newMsg {
    width: 100%;
    height: 4.9rem;
    background: url('../assets/newBgNew.png') no-repeat center;
    background-size: 100% 100%;
    margin-top: 0.43rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .newMsgBox {
        width: 11.9rem;
        margin: 0 auto;

        .oneMsgTit {
            display: flex;
            align-items: center;
            .oneMsgDiv {
                width: 0.49rem;
                height: 0.66rem;
                background: #efce63;
            }

            .oneMsgCont {
                margin-left: 0.15rem;
                .oneMsgTime {
                    color: #ffffff;
                    font-size: 0.16rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                }
                .oneMsgLine {
                    width: 0.52rem;
                    height: 0.03rem;
                    background: #efce63;

                    margin: 0.07rem 0 0.04rem;
                }
                .oneMsgTitName {
                    color: #ffffff;
                    font-size: 0.26rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
            }
        }
        .kuai {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            padding-left: 0.61rem;
            margin-top: 0.3rem;
            .kuaiLi {
                width: 2.21rem;
                height: 2.72rem;
                border-radius: 0 0.8rem 0 0;
                background: #6f8c53;
                padding: 0.3rem 0.25rem;
                box-sizing: border-box;
                margin-right: 0.57rem;
                .kuaiLiTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                        width: 0.7rem;
                        height: 0.64rem;
                        display: block;
                    }
                    .kuaiLiNum {
                        height: 0.64rem;

                        color: #ffffff;
                        font-size: 26px;
                        font-weight: 400;
                        font-family: 'yshst';
                        display: flex;

                        align-items: flex-end;

                        justify-content: flex-end;
                    }
                }
                .kuaiLiName {
                    color: #ffffff;
                    font-size: 0.18rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.38rem;

                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    height: 0.8rem;
                }
                .kuaiLiRight {
                    color: #ffffff;
                    font-size: 0.18rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    text-align: right;
                    flex: 1;
                    margin-top: 0.2rem;
                }
            }
        }
    }
}

.endBox {
    width: 11.9rem;
    margin: 0.33rem auto 0;
    .oneMsgTit {
        display: flex;
        align-items: center;
        .oneMsgDiv {
            width: 0.49rem;
            height: 0.66rem;
            background: #6f8c52;
        }

        .oneMsgCont {
            margin-left: 0.15rem;
            .oneMsgTime {
                color: #6f8c52;
                font-size: 0.16rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .oneMsgLine {
                width: 0.52rem;
                height: 0.03rem;
                background: #6f8c52;

                margin: 0.07rem 0 0.04rem;
            }
            .oneMsgTitName {
                color: #6f8c52;
                font-size: 0.26rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }
        }
    }
    .endBoxBig {
        margin-top: 0.39rem;
        display: flex;
        justify-content: space-between;
        .endBoxBigLeft {
            .endBoxBigLi {
                display: flex;
                // width: 4.82rem;
                width: 4.22rem;
                padding: 0.17rem 0;
                border-top: 1px solid #6f8c52;
                .endBoxBigLiNum {
                    color: #6f8c52;
                    font-size: 40px;
                    font-weight: 400;
                    font-family: 'yshst';
                    margin-top: 0.1rem;
                }
                .endBoxBigLiCont {
                    margin-left: 0.06rem;

                    flex: 1;
                    .endBoxBigLiContTop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .endBoxBigLiContName {
                            width: 3.4rem;

                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #383838;
                            font-size: 0.2rem;
                            font-weight: 400;
                            font-family: 'SourceHanSansCN';
                        }
                        .endBoxBigLiContRight {
                            color: #6f8c53;
                            font-size: 0.2rem;
                            font-weight: 400;
                            font-family: 'SourceHanSansCN';
                        }
                    }
                    .endBoxBigLiContTopTetx {
                        color: #383838;
                        font-size: 0.16rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                        margin-top: 0.11rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        width: 3.4rem;
                    }
                }
            }
        }
        .endBoxImg {
            width: 2.7rem;
            height: 3.54rem;
            position: relative;

            .endBoxImgSmoll {
                width: 2.3rem;
                height: 100%;
                background: #6f8c52;
                padding: 0.22rem 0.33rem;
                box-sizing: border-box;

                .endBoxImgSmollName {
                    color: #ffffff;
                    font-size: 0.2rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';

                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .endBoxImgSmollTime {
                    color: #ffffff;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.02rem;
                }
            }
            .endBoxImgSmollImg {
                position: absolute;
                bottom: 0;
                right: 0;
                img {
                    width: 2.42rem;
                    height: 2.65rem;
                    display: block;
                    object-fit: cover;
                }
            }
            .endBoxImgSmollImgTag {
                color: #383838;
                font-size: 0.18rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                min-width: 1.49rem;
                height: 0.35rem;
                background: #efce63;
                position: absolute;
                bottom: 0.3rem;
                left: 0.1rem;
                padding: 0.04rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 2.6rem;
            }
        }
    }
}
.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;
        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;
            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;
                object-fit: cover;
            }
            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 0.8rem;
                margin-top: 0.3rem;
            }
        }
        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }
        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 8.02rem;
    background: url('@/assets/xwBg.png') no-repeat center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    padding-left: 3.72rem;
    box-sizing: border-box;

    .headerContText {
        .headerContTextTit {
            color: #ffffff;
            font-size: 0.72rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .headerContTextTip {
            color: #ffffff;
            font-size: 0.2rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
}
.tabNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3rem;
    margin-bottom: 0.6rem;
    // .tablli{
    //   margin-right: 0.38rem;
    // }
    // .tablli:nth-child(3){
    //   margin-right: 0;
    // }
    .tabNavLi {
        width: 4.92rem;
        // height: 7.54rem;
        height: 6rem;

        opacity: 1;
        background: #ffffff;
        box-shadow: 0.02rem 0.02rem 0.04rem 0 #00000000;
        padding: 0.43rem 0.3rem;
        box-sizing: border-box;
        margin-right: 0.38rem;
        .tabNavTime {
            color: #545454;
            font-size: 0.16rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
        .tabNavName {
            color: #000000;
            font-size: 0.24rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
            height: 0.68rem;
            display: -webkit-box; /* 显示多行文本容器 */
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*显示行数*/
            overflow: hidden; /*隐藏多出部分文字*/
            text-overflow: ellipsis; /*用省略号代替多出部分文字*/
        }
        .tabNavImg {
            width: 4.35rem;
            height: 3.19rem;
            margin-top: 0.16rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tabNavSort {
            color: #545454;
            font-size: 0.16rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            margin-top: 0.25rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 定义文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .tabNavBtn {
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            margin-top: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            span {
                font-size: 0.13rem;
            }
        }
    }
    .tabNavLi:nth-child(3) {
        margin-right: 0;
    }
    .tabNavLi:hover {
        background: #00b34a;
        box-shadow: 0.02rem 0.02rem 0.04rem 0 #00000000;
        .tabNavTime {
            color: #ffffff;
        }
        .tabNavName {
            color: #ffffff;
        }
        .tabNavSort {
            color: #ffffff;
        }
        .tabNavBtn {
            background: #ffffff;
            color: #00943f;
        }
    }
}
.listBox {
    .listLi {
        width: 15.53rem;
        height: 2.68rem;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0 0.06rem 0.06rem 0 #0000000f;
        margin: 0 auto 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.47rem;
        box-sizing: border-box;
        .listLeft {
            display: flex;
            align-items: center;
            .listTime {
                color: #00943f;
                font-size: 0.18rem;
                font-weight: 500;
                font-family: 'SourceHanSansCN';
            }
            .listImg {
                width: 3rem;
                height: 2rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .listCont {
                margin-left: 0.44rem;
                width: 8.24rem;
                .listName {
                    color: #000000;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                }
                .listTip {
                    color: #54545480;
                    font-size: 0.16rem;
                    font-weight: 500;
                    font-family: 'SourceHanSansCN';
                    margin-top: 0.28rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 定义文本的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .listRight {
            width: 1.5rem;
            height: 0.5rem;
            border-radius: 1rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
            box-shadow: 0 0 0.16rem 0 #00b34a66;
            margin-top: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            span {
                font-size: 0.13rem;
            }
        }
    }
}
</style>