<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="chooseBig">
            <div class="chooseTab">
                <div
                    class="chooseTabLi"
                    :class="tabNum == 99 ? 'chooseTabLiA' : ''"
                    @click="shoose(6)"
                >
                    全部
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 0 ? 'chooseTabLiA' : ''"
                    @click="shoose(0)"
                >
                    待审核
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 1 ? 'chooseTabLiA' : ''"
                    @click="shoose(1)"
                >
                    已发布
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 2 ? 'chooseTabLiA' : ''"
                    @click="shoose(2)"
                >
                    已驳回
                </div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 3 ? 'chooseTabLiA' : ''"
                    @click="shoose(3)"
                >
                    审核中
                </div>
            </div>
        </div>
        <div class="box">
            <div class="shopLi" v-for="(item, index) in dataList" :key="index">
                <div class="shopTop" @click="qyGo(item)">
                    <div class="shopTopLeft">
                        <div class="shopShop">
                            订单编号：{{ item.orderCode }}
                        </div>
                    </div>
                    <div class="shopTopStatus">
                        {{
                            (item.isPublish == 0 && '待审核') ||
                            (item.isPublish == 1 && '审核通过') ||
                            (item.isPublish == 2 && '已驳回') ||
                            (item.isPublish == 3 && '审核中')
                        }}
                    </div>
                </div>
                <div class="shopBottom">
                    <div class="shopBottomBh" v-if="item.isPublish == 2">
                        驳回原因：{{ item.comment }}
                    </div>
                    <div class="shopBottomName">
                        <div class="shopBottomLeft">
                            {{ item.productName }}
                        </div>
                        <div class="shopBottomPrice" v-if="item.priceType == 0">
                            ￥<span>{{ item.price }}/斤</span>
                        </div>
                        <div class="shopBottomPrice" v-if="item.priceType == 1">
                            以实时价为准
                        </div>
                    </div>
                    <div class="orderList">数量：{{ item.num }}吨</div>

                    <div class="orderList">产地：{{ item.producer }}</div>
                    <div class="orderList">
                        发布时间：{{ item.releaseTime }}
                    </div>
                </div>
            </div>
        </div>

        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 14.29rem;
                margin: 0.5rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
        </div>
        <CancelOrder v-if="show" @suBmit="suBmit" @close="close"></CancelOrder>
    </div>
</template>

<script>
import {
    publishList,
    cancelOrder,
    orderContract,
    orderCancel,
    confirmReceipt,
    applyInvoice,
    delOrder
} from '@/api/user/index.js'
export default {
    data() {
        return {
            dialogVisible: false,
            imageSrc: '',
            dialogFormVisible: false,
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            tabNum: 99,
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: [],
            userInfo: JSON.parse(localStorage.getItem('userInfo')),
            tabIndex: 1,
            btnFlag: false,
            show: false,
            order_id: '',
            invoiceMsg: {},
            invoiceId: '',

            incoiceList: []
        }
    },
    created() {
        if (localStorage.getItem('invoiceChoose')) {
            this.invoiceMsg = JSON.parse(localStorage.getItem('invoiceChoose'))
            this.invoiceId = this.invoiceMsg.id
            this.order_id = this.invoiceMsg.orderId
            this.tabNum = this.invoiceMsg.tabNum
            localStorage.removeItem('invoiceChoose')
            this.dialogFormVisible = true
        }
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        handleCountdownEnded() {
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        convertToTimestamp(timeString) {
            let currentTime = new Date().getTime()
            // 预处理字符串，将"-"替换为"/"（如果需要）
            let formattedTimeString = timeString.replace(/-/g, '/')

            // 创建Date对象（注意：这里假设时间字符串的格式是"YYYY/MM/DD hh:mm:ss"或"YYYY/MM/DD"）
            let date = new Date(formattedTimeString)

            // 检查Date对象是否有效
            if (isNaN(date.getTime())) {
                // alert('无效的时间字符串')
                return
            }

            console.log(
                Math.round(date.getTime()) / 1000 +
                    30 * 60 -
                    currentTime / 1000,
                '时间'
            )
            return Math.round(
                Math.round(date.getTime()) / 1000 + 30 * 60 - currentTime / 1000
            )
            // 获取时间戳
            // this.timestamp = Math.round(date.getTime() / 1000); // 转换为秒级时间戳（可选）
        },
        // 跳转企业详情
        qyGo(obj) {
            localStorage.setItem('myXq', JSON.stringify(obj))
            this.$router.push('/myIssueXq')
        },
        // 跳转商品详情
        spGo(id) {
            this.$router.push({
                path: '/shopXq',
                query: { id: id }
            })
        },
        // 查看发票
        checkInvoice(url) {
            this.imageSrc = url
            this.dialogVisible = true
        },

        // 选择发票信息
        goChooseInvoice() {
            this.$router.push({
                path: '/invoiceAdmin',
                query: { type: 2, orderId: this.order_id, tabNum: this.tabNum }
            })
        },
        // 申请开票
        billing(id) {
            this.order_id = id
            this.dialogFormVisible = true
        },
        sureKp() {
            if (!this.invoiceId) {
                this.$message({
                    message: '请选择发票信息',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            applyInvoice({
                id: this.order_id,
                invoiceId: this.invoiceId
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '申请开票提交成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.dialogFormVisible = false

                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
            // dialogFormVisible = false
        },
        // 确认收货
        receipt(id) {
            confirmReceipt({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '确定收货成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        // 跳转订单详情
        btnOperation(item) {
            if (item.status == 0) {
                this.$router.push({
                    path: '/onePayOrder',
                    query: { orderId: item.id }
                })
            } else {
                this.$router.push({
                    path: '/merchandiseOrderXq',
                    query: { orderId: item.id }
                })
            }
        },
        // 去支付
        payMent(id) {
            this.$router.push({
                path: '/onePayOrder',
                query: { orderId: id }
            })
        },
        // 取消订单
        cancelOrder(id) {
            this.order_id = id
            this.show = true
        },
        // 取消订单
        suBmit(num, flag) {
            orderCancel({
                id: this.order_id,
                type: num,
                flag: flag
            }).then((res) => {
                this.show = false
                if (res.code == 0) {
                    this.$message({
                        message: '取消成功',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        close(e) {
            this.show = e
        },
        chooseTop(num) {
            if (this.btnFlag) {
                return
            }
            this.btnFlag = true
            this.tabIndex = num
            this.page = 1
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        htDown(id) {
            orderContract({ orderCode: id }).then((res) => {
                console.log(res)
                if (res.data.contract) {
                    window.location.href = res.data.contract
                } else {
                    this.$message({
                        message: '暂无合同',
                        type: 'warning'
                    })
                }
            })
        },
        toUrl(urlSrc) {
            this.$router.push({ path: urlSrc })
        },
        deleteOrder(id) {
            delOrder({ id: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '删除订单成功！',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        cancelOrderBtn(id) {
            cancelOrder({ orderId: id }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '取消订单成功！',
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                    this.listQuery.page = 1
                    this.dataList = []
                    this.getInit()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        getInit() {
            publishList({
                isPublish: this.tabNum,
                page: this.listQuery.page,
                size: this.listQuery.limit
            }).then((res) => {
                this.btnFlag = false
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    for (var a = 0; a < res.data.records.length; a++) {
                        res.data.records[a].flag = false
                        if (res.data.records[a].status == 0) {
                            res.data.records[a].djsTime =
                                this.convertToTimestamp(
                                    res.data.records[a].createDate
                                )
                        }
                    }
                    this.dataList = res.data.records
                }
            })
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        }
    }
}
</script>

<style lang="scss" scoped>
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.header {
    width: 100%;
    height: 5rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.chooseBig {
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
    margin-top: 1rem;
}

.chooseTab {
    width: 14.29rem;

    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto 0;
    box-sizing: border-box;

    .chooseTabLi {
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
    }

    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        position: relative;
    }

    .chooseTabLiA::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        // right: 10%;
        background: #239f59;
        width: 100%;
        height: 0.09rem;
        border-radius: 0.08rem;
    }
}

.box {
    width: 14.29rem;
    margin: 0 auto;
    .shopLi {
        border-radius: 0.32rem;
        opacity: 1;
        border: 0.04rem solid #ffffff;
        background: #ffffff;
        box-shadow: 0 0.2rem 0.4rem 0 #2fa5631a;
        padding: 0.3rem 0.5rem;
        box-sizing: border-box;
        margin-top: 0.32rem;
        .shopTop {
            display: flex;
            align-items: center;

            justify-content: space-between;
            .shopTopLeft {
                display: flex;
                align-items: center;
            }
            .shopTopStatus {
                color: #1b1b1b80;
                font-size: 0.28rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .shopCheck {
                width: 0.37rem;
                height: 0.37rem;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .shopShop {
                color: #343231;
                font-size: 0.32rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
            .shopLeft {
                width: 0.327rem;
                height: 0.327rem;
            }
            .shopRight {
                width: 0.22rem;
                height: 0.22rem;
            }
        }

        .shopBottom {
            color: #000000;
            font-weight: 400;
            text-align: left;
            font-size: 0.24rem;
            font-family: 'SourceHanSansCN';
            margin-top: 0.2rem;
            .shopBottomBh {
                color: red;
                font-weight: 700;
                font-size: 0.3rem;
                font-family: 'SourceHanSansCN';
            }
            .shopBottomName {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 0.1rem;
                .shopBottomLeft {
                    color: #000000;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    text-align: left;
                    font-size: 0.3rem;
                }
                .shopBottomPrice {
                    color: red;
                    font-weight: 700;
                    font-family: 'SourceHanSansCN';
                    text-align: left;
                    font-size: 0.3rem;
                }
            }
            .orderList {
                color: #000000;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
                text-align: left;
                font-size: 0.26rem;
            }
        }
    }
}
.popupTit {
    font-size: 0.32rem;
    text-align: center;
    font-weight: 700;
    font-family: 'SourceHanSansCN';
    margin-bottom: 0.2rem;
}
.popupMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    .popupMsgLeft {
        .popupMsgLi {
            color: #1b1b1b;
            font-size: 0.28rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin-top: 0.1rem;
        }
    }

    .el-icon-arrow-right {
        font-size: 0.28rem;
    }
}
</style>