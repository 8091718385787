<template>
    <div>
        <div class="Homebig">
            <div v-if="boxIndex == 1 || boxIndex == 2" class="HomebigBox">
                <div class="closeBack" @click="closeBack">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="closeEnd" @click="closeBtn">
                    <i class="el-icon-close"></i>
                </div>
                <div
                    class="HomebigBoxTit"
                    style="margin-top: 0"
                    v-if="boxIndex == 1"
                >
                    忘记密码
                </div>
                <div
                    class="HomebigBoxTit"
                    style="margin-top: 0; margin-bottom: 0.13rem"
                    v-if="boxIndex == 2"
                >
                    注册
                </div>
                <div class="inptBox">
                    <div class="inptIcon">
                        <img src="@/assets/login/shouji.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="text"
                            placeholder="请输入手机号"
                            v-model="phone"
                            placeholder-style="color:#9fa0a5;"
                        />
                    </div>
                </div>
                <div class="inptBox">
                    <div class="inptIcon">
                        <img src="@/assets/login/yzm.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="text"
                            placeholder="请输入验证码"
                            placeholder-style="color:#9fa0a5;"
                            style="width: 1.5rem"
                            v-model="code"
                        />
                        <div class="inptBtn" @click="getCode">
                            {{ timerFont }}
                        </div>
                    </div>
                </div>
                <div class="inptBox">
                    <div class="inptIcon">
                        <img src="@/assets/login/paswod.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="passwordNew"
                            placeholder="请输入新密码"
                            placeholder-style="color:#9fa0a5;"
                            v-model="passwordNew"
                        />
                    </div>
                </div>
                <div class="inptBox">
                    <div class="inptIcon">
                        <img src="@/assets/login/paswod.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="passwordOld"
                            placeholder="确认新密码"
                            placeholder-style="color:#9fa0a5;"
                            v-model="passwordOld"
                        />
                    </div>
                </div>
                <div class="inptBtnLo" v-if="boxIndex == 1" @click="wanji">
                    提交
                </div>
                <div
                    class="inptBtnLo"
                    v-if="boxIndex == 2"
                    @click="zuce"
                    style="margin-bottom: 0.1rem"
                >
                    注册
                </div>
                <div
                    class="agreeBox"
                    v-if="boxIndex == 2"
                    @click="agreeFlag = !agreeFlag"
                >
                    <div class="agreeQuan">
                        <i class="el-icon-check" v-if="agreeFlag"></i>
                    </div>
                    <div class="agreeName">
                        阅读并同意<button
                            @click="toUrl('/xieyiCont', '用户协议')"
                        >
                            《用户协议》</button
                        >和<button @click="toUrl('/xieyiCont', '隐私协议')">
                            《隐私协议》
                        </button>
                    </div>
                </div>
            </div>
            <div class="HomebigBox" v-if="boxIndex == 0">
                <div class="closeEnd" @click="closeBtn">
                    <i class="el-icon-close"></i>
                </div>
                <div class="HomebigBoxTab">
                    <div
                        class="HomebigBoxTabLi"
                        :class="tabIndex == 0 ? 'HomebigBoxTabLiA' : ''"
                        @click="chooseTab(0)"
                    >
                        短信登录
                    </div>
                    <div
                        class="HomebigBoxTabLi"
                        :class="tabIndex == 1 ? 'HomebigBoxTabLiA' : ''"
                        @click="chooseTab(1)"
                    >
                        密码登录
                    </div>
                </div>
                <div class="HomebigBoxTit">欢迎登录<span>粮之道</span></div>
                <div class="inptBox">
                    <div class="inptIcon">
                        <img src="@/assets/login/shouji.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="text"
                            placeholder="请输入手机号"
                            v-model="phone"
                            placeholder-style="color:#9fa0a5;"
                        />
                    </div>
                </div>
                <div class="inptBox" v-if="tabIndex == 0">
                    <div class="inptIcon">
                        <img src="@/assets/login/paswod.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="text"
                            placeholder="请输入验证码"
                            placeholder-style="color:#9fa0a5;"
                            style="width: 1.5rem"
                            v-model="code"
                        />
                        <div class="inptBtn" @click="getCode">
                            {{ timerFont }}
                        </div>
                    </div>
                </div>
                <div class="inptBox" v-if="tabIndex == 1">
                    <div class="inptIcon">
                        <img src="@/assets/login/paswod.png" alt="" />
                    </div>
                    <div class="inptName">
                        <input
                            type="password"
                            placeholder="请输入密码"
                            placeholder-style="color:#9fa0a5;"
                            style="width: 1.5rem"
                            v-model="password"
                        />
                        <div class="inptBtn" @click="wj">忘记?</div>
                    </div>
                </div>
                <div
                    class="jizhumima"
                    v-if="tabIndex == 1"
                    @click="rememberFlag = !rememberFlag"
                >
                    <div class="agreeQuan">
                        <i class="el-icon-check" v-if="rememberFlag"></i>
                    </div>
                    记住密码
                </div>
                <div class="inptBtnLo" v-if="tabIndex == 0" @click="lijidenglu">
                    登录
                </div>
                <div class="inptBtnMima" v-if="tabIndex == 1">
                    <div class="inptBtnMimaOne" @click="login">登录</div>
                    <div class="inptBtnMimaTwo" @click="boxIndex = 2">注册</div>
                </div>
                <div class="agreeBox" @click="agreeFlag = !agreeFlag">
                    <div class="agreeQuan">
                        <i class="el-icon-check" v-if="agreeFlag"></i>
                    </div>
                    <div class="agreeName">
                        阅读并同意<button
                            @click="toUrl('/xieyiCont', '用户协议')"
                        >
                            《用户协议》</button
                        >和<button @click="toUrl('/xieyiCont', '隐私协议')">
                            《隐私协议》
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="markBg" v-if="txFlag">
            <div class="markCont">
                <div class="el-icon-close" @click="txFlag = false"></div>
                <div class="markTit">图形验证码</div>
                <div class="markBgBox">
                    <input
                        type="text"
                        v-model="captchaStr"
                        placeholder="请输入验证码"
                    />
                    <img :src="urltxyzm" mode="widthFix" @click="tuxing" />
                </div>
                <div class="btnpop" @click="yzm">确定</div>
            </div>
        </div>
        <!-- v-if="joinFlag" -->
        <div class="markBg" v-if="joinFlag">
            <div class="markCont">
                <div class="markTit">请选择您的身份，加入粮之道大家庭！</div>
                <div class="markLi" @click="toUrl('/manuFacturer')">
                    <div class="markLeft">
                        <img src="@/assets/login/rz.png" alt="" />
                        成为粮市(成品制造商)
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="markLi" @click="toUrl('/purchasingAgent')">
                    <div class="markLeft">
                        <img src="@/assets/login/rz.png" alt="" />
                        成为粮坊(采购商)
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="markLi" @click="toUrl('/supplierRz')">
                    <div class="markLeft">
                        <img src="@/assets/login/rz.png" alt="" />
                        成为粮仓(供货商)
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="markLi" @click="toUrl('/driverRz')">
                    <div class="markLeft">
                        <img src="@/assets/login/rz.png" alt="" />
                        成为粮运(司机)
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="markLi" @click="toUrl('/youke')">
                    <div class="markLeft">
                        <img src="@/assets/login/rz.png" alt="" />
                        我是游客继续浏览
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    captcha,
    sendSMS,
    codeLogin,
    login,
    forget,
    register
} from '@/api/user/index.js'

export default {
    data() {
        return {
            boxIndex: 0, //0是登陆1室忘记
            tabIndex: 0, //切换登录方式
            agreeFlag: false, //协议
            rememberFlag: false, //是否记住密码
            timerFont: '获取验证码',
            timer: null,
            timers: null,
            count: 0,
            phone: '', //手机号
            txFlag: false,
            uuid: '',
            urltxyzm: '',
            captchaStr: '',
            password: '',
            joinFlag: false,
            passwordNew: '',
            passwordOld: '',
            code: '' //验证码
        }
    },
    created() {
        const savedUsername =
            localStorage.getItem('username') ||
            sessionStorage.getItem('username')
        const savedPassword =
            localStorage.getItem('password') ||
            sessionStorage.getItem('password')

        if (savedUsername && savedPassword) {
            this.phone = savedUsername
            this.password = savedPassword
            this.rememberFlag = true // 自动勾选“记住密码”
        }
    },
    methods: {
        wj() {
            this.boxIndex = 1
        },
        closeBack() {
            this.boxIndex = 0
        },
        // 注册
        zuce() {
            if (!this.phone) {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                })
                return
            }
            if (!this.code) {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning'
                })
                return
            }
            if (!this.passwordNew) {
                this.$message({
                    message: '请输入新密码',
                    type: 'warning'
                })

                return
            }
            if (!this.passwordOld) {
                this.$message({
                    message: '请再次输入密码',
                    type: 'warning'
                })

                return
            }
            if (this.passwordNew != this.passwordOld) {
                this.$message({
                    message: '密码和确认密码输入的不一致',
                    type: 'warning'
                })

                return
            }
            register({
                mobile: this.phone,
                password: this.password,
                code: this.code
            }).then((res) => {
                if (res.code == 0) {
                    localStorage.setItem('token', res.data.token)
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    })
                    if (res.data.userType == 0) {
                        this.joinFlag = true
                    } else {
                        window.location.reload()
                        this.$store.dispatch('updateMessageAsync', false)
                    }
                }
            })
        },
        // 忘记密码
        wanji() {
            if (!this.phone) {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                })
                return
            }
            if (!this.code) {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning'
                })
                return
            }
            if (!this.passwordNew) {
                this.$message({
                    message: '请输入新密码',
                    type: 'warning'
                })

                return
            }
            if (!this.passwordOld) {
                this.$message({
                    message: '请再次输入密码',
                    type: 'warning'
                })

                return
            }
            if (this.passwordNew != this.passwordOld) {
                this.$message({
                    message: '密码和确认密码输入的不一致',
                    type: 'warning'
                })

                return
            }
            forget({
                mobile: this.phone,
                password: this.password,
                smsCode: this.code
            }).then((response) => {
                console.log(response)
                if (response.code == 0) {
                    this.$message({
                        message: '修改成功，请重新登录',
                        type: 'success'
                    })
                    this.boxIndex = 0
                    this.tabIndex = 1
                }
            })
        },
        // 登录
        login() {
            if (!this.phone) {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                })
                return
            }
            if (!this.password) {
                this.$message({
                    message: '请输入密码',
                    type: 'warning'
                })

                return
            }

            if (!this.agreeFlag) {
                this.$message({
                    message: '请阅读并同意用户协议和隐私协议',
                    type: 'warning'
                })
                return
            }
            if (this.rememberFlag) {
                localStorage.setItem('username', this.phone)
                localStorage.setItem('password', this.password)
            } else {
                sessionStorage.setItem('username', this.phone)
                sessionStorage.setItem('password', this.password)
                localStorage.removeItem('username') // 清除长期存储
                localStorage.removeItem('password')
            }
            login({ mobile: this.phone, password: this.password }).then(
                (response) => {
                    console.log(response)
                    if (response.code == 0) {
                        localStorage.setItem('token', response.data.token)
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        })
                        if (response.data.userType == 0) {
                            this.joinFlag = true
                        } else {
                            window.location.reload()
                            this.$store.dispatch('updateMessageAsync', false)
                        }
                    }
                }
            )
        },
        closeBtn() {
            this.$store.dispatch('updateMessageAsync', false)
        },
        // 立即登录
        lijidenglu() {
            if (!this.phone) {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                })
                return
            }
            if (!this.code) {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning'
                })
                return
            }
            if (!this.agreeFlag) {
                this.$message({
                    message: '请阅读并同意用户协议和隐私协议',
                    type: 'warning'
                })
                return
            }
            codeLogin({ mobile: this.phone, smsCode: this.code }).then(
                (res) => {
                    console.log(res)
                    if (res.code == 0) {
                        localStorage.setItem('token', res.data.token)

                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        })
                        if (res.data.userType == 0) {
                            this.joinFlag = true
                        } else {
                            window.location.reload()
                            this.$store.dispatch('updateMessageAsync', false)
                        }
                    }
                }
            )
        },
        // 短信验证码获取
        yzm() {
            let _this = this
            if (!this.captchaStr) {
                this.$message({
                    message: '请输入图形验证码',
                    type: 'warning'
                })
                return
            }
            const TIME_COUNT = 60
            if (!_this.timer) {
                sendSMS({
                    mobile: this.phone,
                    uuid: this.uuid,
                    captcha: this.captchaStr
                }).then((res) => {
                    if (res.code == 0) {
                        _this.txFlag = false
                        this.$message({
                            message: '发送成功',
                            type: 'success'
                        })
                        _this.count = TIME_COUNT
                        _this.timer = setInterval(() => {
                            if (_this.count > 0 && this.count <= TIME_COUNT) {
                                _this.count--
                                _this.timerFont = this.count + '秒'
                            } else {
                                _this.timerFont = '获取验证码'
                                clearInterval(this.timer)
                                _this.timer = null
                            }
                        }, 1000)
                    }
                })
            }
        },
        // 获取验证码

        arrayBufferToBase64(buffer) {
            var binary = ''
            var bytes = new Uint8Array(buffer)
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            return window.btoa(binary)
        },
        getUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                /[xy]/g,
                (c) => {
                    return (
                        c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8'
                    ).toString(16)
                }
            )
        },
        tuxing() {
            this.uuid = this.getUUID()
            captcha(this.uuid).then((res) => {
                this.urltxyzm =
                    'data:image/jpeg;base64,' + this.arrayBufferToBase64(res)
            })
        },
        getCode() {
            if (!this.phone) {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning'
                })
                return
            }
            if (!/^1[3456789]\d{9}$/.test(this.phone)) {
                this.$message({
                    message: '输入的手机号有误',
                    type: 'warning'
                })
                return
            }

            console.log(this.uuid)
            this.txFlag = true
            this.tuxing()
        },

        chooseTab(num) {
            this.tabIndex = num
        },
        toUrl(urlSrc, name) {
            if (urlSrc == '/xieyiCont') {
                this.$router.push({ path: urlSrc, query: { name: name } })
                this.$store.dispatch('updateMessageAsync', false)

                return
            }
            if (urlSrc == '/youke') {
                this.joinFlag = false
                window.location.reload()
                this.$store.dispatch('updateMessageAsync', false)
                return
            }
            this.$store.dispatch('updateMessageAsync', false)
            this.$router.push(urlSrc)
        }
    }
}
</script>

<style lang="scss" scoped>
.Homebig {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.HomebigBox {
    width: 3.83rem;
    height: 3.98rem;
    background: #ffffff;
    border-radius: 0.1rem;
    padding: 0.26rem 0.43rem 0.23rem;
    box-sizing: border-box;
    position: relative;
    .closeBack {
        position: absolute;
        top: 0;
        left: 0.1rem;
        font-size: 0.3rem;
        font-weight: bold;
    }
    .closeEnd {
        position: absolute;
        top: 0;
        right: 0.1rem;
        font-size: 0.3rem;
        font-weight: bold;
    }
}
.HomebigBoxTab {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.HomebigBoxTabLi {
    color: #343434;
    font-size: 0.15rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
}
.HomebigBoxTabLiA {
    color: #6f8c52;
    border-bottom: 1px solid #6f8c53;
}
.HomebigBoxTit {
    color: #343434;
    font-size: 0.17rem;
    font-weight: 700;
    font-family: 'SourceHanSansCN';
    text-align: center;
    margin-top: 0.3rem;

    margin-bottom: 0.23rem;
}
.HomebigBoxTit span {
    color: #6f8c52;
}
.inptBox {
    width: 2.98rem;
    height: 0.45rem;
    background: #f3f4f9;
    display: flex;
    align-items: center;
    padding: 0 0.18rem;
    margin-bottom: 0.16rem;
    box-sizing: border-box;
    .inptIcon {
        width: 0.15rem;
        img {
            width: 0.15rem;
            height: 0.16rem;
            display: block;
        }
    }
    .inptName {
        display: flex;
        align-items: center;
        border-left: 1px solid #9fa0a5;
        margin-left: 0.1rem;
        padding-left: 0.1rem;
        box-sizing: border-box;
        flex: 1;
        input {
            font-size: 0.13rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            flex: 1;
        }
        .inptBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.12rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 0.78rem;
            height: 0.2rem;
            border-radius: 0.53rem;
            background: linear-gradient(180deg, #96ae7c 0%, #6f8c53 100%);
        }
    }
}
.agreeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .agreeQuan {
        margin-right: 0.05rem;
        width: 14px;
        height: 14px;
        border-radius: 172px;
        border: 1px solid #707070;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-check {
            font-size: 12px;
            color: #6f8c52;
            font-weight: bold;
        }
    }
    .agreeName {
        color: #707070;
        font-size: 0.14rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        button {
            color: #6f8c52;
            font-size: 0.14rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
        }
    }
}
.jizhumima {
    color: #9fa0a5;
    font-size: 0.13rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    display: flex;
    align-items: center;
    margin-bottom: 0.13rem;
    .agreeQuan {
        margin-right: 0.05rem;
        width: 14px;
        height: 14px;
        border-radius: 5px;
        border: 1px solid #707070;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-icon-check {
            font-size: 12px;
            color: #6f8c52;
            font-weight: bold;
        }
    }
}
.inptBtnLo {
    display: flex;

    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 0.16rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    text-align: center;
    width: 2.98rem;
    height: 0.45rem;
    border-radius: 0.71rem;
    letter-spacing: 0.15rem;
    padding-left: 0.15rem;
    margin-bottom: 0.6rem;
    box-sizing: border-box;
    background: linear-gradient(180deg, #96ae7c 0%, #6f8c53 100%);
}
.inptBtnMima {
    color: #ffffff;
    font-size: 0.16rem;
    font-weight: 400;
    font-family: 'SourceHanSansCN';
    letter-spacing: 0.1rem;
    margin-bottom: 0.34rem;
    width: 2.98rem;
    height: 0.45rem;
    border-radius: 0.71rem;
    background: linear-gradient(0deg, #e5e5e5 0%, #f7f2f2 100%);
    display: flex;
    align-items: center;
    .inptBtnMimaOne {
        padding-left: 0.1rem;
        box-sizing: border-box;

        display: flex;

        align-items: center;
        justify-content: center;
        width: 2.04rem;
        height: 0.45rem;
        border-radius: 0.71rem;
        background: linear-gradient(180deg, #96ae7c 0%, #6f8c53 100%);
    }
    .inptBtnMimaTwo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #6f8c52;
    }
}
.markBg {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;

    .markCont {
        min-width: 3.2rem;
        border-radius: 0.24rem;
        opacity: 1;
        border: 0.01rem solid #979797;
        background: #ffffff;
        box-shadow: 0 0.06rem 0.58rem 0 #7991ad33;
        padding: 0.34rem 0.3rem 0.29rem;
        box-sizing: border-box;
        position: relative;

        .el-icon-close {
            color: #0a1629;
            font-size: 0.2rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            position: absolute;
            right: 0.15rem;
            top: 0.15rem;
        }

        .markTit {
            color: #0a1629;
            font-size: 0.3rem;
            font-weight: 500;
            font-family: 'SourceHanSansCN';
            text-align: center;
            margin-bottom: 0.15rem;
        }
        .markTip {
            color: #969799;
            font-size: 14px;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
        }
        .markBtn {
            width: 100%;
            height: 48px;
            line-height: 48px;
            margin-top: 26px;
            text-align: center;
            color: #00903d;
            font-size: 16px;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
        button {
            color: #00903d;
            font-size: 14px;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
        }
        .markBgBox {
            display: flex;
            align-content: center;
            padding: 0.1rem;
            box-sizing: border-box;

            input {
                flex: 1;
                font-size: 0.3rem;
            }

            img {
                width: 2rem;
            }
        }

        .btnpop {
            width: 90%;
            height: 0.64rem;
            border-radius: 0.5rem;
            opacity: 1;
            background: linear-gradient(-41.4deg, #ffbe00 0%, #ffeb3b 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin: 0.28rem auto 0;
        }

        .markLi {
            width: 2.95rem;
            height: 0.78rem;
            border-radius: 0.14rem;
            opacity: 1;
            background: #f4f9fd;
            margin: 0 auto 0.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.2rem;
            box-sizing: border-box;

            .markLeft {
                color: #0a1629;
                font-size: 0.18rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;

                img {
                    width: 0.24rem;
                    height: 0.24rem;
                    margin-right: 0.16rem;
                }
            }

            .el-icon-arrow-right {
                font-size: 0.18rem;
            }
        }
    }
}
</style>