<template>
    <div class="content">
        <div class="footer">
            <div class="footerLeft">
                <div class="footerDisplay">
                    <img :src="msgData.company.logo" alt="" />
                    <!-- <div class="footerName">{{msgData.company.name}}</div> -->
                </div>
                <div class="footerContBox">
                    <div class="footerCont">
                        <div class="footerContLi">
                            <div class="footerContName">咨询电话</div>
                            <div class="footerContText">
                                {{ msgData.company.telephone }}
                            </div>
                        </div>

                        <div class="footerContLi">
                            <div class="footerContName">客服电话</div>
                            <div class="footerContText">
                                {{ msgData.company.mobile }}
                            </div>
                        </div>
                        <div class="footerContLi">
                            <div class="footerContName">商务合作</div>
                            <div class="footerContText">
                                {{ msgData.company.email }}
                            </div>
                        </div>
                        <div class="footerContLi">
                            <div class="footerContName">联系地址</div>
                            <div class="footerContText">
                                {{ msgData.company.address }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import { getWeb } from '@/api/user/index.js'

export default {
    data() {
        return {
            msgData: {
                company: {}
            }
        }
    },
    created() {
        this.wznr()
    },
    methods: {
        wznr() {
            getWeb().then((res) => {
                this.msgData = res.data
                localStorage.setItem('webMsg', JSON.stringify(res.data))
            })
        }
    }
}
</script>
  
  <style lang="scss" scoped>
.footer {
    width: 100%;
    height: 3.43rem;
    background: #6f8c52;
    display: flex;
    .footerLeft {
        width: 11.9rem;
        margin: 0 auto;
        display: flex;
        .footerDisplay {
            // width: 1.13rem;
            // height: 1.39rem;
            width: 0.9rem;
            height: 1.16rem;
            background: url('../assets/lobg.png') no-repeat center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.24rem;
            border-radius: 0 0 0.5rem 0;
            img {
                width: 0.76rem;
                height: 0.91rem;
            }
        }
        .footerContBox {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.43rem;
            flex: 1;
        }
        .footerCont {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .footerContLi {
                margin-right: 0.76rem;

                margin-bottom: 0.2rem;
                .footerContName {
                    width: 1.26rem;
                    height: 0.36rem;
                    color: #000000;
                    font-weight: 0;
                    font-size: 0.22rem;
                    font-family: 'SourceHanSansCN';
                    background: #efce63;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0 0.5rem 0.5rem 0;
                }
                .footerContText {
                    color: #ffffff;
                    font-size: 0.18rem;
                    font-family: 'SourceHanSansCN';
                    margin: 0.1rem 0.2rem;
                }
            }
        }
    }
}
</style>