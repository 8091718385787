import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import HeaderTop from './components/headerTop'
import HeaderTopNew from './components/headerTopNew'
import CountDown from './components/countDown'
import CancelOrder from './components/cancelOrder'
import FooterBottom from './components/FooterBottom'
import FooterBottomNew from './components/FooterBottomNew'
import SpeciFication from './components/speciFication'
import ApplyForBilling from './components/applyForBilling'
import logoAll from './components/loginAll'


import store from './store/index.js'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import './config/rem.js';  
// 在 main.js 中引入
import '@/assets/fontFamily/font.css'
Vue.component('logoAll', logoAll)//登录

Vue.component('HeaderTop', HeaderTop)//公共头部
Vue.component('HeaderTopNew', HeaderTopNew)//公共头部

Vue.component('CountDown', CountDown)//倒计时
Vue.component('FooterBottom', FooterBottom)//公共底部
Vue.component('FooterBottomNew', FooterBottomNew)//公共底部


Vue.component('CancelOrder', CancelOrder)//取消订单弹窗
Vue.component('SpeciFication', SpeciFication)//商品规格选择
Vue.component('ApplyForBilling', ApplyForBilling)//申请开票



Vue.use(ElementUi)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
