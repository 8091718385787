var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"boxBig",style:(_vm.backgroundStyle)},[_c('div',{staticClass:"box",style:({ position: _vm.position })},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.flagView)?_c('div',{staticClass:"boxLeft",style:({ color: _vm.activeColor })},[_c('img',{attrs:{"src":_vm.msgData.company.logo,"alt":""}})]):_vm._e(),(!_vm.flagView)?_c('div',{staticClass:"boxLeftA",style:({ color: _vm.activeColor })},[_c('img',{attrs:{"src":_vm.msgData.company.logo,"alt":""}})]):_vm._e(),_c('div',{staticClass:"inpt"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inptName),expression:"inptName"}],attrs:{"type":"text"},domProps:{"value":(_vm.inptName)},on:{"input":function($event){if($event.target.composing)return;_vm.inptName=$event.target.value}}}),_c('div',{staticClass:"inptName",on:{"click":_vm.searchClick}},[_c('img',{attrs:{"src":require("../assets/searchNew.png"),"alt":""}})])])]),_c('div',{staticClass:"boxRight"},[_c('div',{staticClass:"boxRightNav"},[_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 0 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 0 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/', 0)}}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 1 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 1 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/grainMarket', 1)}}},[_vm._v(" 粮市 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 2 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 2 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/grainMill', 2)}}},[_vm._v(" 粮坊 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 3 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 3 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/grainElevator', 3)}}},[_vm._v(" 粮仓 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 4 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 4 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/grainFarmer', 4)}}},[_vm._v(" 粮耕 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 5 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 5 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/newPage', 5)}}},[_vm._v(" 粮讯 ")]),_c('div',{staticClass:"boxRightNavLine"}),_c('div',{staticClass:"boxRightNavLi",class:_vm.parentData == 6 ? 'boxRightNavLiA' : '',style:({
                            color: _vm.parentData == 6 ? _vm.activeColor : _vm.color
                        }),on:{"click":function($event){return _vm.toUrl('/joinOus', 6)}}},[_vm._v(" 粮道 ")])]),_c('div',{staticStyle:{"width":"0.2rem"}}),(!_vm.userInfoMsg.username)?_c('div',{staticClass:"goDl",on:{"click":_vm.logoGo}},[_vm._v(" 去登录 ")]):_vm._e(),(_vm.userInfoMsg.username)?_c('div',{staticClass:"boxMsg",style:({ color: _vm.activeColor }),on:{"click":_vm.logoGo}},[(_vm.userInfoMsg.headUrl)?_c('img',{attrs:{"src":_vm.userInfoMsg.headUrl,"alt":""}}):_vm._e(),_c('div',{staticClass:"userInfoCss"},[_vm._v(" "+_vm._s(_vm.userInfoMsg.username ? _vm.userInfoMsg.username : '去登录')+" ")]),(!_vm.menusFlag)?_c('div',{staticClass:"el-icon-caret-bottom"}):_vm._e(),(_vm.menusFlag)?_c('div',{staticClass:"el-icon-caret-top"}):_vm._e(),(_vm.menusFlag)?_c('div',{staticClass:"popup"},[_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/myFavorite', 0)}}},[_vm._v(" 我的收藏 ")]),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/collectionInformation', 0)}}},[_vm._v(" 收款信息 ")]),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/purchaserOrderAll', 0)}}},[_vm._v(" 收货订单 ")]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/supplierOrderAll', 0)}}},[_vm._v(" 供货订单 ")]):_vm._e(),(_vm.userInfoMsg.userType == 4)?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/driveList', 0)}}},[_vm._v(" 全部订单 ")]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/purchaserDb', 0)}}},[_vm._v(" 待办订单 ")]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/orderRelease', 0)}}},[_vm._v(" 发布采购需求 ")]):_vm._e(),(_vm.userInfoMsg.userType == 3)?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/orderReleaseGys', 0)}}},[_vm._v(" 发布供应需求 ")]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/issueMy', 0)}}},[_c('div',[_vm._v("我的发布")])]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/grainCoupon', 0)}}},[_c('div',[_vm._v("我的粮票")]),(_vm.userInfoMsg.isCredit == 1)?_c('div',[_vm._v(" "+_vm._s(_vm.userInfoMsg.amount)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/shopList', 0, 3)}}},[_vm._v(" 新品推荐 ")]),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/cartShop', 0)}}},[_vm._v(" 购物车 ")]),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/addressList', 0)}}},[_vm._v(" 收获地址 ")]),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3 ||
                                _vm.userInfoMsg.userType == 5
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/freightTemplate', 0)}}},[_vm._v(" 运费模版 ")]):_vm._e(),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/merchandiseOrderList', 0)}}},[_vm._v(" 商品买入订单 ")]),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3 ||
                                _vm.userInfoMsg.userType == 5
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/merchandiseSaleIndex', 0)}}},[_vm._v(" 商品卖出订单 ")]):_vm._e(),(
                                _vm.userInfoMsg.userType == 1 ||
                                _vm.userInfoMsg.userType == 2 ||
                                _vm.userInfoMsg.userType == 3 ||
                                _vm.userInfoMsg.userType == 5
                            )?_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/addShop', 0)}}},[_vm._v(" 企业商品 ")]):_vm._e(),_c('div',{staticClass:"popupLi",on:{"click":function($event){return _vm.toUrl('/invoiceAdmin', 0)}}},[_vm._v(" 发票信息 ")]),_c('div',{staticClass:"popupLi",on:{"click":_vm.goOut}},[_vm._v("退出登录")])]):_vm._e()]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }