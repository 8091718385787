<template>
    <div class="content">
        <div class="boxBig" :style="backgroundStyle">
            <div class="box" :style="{ position: position }">
                <div style="display: flex; align-items: center">
                    <div
                        class="boxLeft"
                        :style="{ color: activeColor }"
                        v-if="flagView"
                    >
                        <img :src="msgData.company.logo" alt="" />
                        <!-- {{msgData.company.name}} -->
                    </div>
                    <div
                        class="boxLeftA"
                        :style="{ color: activeColor }"
                        v-if="!flagView"
                    >
                        <img :src="msgData.company.logo" alt="" />
                        <!-- {{msgData.company.name}} -->
                    </div>
                    <div class="inpt">
                        <input type="text" v-model="inptName" />
                        <div class="inptName" @click="searchClick">
                            <img src="../assets/searchNew.png" alt="" />
                        </div>
                    </div>
                </div>

                <div class="boxRight">
                    <div class="boxRightNav">
                        <div
                            class="boxRightNavLi"
                            :class="parentData == 0 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 0 ? activeColor : color
                            }"
                            @click="toUrl('/', 0)"
                        >
                            首页
                        </div>
                        <div class="boxRightNavLine"></div>
                        <div
                            class="boxRightNavLi"
                            :class="parentData == 1 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 1 ? activeColor : color
                            }"
                            @click="toUrl('/grainMarket', 1)"
                        >
                            粮市
                        </div>
                        <div class="boxRightNavLine"></div>

                        <div
                            class="boxRightNavLi"
                            :class="parentData == 2 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 2 ? activeColor : color
                            }"
                            @click="toUrl('/grainMill', 2)"
                        >
                            粮坊
                        </div>
                        <div class="boxRightNavLine"></div>

                        <div
                            class="boxRightNavLi"
                            :class="parentData == 3 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 3 ? activeColor : color
                            }"
                            @click="toUrl('/grainElevator', 3)"
                        >
                            粮仓
                        </div>

                        <div class="boxRightNavLine"></div>

                        <div
                            class="boxRightNavLi"
                            :class="parentData == 4 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 4 ? activeColor : color
                            }"
                            @click="toUrl('/grainFarmer', 4)"
                        >
                            粮耕
                        </div>
                        <div class="boxRightNavLine"></div>

                        <div
                            class="boxRightNavLi"
                            :class="parentData == 5 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 5 ? activeColor : color
                            }"
                            @click="toUrl('/newPage', 5)"
                        >
                            粮讯
                        </div>
                        <div class="boxRightNavLine"></div>

                        <div
                            class="boxRightNavLi"
                            :class="parentData == 6 ? 'boxRightNavLiA' : ''"
                            :style="{
                                color: parentData == 6 ? activeColor : color
                            }"
                            @click="toUrl('/joinOus', 6)"
                        >
                            粮道
                        </div>
                    </div>
                    <div style="width: 0.2rem"></div>
                    <div
                        @click="logoGo"
                        v-if="!userInfoMsg.username"
                        class="goDl"
                    >
                        去登录
                    </div>
                    <div
                        class="boxMsg"
                        @click="logoGo"
                        :style="{ color: activeColor }"
                        v-if="userInfoMsg.username"
                    >
                        <img
                            :src="userInfoMsg.headUrl"
                            alt=""
                            v-if="userInfoMsg.headUrl"
                        />
                        <!-- <img src="@/assets/logo.png" alt="" v-if="!userInfoMsg.headUrl"/> -->
                        <div class="userInfoCss">
                            {{
                                userInfoMsg.username
                                    ? userInfoMsg.username
                                    : '去登录'
                            }}
                        </div>
                        <div
                            class="el-icon-caret-bottom"
                            v-if="!menusFlag"
                        ></div>
                        <div class="el-icon-caret-top" v-if="menusFlag"></div>
                        <div class="popup" v-if="menusFlag">
                            <div
                                class="popupLi"
                                @click="toUrl('/myFavorite', 0)"
                            >
                                我的收藏
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/collectionInformation', 0)"
                            >
                                收款信息
                            </div>
                            <!-- <div
                            class="popupLi"
                            @click="toUrl('/purchaserOrderAll', 0)"
                        >
                            全部订单
                        </div> -->
                            <div
                                class="popupLi"
                                @click="toUrl('/purchaserOrderAll', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3
                                "
                            >
                                收货订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/supplierOrderAll', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3
                                "
                            >
                                供货订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/driveList', 0)"
                                v-if="userInfoMsg.userType == 4"
                            >
                                全部订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/purchaserDb', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3
                                "
                            >
                                待办订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/orderRelease', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2
                                "
                            >
                                发布采购需求
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/orderReleaseGys', 0)"
                                v-if="userInfoMsg.userType == 3"
                            >
                                发布供应需求
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/issueMy', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3
                                "
                            >
                                <div>我的发布</div>
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/grainCoupon', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3
                                "
                            >
                                <div>我的粮票</div>
                                <div v-if="userInfoMsg.isCredit == 1">
                                    {{ userInfoMsg.amount }}
                                </div>
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/shopList', 0, 3)"
                            >
                                新品推荐
                            </div>
                            <div class="popupLi" @click="toUrl('/cartShop', 0)">
                                购物车
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/addressList', 0)"
                            >
                                收获地址
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/freightTemplate', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3 ||
                                    userInfoMsg.userType == 5
                                "
                            >
                                运费模版
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/merchandiseOrderList', 0)"
                            >
                                商品买入订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/merchandiseSaleIndex', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3 ||
                                    userInfoMsg.userType == 5
                                "
                            >
                                商品卖出订单
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/addShop', 0)"
                                v-if="
                                    userInfoMsg.userType == 1 ||
                                    userInfoMsg.userType == 2 ||
                                    userInfoMsg.userType == 3 ||
                                    userInfoMsg.userType == 5
                                "
                            >
                                企业商品
                            </div>
                            <div
                                class="popupLi"
                                @click="toUrl('/invoiceAdmin', 0)"
                            >
                                发票信息
                            </div>

                            <div class="popupLi" @click="goOut">退出登录</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userInfo, getWeb, logout } from '@/api/user/index.js'
// import service from '@/utils/request';

export default {
    data() {
        return {
            menusFlag: false,
            userInfoMsg: {},
            msgData: {
                company: {},
                parentData: localStorage.getItem('indexNum')
                    ? localStorage.getItem('indexNum')
                    : 0
            },

            inptName: ''
        }
    },
    props: {
        flagView: {
            type: Boolean,
            default: true
        },
        parentData: {
            type: Number,
            default: 0
        },
        backgroundImage: {
            type: String,
            // default: 'rgba($color: #000000, $alpha: 0.7)'
            default: ''
            // default: ''
        },
        backgroundBg: {
            type: String,
            // default: 'rgba($color: #000000, $alpha: 0.7)'
            default: 'rgba(255,255,255,0)'
            // default: ''
        },
        color: {
            type: String,
            default: '#ffffff'
        },
        activeColor: {
            type: String,
            default: '#ffffff'
        },
        position: {
            type: String,
            default: 'fixed'
        }
    },
    watch: {
        backgroundBg(data) {
            console.log(data, 'dadadadad')
            return data
        }
    },
    created() {
        // this.getInit()
        this.wznr()
        if (localStorage.getItem('token')) {
            this.getUser()
        }
    },
    computed: {
        backgroundStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`,
                backgroundSize: 'cover', // 根据需要调整背景图大小
                backgroundPosition: 'center' // 背景图居中显示
            }
        }
    },
    methods: {
        //搜索
        searchClick() {
            if (!this.inptName) {
                this.$message({
                    message: '请输入搜索内容',
                    type: 'warning'
                })
                return
            }
            this.$router.push({
                path: '/allSearch',
                query: { name: this.inptName }
            })
        },
        // getInit() {
        //   index({ type: 0, page: 1, keyword: '' }).then(res => {
        //     localStorage.setItem('lunboList',JSON.stringify(res.data.carouselList.records))
        //     // this.initData = res.data
        //     // console.log(this.initData)
        //   })
        // },
        wznr() {
            getWeb().then((res) => {
                this.msgData = res.data
                localStorage.setItem('webMsg', JSON.stringify(res.data))
                this.$emit('childMessage', res.data)
            })
        },
        getUser() {
            userInfo().then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.userInfoMsg = res.data
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }
            })
        },
        logoGo() {
            // this.$router.replace({name:'login'})
            if (localStorage.getItem('token')) {
                this.menusFlag = !this.menusFlag
            } else {
                // this.$router.replace({ name: 'login' })
                // this.loginFlag = true
                this.$store.dispatch('updateMessageAsync', true)
            }
        },
        toUrl(num, index, id) {
            localStorage.setItem('indexNum', index)
            // if (num == '/purchaserOrderAll') {
            //     if (this.userInfoMsg.userType == 3) {
            //         this.$router.push('/supplierOrderAll')
            //     } else if (this.userInfoMsg.userType == 4) {
            //         this.$router.push('/driveList')
            //     } else {
            //         this.$router.push(num)
            //     }

            //     return
            // }
            console.log(num)
            console.log(this.userInfoMsg.isCredit)

            if (num == '/grainCoupon') {
                if (this.userInfoMsg.isCredit == 1) {
                    this.$router.push('/lpBox')

                    // this.$router.push(num)
                } else {
                    this.$router.push({
                        path: '/foodCoupon',
                        query: { index: this.userInfoMsg.isCredit }
                    })
                }
                return
            }
            if (num == '/shopList') {
                this.$router.push({ path: num, query: { type: id } })
                return
            }
            this.$router.push(num)
        },
        // goOut(){

        // },
        goOut() {
            // localStorage.clear()
            // this.$router.replace('/login')

            logout('logout').then((res) => {
                console.log(res)
                if (res.code == 0) {
                    const username = localStorage.getItem('username') // 示例：保留主题设置
                    const password = localStorage.getItem('password') // 示例：保留语言设置
                    localStorage.clear()
                    if (username) {
                        localStorage.setItem('username', username)
                    }
                    if (password) {
                        localStorage.setItem('password', password)
                    }
                    // this.$router.replace('/login')
                    // this.$router.replace('/')
                    location.reload()
                    this.$router.push('/').catch((err) => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err
                        }
                    })
                    // this.$router.replace({ path: '/' })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.boxBig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 0.8rem;
    background-size: 100% 100%;
}
.userInfoCss {
    width: 1rem;
    // flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.box {
    width: 11.9rem;
    height: 0.8rem;
    // background: rgba($color: #ffffff, $alpha: 0);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .boxLeft {
        display: flex;
        align-items: center;
        background: url('../assets/lobg.png') no-repeat center;
        background-size: cover;
        // background: #ffffff;
        font-size: 0.28rem;
        font-weight: 500;
        font-family: 'mianfeiziti';
        // width: 0.65rem;
        // height: 0.8rem;
        width: 1.5rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 0 0.9rem 0;
        img {
            // width: 0.51rem;
            // height: 0.61rem;
            width: 1.4rem;
            height: 1.8rem;
            margin-right: 0.09rem;
        }
    }
    .boxLeftA {
        display: flex;
        align-items: center;
        // background: url('../assets/lobg.png') no-repeat center;
        background-size: cover;
        font-size: 0.28rem;
        font-weight: 500;
        font-family: 'mianfeiziti';
        width: 0.65rem;
        height: 0.8rem;
        // width: 1.5rem;
        // height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        img {
            // width: 0.51rem;
            // height: 0.61rem;
            width: 0.61rem;
            height: 0.75rem;
            // width: 1.4rem;
            // height: 1.8rem;
            // margin-right: 0.09rem;
        }
    }
    .inpt {
        width: 2.94rem;
        height: 0.32rem;
        border: 0.01rem solid #ffffff;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // margin-left: 1.1rem;
        margin-left: 1.75rem;
        margin-right: 0.9rem;
        padding-left: 0.1rem;
        box-sizing: border-box;

        input {
            width: 2.27rem;
            color: #ffffff;
        }
        .inptName {
            width: 0.56rem;
            height: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: 0.2);
            border-radius: 0 50px 50px 0;
            img {
                width: 0.26rem;
                height: 0.22rem;
            }
        }
    }

    .boxRight {
        display: flex;
        align-items: center;

        .boxRightNav {
            display: flex;
            align-items: center;
            width: 4.7rem;
            justify-content: space-between;
            .boxRightNavLine {
                width: 1px;
                height: 0.3rem;
                background: #ffffff;
                margin: 0 0.06rem;
            }

            .boxRightNavLi {
                width: 0.63rem;
                height: 0.32rem;
                color: #ffffff;
                font-size: 0.18rem;
                // font-size: 0.2rem;
                font-weight: 300;
                font-family: 'SourceHanSansCN';
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .boxRightNavLiA {
                width: 0.63rem;
                height: 0.32rem;
                border-radius: 0.6rem;
                background: #76935a;
            }
        }
        .goDl {
            color: #ffffff;
            font-size: 0.15rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            width: 0.94rem;
            height: 0.31rem;
            background: rgba($color: #000000, $alpha: 0.1);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .boxMsg {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 0.18rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            position: relative;

            img {
                width: 0.38rem;
                height: 0.38rem;
                margin-right: 0.1rem;
                border-radius: 50%;
            }

            .el-icon-caret-bottom {
                margin-left: 0.2rem;
                font-size: 0.25rem;
            }
            .el-icon-caret-top {
                margin-left: 0.2rem;
                font-size: 0.25rem;
            }

            .popup {
                position: absolute;
                top: 0.6rem;
                left: 0;
                width: 1.28rem;
                border-radius: 0.08rem;
                opacity: 1;
                background: #ffffff;
                box-shadow: 0 0.1rem 0.3rem 0 #bcc9e380;
                padding: 0.1rem 0;
                z-index: 9999999999;
                .popupLi {
                    color: #798cb5;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    width: 1.1034rem;
                    min-height: 0.28rem;
                    border-radius: 0.04rem;
                    line-height: 0.28rem;
                    margin: 0 auto 0.08rem;
                }

                .popupLi:hover {
                    background: #f3f4fc;
                }
            }
        }
    }
}
</style>
