<template>
    <div class="content" @scroll="handleScroll">
        <div class="bgColor"></div>
        <HeaderTopNew
            :parentData="dataNum"
            :backgroundImage="backgroundBg"
            :flagView="flagView"
        ></HeaderTopNew>
        <!-- <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop> -->
        <div
            class="headerImg"
            @click="toUrl('/seedList', list[0].id, list[0].title)"
        >
            <img :src="list[0].pic" alt="" />
        </div>

        <div class="headerMsgBox">
            <div class="headerMsgBoxTop">
                <div class="headerMsgBoxTopTit">
                    <div>为中国农业的</div>
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex: 1;
                        "
                    >
                        可持续发展贡献力量
                        <div class="headerMsgBoxTopMore">了解更多信息 ></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ciffyBox">
            <div v-for="(item, index) in list" :key="index">
                <div
                    class="ciffyBoxLi"
                    v-if="index >= 1"
                    @click="toUrl('/seedList', item.id, item.title)"
                >
                    <img :src="item.pic" alt="aspectFill" />
                </div>
            </div>
        </div>

        <div class="titleIconBox">
            <div class="titleIcon">
                <img src="../assets/jinrtm.png" alt="" />
            </div>
            <div class="titleCont">
                <div class="titleName">今日特卖</div>
                <div class="titleTip">精选好物推荐</div>
            </div>
            <div class="titleMore" @click="toUrl('/temaiList', 4)">
                <div>查看更多<i class="el-icon-arrow-right"></i></div>
            </div>
        </div>
        <div style="position: relative; width: 11.9rem; margin: 0 auto">
            <div class="shopBox">
                <div
                    class="shopBoxLi"
                    v-for="(obj, objIndex) in saleList"
                    :key="objIndex"
                    @click.stop="toUrl('/shopXq', obj.id)"
                >
                    <div
                        class="shopBoxImg"
                        :style="getBackgroundStyle(obj.pic[0])"
                    ></div>
                    <div class="shopBoxCont">
                        <div class="shopBoxBottom">
                            <div class="shopPrice">
                                ￥<span class="shopNum">{{ obj.price }}</span>
                            </div>
                        </div>
                        <div class="shopBoxTit">
                            {{ obj.title }}
                        </div>

                        <div class="shopBoxBottomBtn" @click.stop="gomai(obj)">
                            <div class="shopBoxBottomBtnLi">加入购物车</div>
                            <div class="shopBoxBottomBtnLiA">立即购买</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="titleIconBox">
            <div class="titleIcon">
                <img src="../assets/jinrtm.png" alt="" />
            </div>
            <div class="titleCont">
                <div class="titleName">精选企业</div>
                <div class="titleTip">品质铸就信赖</div>
            </div>
        </div>

        <div class="lsBox">
            <div
                class="lsLi"
                v-for="(item, index) in companyTwoList"
                :key="index"
                @click="toUrl('/companyXq', item.id)"
            >
                <div class="lsImg">
                    <img :src="item.factoryImg" alt="" />
                </div>
                <div class="lsCont">
                    <div class="lsName">
                        {{ item.title }}
                    </div>

                    <div class="lsBtnBox">
                        <div class="lsBtn">
                            了解更多信息
                            <img src="../assets/rightI.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                box-sizing: border-box;
                width: 11.9rem;
                margin: 0.2rem auto;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>

        <!-- <div
            class="marketBox"
            style="background: #fafafa"
            v-if="saleList.length > 0"
        >
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;
                    box-sizing: border-box;
                "
            >
                <div style="width: 1rem"></div>

                <div>
                    <div class="marketTit">今日特卖</div>
                </div>
                <div
                    style="
                        width: 1rem;
                        font-size: 0.2rem;
                        color: rgba(17, 17, 17, 0.5019607843);
                    "
                    @click="toUrl('/temaiList', 4)"
                >
                    更多产品
                </div>
            </div>
            <div class="jrtmBox">
                <div
                    class="jrtmLi"
                    v-for="(item, index) in saleList"
                    :key="index"
                    @click="toUrl('/shopXq', item.id)"
                >
                    <img :src="item.pic[0]" alt="" />
                    <div class="jrtmName">{{ item.title }}</div>
                </div>
            </div>
        </div> -->
        <!-- <div class="contBox">
            <div
                class="contLi"
                @click="toUrl('/seedList', item.id, item.title)"
                v-for="(item, index) in list"
                :key="index"
            >
                <img :src="item.pic" alt="aspectFill" />
                <div class="contName">
                    <div class="contNameText">
                        {{ item.title }}
                    </div>
                </div>
            </div>

           
        </div>
        <FooterBottom></FooterBottom> -->
        <SpeciFication
            v-if="specificationFlag"
            :msgData="dataMsg"
            @closeBtn="closeBtn"
            @guigeMsgGet="guigeMsgGet"
        ></SpeciFication>
    </div>
</template>

<script>
import {
    categoryList,
    index,
    getGoods,
    addShoppingCart
} from '@/api/user/index.js'

export default {
    data() {
        return {
            flagView: true,
            dataNum: 4,
            backgroundBg: require('@/assets/dhBg.png'),
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            list: [],
            lunboList: [],
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            saleList: [],
            dataMsg: {},
            specificationFlag: false
        }
    },
    created() {
        this.getInit()
        this.getInita()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        // 移除事件监听
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        guigeMsgGet(e, num, number) {
            console.log(e, num, number)
            // num==1//加入购物车2市立即购买
            let data = JSON.parse(e)
            if (num == 1) {
                // 加入购物车
                addShoppingCart({ goodsSpecId: data.id, num: number }).then(
                    (res) => {
                        this.specificationFlag = false

                        if (res.code == 0) {
                            this.$message({
                                message: '加入购物车成功',
                                type: 'success',
                                customClass: 'messageBoxTip'
                            })
                        }
                    }
                )
            } else {
                // 立即购买

                this.showSpecifica = false
                // 立即购买
                this.$router.push({
                    path: '/sureOrder',
                    query: { ids: data.id, num: number, type: 2 }
                })
            }
        },
        closeBtn() {
            this.specificationFlag = false
        },
        gomai(item) {
            getGoods({ id: item.id }).then((res) => {
                if (res.code == 0) {
                    res.data.pic = res.data.pic.split(',')
                    res.data.details = res.data.details.split(',')
                    if (res.data.commentList.length > 0) {
                        for (var a = 0; a < res.data.commentList.length; a++) {
                            if (res.data.commentList[a].img) {
                                res.data.commentList[a].img =
                                    res.data.commentList[a].img.split(',')
                            }
                            if (res.data.commentList[a].videos) {
                                res.data.commentList[a].videos =
                                    res.data.commentList[a].videos.split(',')
                            }
                        }
                    }
                    this.dataMsg = res.data
                    this.specificationFlag = true
                }
            })
        },
        handleScroll() {
            this.scrollPosition = window.scrollY || window.pageYOffset // 对于旧版IE使用window.pageYOffset, 对于现代浏览器使用window.scrollY
            console.log(this.scrollPosition, '页面滚动事件被触发')
            // 在这里处理你的逻辑
            if (this.scrollPosition <= 0) {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = true
            } else {
                this.backgroundBg = require('@/assets/dhBg.png')
                this.flagView = false
            }
            console.log(this.backgroundBg, '页面滚动事件被触发')
        },
        getBackgroundStyle(imageUrl) {
            return {
                'background-image': `url(${imageUrl})`,
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
            }
        },
        //搜索
        searchClick() {
            this.$router.push({ path: '/grainFarmerSearch' })
        },

        swiperIndex(index) {
            console.log(index)
            // 1产品详情2公司详情0富文本
            if (this.lunboList[index].type == 1) {
                this.$router.push({
                    path: '/shopXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 2) {
                this.$router.push({
                    path: '/companyXq',
                    query: { id: this.lunboList[index].url }
                })
            } else if (this.lunboList[index].type == 0) {
                this.$router.push({
                    path: '/lbXq',
                    query: { id: this.lunboList[index].id }
                })
            }
        },
        getInita() {
            index({
                type: 4,
                page: this.listQuery.page,
                limit: this.listQuery.limit,
                keyword: ''
            }).then((res) => {
                if (res.code == 0) {
                    if (res.data.saleList.records.length > 0) {
                        for (
                            var c = 0;
                            c < res.data.saleList.records.length;
                            c++
                        ) {
                            res.data.saleList.records[c].pic =
                                res.data.saleList.records[c].pic.split(',')
                        }
                    }
                    this.saleList = res.data.saleList.records
                    this.lunboList = res.data.carouselList.records
                }
            })
        },
        toUrl(urlSrc, id, name) {
            if (urlSrc == '/temaiList') {
                if (localStorage.getItem('token')) {
                    this.$router.push({ path: urlSrc, query: { type: id } })
                } else {
                    this.$message({
                        message: '请登录后操作',
                        type: 'warning'
                    })
                }
                return
            }
            this.$router.push({ path: urlSrc, query: { id: id, name: name } })
        },
        getInit() {
            categoryList().then((res) => {
                if (res.code == 0) {
                    this.list = res.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.headerImg {
    margin-top: 0.8rem;
    width: 100%;
    height: 4.94rem;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.headerMsgBox {
    width: 11.9rem;
    margin: 0.2rem auto 0;
    .headerMsgBoxTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerMsgBoxTopTit {
            color: #3a3a3a;
            font-size: 0.29rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            flex: 1;
        }
        .headerMsgBoxTopMore {
            width: 1.45rem;
            height: 0.29rem;
            border-radius: 0.64rem;
            background: linear-gradient(0deg, #6f8c53 0%, #96ae7c 100%);
            color: #ffffff;
            font-size: 0.17rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.ciffyBox {
    width: 11.9rem;
    margin: 0.22rem auto 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ciffyBoxLi {
        width: 2.26rem;
        height: 4.87rem;
        margin-right: 0.15rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.2rem;
            object-fit: cover;
        }
    }
}
.ciffyBox :nth-child(5n + 1) .ciffyBoxLi {
    margin-right: 0;
}

.titleIconBox {
    width: 11.9rem;
    height: 0.86rem;
    background: #efce63;
    border-radius: 0.1rem;
    margin: 0.23rem auto 0.18rem;
    padding: 0.12rem 0.14rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .titleIcon {
        width: 0.62rem;
        height: 0.62rem;
        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .titleCont {
        margin-left: 0.1rem;
        .titleName {
            color: #3a3a3a;
            font-size: 0.26rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
        }
        .titleTip {
            color: #3a3a3a;
            font-size: 0.16rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
        }
    }
    .titleMore {
        color: #3a3a3a;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex: 1;
        height: 0.62rem;
    }
}

.shopBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    .shopBoxLi {
        border-radius: 0.1rem;
        // margin-bottom: 0.27rem;
        margin-bottom: 0.11rem;
        margin-left: 0.21rem;
        // margin-left: 0.37rem;
        width: 2.21rem;
        // width: 2.62rem;
        background: #ffffff;
        box-shadow: 0px 5px 5px 0px rgba(78, 112, 88, 0.3);
        .shopBoxImg {
            width: 100%;
            height: 2.52rem;
            background: url('../assets/cgsBanner.png') no-repeat center;
            background-size: cover;
            border-radius: 0.1rem 0.1rem 0 0;
            img {
                width: 100%;
                height: 100%;
                border-radius: 0.1rem 0.1rem 0 0;
            }
        }
        .shopBoxCont {
            padding: 0.24rem 0.17rem 0.1rem;
            box-sizing: border-box;
            background: #ffffff;
            z-index: 9999;
            margin-top: -0.3rem;
            background: url('../assets/contBg.png') no-repeat center;
            background-size: 100% 100%;
            .shopBoxTit {
                font-size: 0.2rem;
                color: #000000;
                text-align: center;
                font-family: 'SourceHanSansCN';
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 0.045rem;
            }
            .shopBoxBottom {
                display: flex;
                align-items: center;
                justify-content: center;

                .shopPrice {
                    font-size: 0.2rem;
                    color: #6f8c52;
                    font-weight: 500;

                    font-family: 'SourceHanSansCN';

                    .shopNum {
                        font-size: 0.28rem;
                        font-weight: 500;
                        font-family: 'SourceHanSansCN';
                    }
                    .shopTag {
                        font-size: 0.18rem;
                    }
                }
                img {
                    width: 0.29rem;
                    height: 0.29rem;
                }
            }
            .shopBoxBottomBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.76rem;
                height: 0.24rem;
                border-radius: 0.6rem;
                background: linear-gradient(0deg, #cccccc 0%, #f2f1f3 100%);
                margin: 0.12rem auto 0;
                .shopBoxBottomBtnLi {
                    color: #ffffff;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    text-align: center;
                    width: 0.92rem;
                    height: 0.24rem;
                    border-radius: 0.6rem;
                    background: linear-gradient(0deg, #708d53 0%, #97af7d 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .shopBoxBottomBtnLiA {
                    color: #708d53;
                    font-size: 0.14rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
.shopBox :nth-child(5n + 1) {
    margin-left: 0;
}
.searchBox {
    width: 15.5531rem;
    height: 1rem;
    border-radius: 0.31rem;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    color: #121212;
    font-size: 0.4rem;
    font-weight: 400;
    padding: 0 0.49rem;
    margin: 0.29rem 0 0 1.2rem;
    display: flex;
    align-items: center;
}

.marketBox {
    padding: 0.54rem 0 0 0;

    .marketTit {
        color: #000000;
        font-size: 0.45rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .marketFu {
        color: #666666;
        font-size: 0.16rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        text-align: center;
    }

    .jrtmBox {
        width: 15.5531rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0.29rem auto 0;

        .jrtmLi {
            width: 3.67rem;
            margin-bottom: 0.54rem;

            img {
                width: 3.67rem;
                height: 3.3rem;
                border-radius: 0.2921rem 0.2921rem 0 0;
                display: block;
                object-fit: cover;
            }

            .jrtmName {
                color: #121212;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                text-align: center;
                padding: 0 0.32rem;
                box-sizing: border-box;
                background: #ffffff;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 0.8rem;
                margin-top: 0.3rem;
            }
        }

        .jrtmLi:last-child:nth-child(4n-1) {
            // margin-right: calc(3.67rem+0.02rem/3);
            margin-right: calc(24% + 4% / 3);
        }

        .jrtmLi:last-child:nth-child(4n-2) {
            // margin-right: calc(7.34rem+0.04rem/3);
            margin-right: calc(48% + 8% / 3);
        }
    }
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}

.contBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    padding: 0.63rem 0.49rem 0;
    box-sizing: border-box;
    margin: 1.2rem auto 0.5rem;

    .contLi {
        width: 7.33rem;
        // height: 8.49rem;
        height: 3.68rem;
        border-radius: 0.28rem;
        opacity: 1;
        position: relative;
        margin-bottom: 1.28rem;

        img {
            width: 7.33rem;
            // height: 8.49rem;
            height: 3.68rem;

            border-radius: 0.28rem;
            object-fit: cover;
            //若图片不能全部显示,加上下面这条调节两个值可以设置要显示图片的哪个部分
            // object-position: 5px 10%;
        }

        .contName {
            width: 100%;
            height: 100%;
            border-radius: 0.28rem;

            background: linear-gradient(180deg, #0d253c00 52%, #0d253c 100%);
            position: absolute;
            top: 0;
            left: 0;

            .contNameText {
                color: #ffffff;
                font-size: 0.6rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                position: absolute;
                bottom: 0.71rem;
                left: 0.54rem;
            }
        }
    }
}
</style>